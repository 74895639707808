/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlbTable from 'components/AlbTable';
import moment from 'moment';
import AlbError from 'components/AlbError';
import AlbLoading from 'components/AlbLoading';
import AlembicPageTitle from 'components/AlembicPageTitle';
import CustomFooter from 'components/TablePagination';
import HasAnyPriv from 'components/HasAnyPriv/HasAnyPriv';
import { S3_LIST_FILES } from 'gql/s3';
import setPageTitle from 'util/titles';
import { customSizeRender } from 'components/AlbTable/CustomRender';
import FileUpload from './FileUpload';

const styles = makeStyles({
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

const FileList = () => {
  const classes = styles();

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(['LastModified', 'desc']);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    setPageTitle('Files');
  }, []);

  // defaults to 1000 objects
  // to limit, use { variables: { maxKeys: rowsPerPage } }
  // to increase, we need to add pagination via server
  const { data, loading, error, refetch } = useQuery(S3_LIST_FILES, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.listS3Files?.objects) {
      const objects = data.listS3Files.objects || [];
      let filteredObjects = objects;

      if (searchKeyword) {
        const keyword = searchKeyword.toLowerCase();
        filteredObjects = objects.filter(file => file.Key.toLowerCase().includes(keyword));
      }

      setTableData(filteredObjects);
      setCount(filteredObjects.length);
    } else {
      setTableData([]);
      setCount(0);
    }
  }, [data, searchKeyword]);

  const handleSearch = keyword => {
    setSearchKeyword(keyword);
    setPage(0);
  };

  const customDateRender = ms => {
    return <div style={{ whiteSpace: 'nowrap' }}>{moment(ms, 'x').format('MM/DD/YYYY')}</div>;
  };

  // const customActionsRender = () => {
  //   return <div />;
  // };

  const columns = [
    {
      name: 'Key',
      label: 'FILENAME',
      options: { sort: true }
    },
    {
      name: 'Size',
      label: 'SIZE',
      options: {
        sort: true,
        customBodyRender: customSizeRender,
        sortCompare: order => {
          return (a, b) => {
            const val1 = +a.data;
            const val2 = +b.data;

            return order === 'asc' ? val1 - val2 : val2 - val1;
          };
        }
      }
    },
    {
      name: 'LastModified',
      label: 'UPLOAD DATE',
      options: { sort: true, customBodyRender: customDateRender }
    }
    // we need to determine which actions we want to support -
    // deletion is possible, but we need to ensure that the file is not being processed
    // and consider if we want to actually delete the file from S3 or just flag it as deleted
    // which would require a postgres table to track individual files and their statuses
    // {
    //   name: 'actions',
    //   label: 'ACTIONS',
    //   options: {
    //     customBodyRender: customActionsRender,
    //     sort: false
    //   }
    // }
  ];

  const rowsPerPageOptions = [10, 20, 50];

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    rowHover: false,
    fixedHeader: false,
    sortFilterList: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    search: true,
    serverSide: false,
    page,
    rowsPerPage,
    count,
    onSearchChange: handleSearch,
    onChangeRowsPerPage: newRowsPerPage => {
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    },
    onChangePage: setPage,
    draggableColumns: { enabled: true, transitionTime: 300 },
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction]),
    customFooter,
    textLabels: {
      body: {
        noMatch: 'No files found'
      }
    }
  };

  const tableThemeOverrides = {
    MUIDataTableHeadCell: {
      toolButton: {
        marginLeft: '0px',
        marginRight: '0px'
      }
    }
  };

  return (
    <>
      <HasAnyPriv privs={['ORG:UPDATE']} showAlert>
        <>
          <Grid container justifyContent="space-between">
            <Grid item>
              <AlembicPageTitle title="Files" />
            </Grid>
            <Grid item>
              <FileUpload refetch={refetch} />
            </Grid>
          </Grid>
          {error && <AlbError error={error} />}
          <Paper style={{ position: 'relative' }}>
            {loading && (
              <div className={classes.loadingOverlay}>
                <AlbLoading />
              </div>
            )}
            <AlbTable
              tableData={tableData}
              tableColumns={columns}
              tableOptions={options}
              tableThemeOverrides={tableThemeOverrides}
            />
          </Paper>
        </>
      </HasAnyPriv>
    </>
  );
};

export default FileList;
