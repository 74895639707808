import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, MenuItem, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER, WEB_LINKED_ACCOUNTS } from 'gql/linktoken';
import { useLazyQuery } from '@apollo/client';
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import Box from 'components/Box';
import ContainerNav from 'components/ContainerNav';
import PostAccountSelector from 'components/PostAccountSelector';
import AnalyticsDatePicker from 'components/AnalyticsDatePicker';
import PlatformSelector from 'components/PlatformSelector';
import { showToast } from 'contexts/ToastContext';
import { connect } from 'react-redux';
import { LogoColored } from 'util/assets';
import { getPlatformName, mapOptions } from 'util/social';
import { parseQueryString, deleteQueryStringParameter } from 'util/routesHelpers';
import SIMPLE_SOCIAL_TYPE_ENUM from 'util/getSimpleSocialTypeEnum';

const useStyles = makeStyles({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    '@media print': {
      '& > div': {
        flex: 'none'
      }
    }
  },
  title: {
    display: 'flex',
    fontSize: '24px',
    fontWeight: '500',
    color: '#0A1734',
    marginRight: '20px',
    minWidth: 'fit-content'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  beta: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#6F6F6F',
    marginLeft: '5px'
  },
  dropDownContainer: {
    maxWidth: '350px'
  },
  dropDown: {
    borderColor: 'hsl(0,0%,80%)',
    borderRadius: '4px',
    border: 'solid 1px',
    minHeight: '38px',
    cursor: 'default',
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#0A1734',
    width: '200px',

    '& > div': {
      padding: '0px',

      '& div': {
        display: 'flex',
        alignItems: 'center',
        padding: '11px',
        paddingLeft: '16px',

        '&:focus': {
          backgroundColor: 'unset'
        }
      }
    }
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease',
    '@media print': {
      display: 'none'
    }
  },
  logo: {
    display: 'none',
    paddingTop: '66px',
    paddingBottom: '64px',
    '@media print': {
      display: 'block',
      padding: 20
    }
  },
  full: {
    marginTop: '70px',
    marginBottom: '40px'
  },
  compact: {
    marginTop: '30px',
    marginBottom: '20px'
  },
  closeIcon: {
    height: '45px',
    width: '45px',
    backgroundColor: '#0b1835',
    color: 'white',
    top: '40px',
    right: '40px',
    position: 'fixed',

    '@media print': {
      display: 'none'
    }
  }
});

const AnalyticsHeader = props => {
  const {
    selectedAccounts,
    setSelectedAccounts,
    setPlaceholder,
    calRange,
    changeCalRange,
    displayTitle,
    dropdown,
    customDropdownComponent,
    dropdownOnChange,
    selectedOption,
    defaultButtonLabel,
    currentUser,
    onClose,
    dialogRef,
    hideDatePicker,
    hideAccountSelector,
    hideContainerSelector,
    initialPlatforms,
    selectedPlatforms,
    simpleSelector,
    delta,
    web, // only retrieves GA and other related analytics accounts
    appStores, // only retrieves app stores accounts
    platformFilter,
    currentContainer,
    location,
    orgTree,
    handleContainerChange,
    beta // display beta tag next to header title
  } = props;
  const classes = useStyles();

  const [accounts, setAccounts] = useState([]);
  const [value, setValue] = useState(selectedOption || dropdown?.[0]?.value);
  const [scrolled, setScrolled] = useState(false);

  const onCompleted = async (data, query) => {
    if (data?.[query]) {
      // List of account IDs from the URL parameter
      const accountsInUrl = parseQueryString('account', location);
      let linkedAccounts = data[query];
      let tempSelectedAccounts = [];

      if (platformFilter.length) {
        const accountPromises = linkedAccounts.map(async account => {
          const platformName = await getPlatformName(account);
          return { account, platformName };
        });

        const accountsWithPlatforms = await Promise.all(accountPromises);
        linkedAccounts = accountsWithPlatforms
          .filter(({ platformName }) => platformFilter.includes(platformName))
          .map(({ account }) => account);
      }

      // Cross check IDs in URL with IDs from query
      if (accountsInUrl?.length) {
        const filteredAccounts = linkedAccounts.filter(({ id }) => accountsInUrl.includes(id));
        tempSelectedAccounts = await mapOptions(filteredAccounts, false);
      } else {
        tempSelectedAccounts = await mapOptions(linkedAccounts, false);
      }

      if (
        web === true &&
        tempSelectedAccounts.some(
          ({ platform }) => platform.name === SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS
        ) &&
        tempSelectedAccounts.some(
          ({ platform }) => platform.name === SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE
        )
      ) {
        tempSelectedAccounts = tempSelectedAccounts.filter(({ platform }) => {
          if (platform.name === SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS) {
            return false;
          }

          if (platform.name === SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE) {
            return false;
          }

          return true;
        });

        deleteQueryStringParameter('account', location.search);
      }

      // Get all mapped accounts
      const allMappedAccounts = await mapOptions(linkedAccounts, false);

      setSelectedAccounts(tempSelectedAccounts);
      setAccounts(allMappedAccounts);
      setPlaceholder(!tempSelectedAccounts.length);
    }
  };

  const [getWebLinkedAccounts, { error: webAccountsError }] = useLazyQuery(WEB_LINKED_ACCOUNTS, {
    variables: { appStores },
    fetchPolicy: 'network-only',
    onCompleted: data => onCompleted(data, 'webLinkedAccounts')
  });

  const [getLinkedAccounts, { error: accountsError }] = useLazyQuery(
    LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER,
    {
      variables: { showOnlyEnabled: true },
      fetchPolicy: 'network-only',
      onCompleted: data => onCompleted(data, 'linkedAccounts')
    }
  );

  const handleFetchAccounts = () =>
    web
      ? getWebLinkedAccounts({ variables: { appStores } })
      : getLinkedAccounts({ variables: { showOnlyEnabled: true } });

  useEffect(() => {
    // handle fetching accounts on initial load of pages containing this component, and also on changing containers
    // if account selector is hidden, linkTokens are not queried
    if (!hideAccountSelector && !simpleSelector) {
      handleFetchAccounts();
    }
  }, [currentContainer.id]);

  useEffect(() => {
    if (!hideAccountSelector && !simpleSelector) {
      // clear query string when web or appStores props change, which indicates init of either web and apps analytics or inner apps tab
      deleteQueryStringParameter('account', location.search);
      handleFetchAccounts();
    }
  }, [appStores]);

  useEffect(() => {
    if (webAccountsError) {
      showToast(`Error retrieving accounts: ${webAccountsError}`, 'error');
    } else if (accountsError) {
      showToast(`Error retrieving accounts: ${accountsError}`, 'error');
    }
  }, [webAccountsError, accountsError]);

  // this container ref refers to the DOM
  const containerRef = useRef();

  const handleScroll = useCallback(event =>
    setScrolled(event.target.scrollTop > containerRef.current.offsetHeight)
  );

  useEffect(() => {
    if (dialogRef?.current?.children?.length >= 3) {
      // if material ui dialog follows this structure then this DOM access should be safe
      // accessing the scrolling section of dialog
      const dialogContainer = dialogRef?.current?.children?.[2];
      const dialog = dialogContainer?.firstChild;

      dialog.addEventListener('scroll', handleScroll);

      return () => dialog.removeEventListener('scroll', handleScroll);
    }
    // to stop eslint from complaining
    return () => null;
  }, [dialogRef.current]);

  useEffect(() => {
    if (selectedAccounts?.length > 0) {
      setPlaceholder(false);
    } else {
      setPlaceholder(true);
    }
  }, [selectedAccounts]);

  return (
    <Grid container justifyContent="center" className={classes.header} ref={containerRef}>
      <Grid item xs={9}>
        <Box className={`${classes.box} ${scrolled ? classes.compact : classes.full}`}>
          <Grid item>
            {!hideContainerSelector && (
              <ContainerNav
                startingContainer={currentUser.home_container_id}
                handleContainerChange={() => {
                  return orgTree
                    ? // if org tree, wipe container and unique platform list held in state
                      handleContainerChange()
                    : // otherwise, wipe accounts in post account selector dropdown when changing the container
                      setSelectedAccounts([]);
                }}
                analytics
              />
            )}
          </Grid>
          <Grid container justifyContent="space-between" alignItems="flex-start">
            <Grid item className={classes.titleContainer}>
              <Typography className={classes.title}>
                {displayTitle}
                {beta && <span className={classes.beta}>beta</span>}
              </Typography>
              <div className={classes.dropDownContainer}>
                {!dropdown.length && !hideAccountSelector && !simpleSelector && (
                  <PostAccountSelector
                    singleAccountData={accounts}
                    singleSelectedAccounts={selectedAccounts}
                    handleSelectAccount={setSelectedAccounts}
                    useIconDisplay
                    useDefault
                    web={web}
                  />
                )}
                {!!dropdown.length && (
                  <ValidatorForm
                    onSubmit={e => {
                      e.preventDefault();
                    }}
                  >
                    <SelectValidator
                      value={value}
                      className={classes.dropDown}
                      onChange={event => {
                        setValue(event.target.value);
                        dropdownOnChange(event.target.value);
                      }}
                    >
                      {dropdown.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </ValidatorForm>
                )}
                {customDropdownComponent && customDropdownComponent}
              </div>
              {!hideAccountSelector && simpleSelector && (
                <PlatformSelector
                  availablePlatforms={initialPlatforms}
                  selectedPlatforms={selectedPlatforms}
                  updateSelectedPlatforms={setSelectedAccounts}
                />
              )}
            </Grid>
            {!hideDatePicker && (
              <Grid item>
                <AnalyticsDatePicker
                  setDateRange={changeCalRange}
                  calRange={calRange}
                  defaultButtonLabel={defaultButtonLabel}
                  delta={delta}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <div className={classes.logo}>
          <Grid container justifyContent="flex-start">
            <img src={LogoColored} alt="Alembic Logo" width="250px" height="45px" />
          </Grid>
        </div>
      </Grid>
      <IconButton onClick={onClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
};

AnalyticsHeader.propTypes = {
  displayTitle: PropTypes.string.isRequired,
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape()),
  setSelectedAccounts: PropTypes.func,
  setPlaceholder: PropTypes.func,
  calRange: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  changeCalRange: PropTypes.func.isRequired,
  dropdown: PropTypes.arrayOf(PropTypes.shape()),
  customDropdownComponent: PropTypes.element,
  dropdownOnChange: PropTypes.func,
  selectedOption: PropTypes.string,
  defaultButtonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  currentContainer: PropTypes.shape().isRequired,
  dialogRef: PropTypes.shape(),
  hideDatePicker: PropTypes.bool,
  hideAccountSelector: PropTypes.bool,
  hideContainerSelector: PropTypes.bool,
  simpleSelector: PropTypes.bool,
  delta: PropTypes.bool,
  initialPlatforms: PropTypes.arrayOf(PropTypes.string),
  selectedPlatforms: PropTypes.arrayOf(PropTypes.string),
  web: PropTypes.bool,
  appStores: PropTypes.bool,
  platformFilter: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape(),
  orgTree: PropTypes.bool,
  handleContainerChange: PropTypes.func,
  beta: PropTypes.bool
};

AnalyticsHeader.defaultProps = {
  selectedAccounts: [],
  dropdown: [],
  customDropdownComponent: null,
  setSelectedAccounts: () => null,
  setPlaceholder: () => null,
  dropdownOnChange: () => null,
  selectedOption: null,
  defaultButtonLabel: '7',
  dialogRef: {},
  hideDatePicker: false,
  hideAccountSelector: false,
  hideContainerSelector: false,
  simpleSelector: false,
  delta: false,
  initialPlatforms: [],
  selectedPlatforms: [],
  web: false,
  appStores: false,
  platformFilter: [],
  location: {},
  orgTree: false,
  handleContainerChange: () => null,
  beta: false
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    currentContainer: state.auth.currentContainer
  };
};

export default connect(mapStateToProps)(AnalyticsHeader);
