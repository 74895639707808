import {
  AccountBox as AccountBoxIcon,
  Group as GroupIcon,
  InsertDriveFile as InsertDriveFileIcon
} from '@material-ui/icons';
import memoize from 'lodash/memoize';
import sortBy from 'lodash/sortBy';
import {
  FacebookLogo,
  InstagramLogo,
  InstagramStoryLogo,
  LinkedinLogo,
  TwitterLogo,
  YouTubeLogo,
  GoogleLogo,
  ARTICLE_ICON,
  AdobeAnalyticsLogo,
  AppleLogo,
  AndroidLogo,
  TikTokLogo,
  AppStoreConnectLogo,
  GooglePlayConsoleLogo
} from 'util/assets';
import getSimpleSocialType from './getSimpleSocialType';
import SIMPLE_SOCIAL_TYPE_ENUM from './getSimpleSocialTypeEnum';
import SOCIAL_DISPLAY_NAME_ENUM from './getSocialDisplayNameEnum';

export const getPlatformName = async linktoken => {
  if (linktoken?.type == null) {
    return '';
  }

  if (linktoken?.type === 'OTHER') {
    return SIMPLE_SOCIAL_TYPE_ENUM.OTHER;
  }

  return getSimpleSocialType(linktoken.type, true);
};

const facebook = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.FACEBOOK,
  name: SIMPLE_SOCIAL_TYPE_ENUM.FACEBOOK,
  color: '#5478C9',
  icon: FacebookLogo,
  sortIndex: 0,
  charLimit: 63206,
  mediaLimit: 30,
  types: {
    FB_PROFILE: {
      icon: AccountBoxIcon,
      displayName: 'Profile'
    },
    FB_GROUP: {
      icon: GroupIcon,
      displayName: 'Group'
    },
    FB_PAGE: {
      icon: InsertDriveFileIcon,
      displayName: 'Page'
    }
  }
};

const twitter = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.TWITTER,
  name: SIMPLE_SOCIAL_TYPE_ENUM.TWITTER,
  color: '#1A97F1',
  icon: TwitterLogo,
  sortIndex: 1,
  charLimit: 280,
  mediaLimit: 4
};

const linkedin = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.LINKEDIN,
  name: SIMPLE_SOCIAL_TYPE_ENUM.LINKEDIN,
  color: '#006BAB',
  icon: LinkedinLogo,
  sortIndex: 2,
  charLimit: 40000,
  mediaLimit: 9
};

const instagram = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.INSTAGRAM,
  name: SIMPLE_SOCIAL_TYPE_ENUM.INSTAGRAM,
  color: '#AC1C79',
  icon: InstagramLogo,
  sortIndex: 3,
  charLimit: 2200,
  mediaLimit: 10
};

const youtube = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.YOUTUBE,
  name: SIMPLE_SOCIAL_TYPE_ENUM.YOUTUBE,
  color: '#FF0000',
  icon: YouTubeLogo,
  sortIndex: 4,
  charLimit: 2200,
  mediaLimit: 1
};

const google = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.GOOGLE,
  name: SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE,
  color: '#FF0000',
  icon: GoogleLogo,
  sortIndex: 4,
  charLimit: 2200,
  mediaLimit: 1
};

const socialAnimal = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.SOCIAL_ANIMAL,
  name: SIMPLE_SOCIAL_TYPE_ENUM.SOCIAL_ANIMAL,
  icon: ARTICLE_ICON,
  sortIndex: 5
};

const adobeAnalytics = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.ADOBE_ANALYTICS,
  name: SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS,
  icon: AdobeAnalyticsLogo,
  sortIndex: 6
};

const apple = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.APP_STORE_CONNECT_APP,
  name: SIMPLE_SOCIAL_TYPE_ENUM.APP_STORE_CONNECT_APP,
  icon: AppleLogo,
  sortIndex: 7
};

const android = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.GOOGLE_PLAY_CONSOLE_APP,
  name: SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_PLAY_CONSOLE_APP,
  icon: AndroidLogo,
  sortIndex: 8
};

const tiktok = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.TIKTOK,
  name: SIMPLE_SOCIAL_TYPE_ENUM.TIKTOK,
  icon: TikTokLogo,
  sortIndex: 9,
  charLimit: 2200,
  mediaLimit: 1
};

const appStore = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.APPLE_APP_STORE_CONNECT,
  name: SIMPLE_SOCIAL_TYPE_ENUM.APPLE_APP_STORE_CONNECT,
  icon: AppStoreConnectLogo,
  sortIndex: 10
};

const googlePlay = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.GOOGLE_PLAY_CONSOLE,
  name: SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE_PLAY_CONSOLE,
  icon: GooglePlayConsoleLogo,
  sortIndex: 11
};

const other = {
  displayName: SOCIAL_DISPLAY_NAME_ENUM.OTHER,
  name: SIMPLE_SOCIAL_TYPE_ENUM.OTHER,
  sortIndex: 12
};

export const socialPostPlatforms = [facebook, twitter, linkedin, instagram, tiktok];

export const socialBroadcastPlatforms = [facebook, twitter, linkedin, instagram, youtube, tiktok];

export const platforms = [
  facebook,
  twitter,
  linkedin,
  instagram,
  youtube,
  google,
  adobeAnalytics,
  apple,
  android,
  tiktok,
  appStore,
  googlePlay,
  other
];

// Columns matched to db fields in social table
export const DATE = 'start_date';
export const CAMPAIGN = 'campaign_id';
export const AUTHOR = 'created_by';
export const PREVIEW = 'event_body';
export const SOCIAL_ACCOUNTS = 'linked_account_id';
export const STATUS = 'state';

export const campaignEventsStates = {
  0: ['ACTIVE', 'PAUSED', 'PENDING', 'DENIED', 'ERROR'],
  1: ['DRAFT'],
  2: ['COMPLETED']
};

// used for sorting - we only currently sort by Date (column 0), and State (column 5)
export const campaignEventsColumns = {
  0: DATE,
  1: CAMPAIGN,
  2: AUTHOR,
  3: PREVIEW,
  4: SOCIAL_ACCOUNTS,
  5: STATUS
};

// tabs in Social Table
export const UPCOMING_TAB = 0;
export const DRAFTS_TAB = 1;
export const COMPLETED_TAB = 2;

// if we want to implement different sort direction per tab, this is where we do that.
export const campaignEventsDefaultSortDate = {
  0: 'asc',
  1: 'asc',
  2: 'desc'
};

// mapping db states to user-friendly names.
export const CAMPAIGN_EVENT_STATE_DETAILS = {
  DRAFT: { name: 'DRAFT', color: 'rgba(50, 50, 125, 0.1)' },
  ACTIVE: { name: 'APPROVED', color: 'rgba(0, 168, 79, 0.1)' },
  PENDING: { name: 'AWAITING APPROVAL', color: 'rgba(0, 159, 196, 0.1)' },
  DENIED: { name: 'DENIED', color: 'rgba(231, 85, 85, 0.7)' },
  PAUSED: { name: 'PAUSED', color: 'rgba(243, 155, 37, 0.15)' },
  COMPLETED: { name: 'COMPLETED', color: 'rgba(0, 168, 79, 0.1)' },
  INELIGIBLE: { name: 'COMPLETED', color: 'rgba(0, 168, 79, 0.1)' },
  ERROR: { name: 'ERROR', color: 'rgba(255, 0, 0, 0.8)' }
};

export const creatorStatuses = [
  { status: 'DRAFT', name: 'DRAFT', color: 'rgba(50, 50, 125, 0.1)' },
  { status: 'PENDING', name: 'AWAITING APPROVAL', color: 'rgba(0, 159, 196, 0.1)' }
];

export const mapOptions = memoize(
  async (accounts, filterAccounts) => {
    const filteredAccounts = filterAccounts
      ? accounts.filter(
          a =>
            a.enabled === true &&
            [
              'FB_PAGE',
              'INSTAGRAM',
              'TWITTER',
              'LINKEDIN_PROFILE',
              'LINKEDIN_COMPANY',
              'TIKTOK'
            ].includes(a.type)
        )
      : accounts;

    const accountPromises = filteredAccounts.map(async account => {
      const platformName = await getPlatformName(account);
      let platform;
      if (filterAccounts) {
        platform = socialPostPlatforms.find(p => p.name === platformName);
      } else {
        platform = platforms.find(p => p.name === platformName);
      }
      const label = account.remote_name;

      return { ...account, platform, label, value: account.id };
    });

    const resolvedAccounts = await Promise.all(accountPromises);

    return sortBy(resolvedAccounts, [a => a.platform?.sortIndex, 'remote_name']);
  },
  (accounts, filterAccounts) => {
    return `${JSON.stringify(accounts)}:${filterAccounts}`;
  }
);

export const trimPreviewText = (text, maxLength) => {
  // trim the string to the maximum length
  let trimmedString = text.substr(0, maxLength);

  // re-trim if we are in the middle of a word and
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );

  return trimmedString;
};

export const getEmailMetricValue = (email, metric) => {
  const emailMetricsMapping = {
    sent: {
      SENDGRID: 'requests',
      SPARKPOST: 'count_sent'
    },
    uniqueOpens: {
      SENDGRID: 'unique_opens',
      SPARKPOST: 'count_unique_confirmed_opened'
    },
    totalOpens: {
      SENDGRID: 'opens',
      SPARKPOST: 'count_rendered'
    },
    totalClicks: {
      SENDGRID: 'clicks',
      SPARKPOST: 'count_clicked'
    },
    uniqueClicks: {
      SENDGRID: 'unique_clicks',
      SPARKPOST: 'count_unique_clicked'
    },
    unsubscribers: {
      SENDGRID: 'unsubscribes',
      SPARKPOST: 'count_unsubscribe'
    },
    delivered: {
      SENDGRID: 'delivered',
      SPARKPOST: 'count_accepted'
    },
    bounces: {
      SENDGRID: 'bounces',
      SPARKPOST: 'count_bounce'
    },
    blocks: {
      SENDGRID: 'spam_report_drops',
      SPARKPOST: 'count_spam_complaint'
    }
  };

  // eslint-disable-next-line camelcase
  const emailAccountType = email?.campaign_event?.linked_account?.type;
  if (!emailAccountType) {
    return null;
  }

  const mappedMetric = emailMetricsMapping[metric];

  if (mappedMetric) {
    const mappedMetricAndType = mappedMetric[emailAccountType];

    if (mappedMetricAndType) {
      // eslint-disable-next-line camelcase
      return email?.meta_analytics?.[mappedMetricAndType];
    }
  }

  // Return null if we didn't have a match above
  return null;
};

export const instagramStoryTypes = [
  'INSTAGRAM_STORY_PHOTO',
  'INSTAGRAM_STORY_VIDEO',
  'INSTAGRAM_STORY_ALBUM'
];

export const storyTypes = [...instagramStoryTypes];

export const storyIcons = {
  INSTAGRAM_STORY_PHOTO: InstagramStoryLogo,
  INSTAGRAM_STORY_VIDEO: InstagramStoryLogo,
  INSTAGRAM_STORY_ALBUM: InstagramStoryLogo
};

export const handleNullTableValue = (value, percent, currency) => {
  if (typeof value === 'number') {
    if (percent) {
      return `${Math.round(value).toLocaleString()}%`;
    }
    if (currency) {
      return `$${Math.round(value).toLocaleString()}`;
    }

    return Math.round(value).toLocaleString();
  }
  return '-';
};

export const socialIconForChannel = type => {
  if (type.includes('FB')) {
    return facebook.icon;
  }
  if (storyIcons[type]) {
    return storyIcons[type];
  }
  if (type.includes('INSTAGRAM')) {
    return instagram.icon;
  }
  if (type.includes('TWITTER')) {
    return twitter.icon;
  }
  if (type.includes('LINKEDIN')) {
    return linkedin.icon;
  }
  if (type.includes('FACEBOOK')) {
    return facebook.icon;
  }
  if (type.includes('YOUTUBE')) {
    return youtube.icon;
  }
  if (type.includes('GOOGLE')) {
    return google.icon;
  }
  if (type.includes('SOCIAL_ANIMAL') || type.includes('SA')) {
    return socialAnimal.icon;
  }
  if (type.includes('ADOBE')) {
    return adobeAnalytics.icon;
  }
  if (type.includes('TIKTOK')) {
    return tiktok.icon;
  }
  return type.toLowerCase();
};

export const supportedDrawerTableTypes = ['DEMO_TABLE', 'DEMO_TABLE_AGE'];
export const supportedDrawerGraphTypes = ['BAR', 'NUMBER', 'LINE', ...supportedDrawerTableTypes];

export const MAJOR_RESURGENCE_RANK = 10;
export const getResurgenceType = rank => (rank >= MAJOR_RESURGENCE_RANK ? 'MAJOR' : 'MINOR');
