import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { Delete, Mail, FileCopy as CopyIcon } from '@material-ui/icons';
import { INVITATION_USERS } from 'gql/invitation';
import HasAnyPriv from 'components/HasAnyPriv';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { convertToUserTimeZone } from 'util/date';
import { showToast } from 'contexts/ToastContext';
import DeleteInviteModal from './DeleteInviteModal';
import ResendInviteModal from './ResendInviteModal';

const styles = makeStyles({
  tableHeading: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#6f6f6f'
  },
  tableBody: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#757575'
  },
  tableRow: {
    height: '60px',
    padding: '0px 20px'
  }
});

const InviteTable = props => {
  const { currentUser, containerId, refetchInvite, setRefetchInvite } = props;
  const classes = styles();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openResendModal, setOpenResendModal] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const [inviteToResend, setInviteToResend] = useState(null);

  const { loading, error, data, refetch } = useQuery(INVITATION_USERS, {
    variables: {
      containerId
    }
  });

  useEffect(() => {
    if (refetchInvite) {
      refetch();
      setRefetchInvite();
    }
  }, [refetchInvite]);

  if (loading) return <AlbLoading />;
  if (error) return <AlbError error={error} refetch={refetch} />;

  return (
    <>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.tableRow}
      >
        <Grid item container xs={1}>
          <Typography className={classes.tableHeading}>First Name</Typography>
        </Grid>
        <Grid item container xs={1}>
          <Typography className={classes.tableHeading}>Last Name</Typography>
        </Grid>
        <Grid item container xs={2}>
          <Typography className={classes.tableHeading}>Role</Typography>
        </Grid>
        <Grid item container xs={2}>
          <Typography className={classes.tableHeading}>Email</Typography>
        </Grid>
        <Grid item container xs={2}>
          <Typography className={classes.tableHeading}>Last Activity</Typography>
        </Grid>
        <HasAnyPriv privs={['USERS:CREATE']}>
          <Grid item container xs={1}>
            <Typography className={classes.tableHeading}>Delete Invitation</Typography>
          </Grid>
          <Grid item container xs={1}>
            <Typography className={classes.tableHeading}>Resend Invitation</Typography>
          </Grid>
          <Grid item container xs={1}>
            <Typography className={classes.tableHeading}>Copy Link</Typography>
          </Grid>
        </HasAnyPriv>
      </Grid>
      {data?.invitations?.length === 0 && (
        <Grid container justifyContent="center" alignItems="center" className={classes.tableRow}>
          <Typography>No Users Found</Typography>
        </Grid>
      )}
      {data?.invitations &&
        data.invitations.map(inviteData => {
          const tokenURL = `${window.location.origin}/users/invite?t=${inviteData.token}`;

          return (
            <Grid
              key={inviteData.id}
              container
              justifyContent="space-evenly"
              alignItems="center"
              className={classes.tableRow}
            >
              <Grid item xs={1}>
                <Typography className={classes.tableBody}>
                  {inviteData.recipient_first_name}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography className={classes.tableBody}>
                  {inviteData.recipient_last_name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.tableBody}>{inviteData.role.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.tableBody}>{inviteData.recipient_email}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.tableBody}>
                  {convertToUserTimeZone(inviteData.updated_at, currentUser.time_zone).format(
                    'L LT'
                  )}
                </Typography>
              </Grid>
              <HasAnyPriv privs={['USERS:CREATE']}>
                <Grid item xs={1}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setInviteToDelete(inviteData.id);
                      setOpenDeleteModal(true);
                    }}
                    style={{ marginLeft: '8px' }}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setInviteToResend(inviteData);
                      setOpenResendModal(true);
                    }}
                    style={{ marginLeft: '8px' }}
                  >
                    <Mail />
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={e => {
                      navigator.clipboard.writeText(tokenURL);
                      showToast(`Invite link copied to clipboard`, 'success');
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Grid>
              </HasAnyPriv>
            </Grid>
          );
        })}
      {inviteToDelete && (
        <DeleteInviteModal
          isModalOpen={openDeleteModal}
          onChange={e => {
            setOpenDeleteModal(e.isModalOpen);
            refetch();
          }}
          inviteId={inviteToDelete}
        />
      )}
      {inviteToResend && (
        <ResendInviteModal
          isModalOpen={openResendModal}
          onChange={e => {
            setOpenResendModal(e.isModalOpen);
            refetch();
          }}
          containerId={containerId}
          invite={inviteToResend}
        />
      )}
    </>
  );
};

InviteTable.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  containerId: PropTypes.string.isRequired,
  refetchInvite: PropTypes.bool.isRequired,
  setRefetchInvite: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(InviteTable);
