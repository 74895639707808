// Avatars
import DefaultContainerAvatar from 'assets/avatars/default_container.png';
import DefaultUserAvatarColor from 'assets/avatars/default_user_color.svg';
import DefaultUserAvatar from 'assets/avatars/default_user.jpg';

// Brand
import Logo from 'assets/brand/alembic_2021_linear_lilac.svg';
import LogoColored from 'assets/brand/alembic_2021_linear.svg';
import LogoMarkOnly from 'assets/brand/alembic_2021_mark.svg';
import LogoStacked from 'assets/brand/alembic_2021_stacked.svg';

// Business Types
import AgencyImage from 'assets/business_types/agency.png';
import RestaurantImage from 'assets/business_types/b2b.png';
import ConsumerImage from 'assets/business_types/consumer_goods.png';
import EntertainmentImage from 'assets/business_types/entertainment.png';
import FinanceImage from 'assets/business_types/finance.png';
import FitnessImage from 'assets/business_types/fitness.png';
import HotelImage from 'assets/business_types/hospitality.png';
import NonProfitImage from 'assets/business_types/non_profit.png';
import OtherImage from 'assets/business_types/other.png';
import ProfessionalImage from 'assets/business_types/professional_services.png';
import RestaurantBarImage from 'assets/business_types/restaurant_bar.png';
import RetailImage from 'assets/business_types/retail.png';

// TODO: Replace
// Card Icons
import THIRD_PARTY_MEDIA_ICON from 'assets/cardIcons/3rd_party_media_icon.svg';
import CALENDAR_ICON from 'assets/cardIcons/calendar_icon.svg';
import EMAIL_ICON from 'assets/cardIcons/email.svg';
import HYPE_AND_FLOW_ICON from 'assets/cardIcons/hype_flow_icon.svg';
import INSIGHTS_ICON from 'assets/cardIcons/insights_icon.svg';
import LINK_SHORTENER_ICON from 'assets/cardIcons/link_shortener_icon.svg';
import MEDIA_VALUATION_ICON from 'assets/cardIcons/media_valuation_icon.svg';
import ORG_TREE_ICON from 'assets/cardIcons/org_tree_icon.svg';
import PROGRAM_LIFT_ICON from 'assets/cardIcons/program_lift_icon.svg';
import TARGETING_ICON from 'assets/cardIcons/targeting_icon.svg';
import RESURGENCE_ICON from 'assets/cardIcons/resurgence_icon.svg';
import SALESFORCE_ICON from 'assets/cardIcons/salesforce_icon.svg';
import SHARE_OF_VOICE_ICON from 'assets/cardIcons/share_of_voice_icon.svg';
import SOCIAL_MEDIA_ICON from 'assets/cardIcons/social_broadcast_icon.svg';
import SOCIAL_CV_ICON from 'assets/cardIcons/social_cv_icon.svg';
import SOCIAL_IMPACT_ICON from 'assets/cardIcons/social_impact_icon.svg';
import WEB_AND_APPS_ICON from 'assets/cardIcons/web_and_apps_icon.svg';

// CRM
import AddressBookIcon from 'assets/crm/address_book.svg';
import CongratsIcon from 'assets/crm/congrats.svg';
import ReorderColumnsIcon from 'assets/crm/reorder_columns_icon.svg';
import SmartFiltersBlueIcon from 'assets/crm/smart_filters_blue.svg';
import SmartFiltersWhiteIcon from 'assets/crm/smart_filters_white.svg';

// Detected Event
import CorrelationLogo from 'assets/detectedEvent/correlation.svg';
import ShortlinkLogo from 'assets/detectedEvent/shortlink.svg';

// Media
import NoImageAvailable from 'assets/media/no_image_avail.svg';
import NoThumbnailAvailable from 'assets/media/no_thumb_avail.svg';
import NoVideo from 'assets/media/no_video.svg';
import VideoPlaceholderImage from 'assets/media/video_placeholder_img.jpg';

// Nav Bar Icons
import ObserveIcon from 'assets/navBarIcons/ObserveIcon';
import OptimizeIcon from 'assets/navBarIcons/OptimizeIcon';
import PlanIcon from 'assets/navBarIcons/PlanIcon';
import ValidateIcon from 'assets/navBarIcons/ValidateIcon';

// Other
import PostDetailScreenshot from 'assets/other/PostDetailScreenshot.jpg';

// Plan Dashboard
import CircleArrowsIcon from 'assets/planDashboard/circle_arrows.svg';
import LightBulbIcon from 'assets/planDashboard/light_bulb.svg';
import TargetIcon from 'assets/planDashboard/target.svg';

// Social
import AdobeAnalyticsLogo from 'assets/social/adobe_analytics.svg';
import AppleLogo from 'assets/social/app_store_connect_app.svg';
import AppStoreConnectLogo from 'assets/social/app_store_connect.svg';
import FacebookLogo from 'assets/social/facebook.svg';
import GoogleAnalyticsViewLogo from 'assets/social/google_analytics_view.svg';
import GoogleAnalyticsWWWLogo from 'assets/social/google_analytics_www.svg';
import GoogleAnalyticsLogo from 'assets/social/google_analytics.svg';
import AndroidLogo from 'assets/social/google_play_console_app.svg';
import GooglePlayConsoleLogo from 'assets/social/google_play_console.svg';
import GoogleLogo from 'assets/social/google.svg';
import InstagramLogo from 'assets/social/instagram_gradient.svg';
import InstagramReelLogo from 'assets/social/instagram_reel_gradient.svg';
import InstagramStoryLogo from 'assets/social/instagram_story_gradient.svg';
import LinkedinLogo from 'assets/social/linkedin.svg';
import MailchimpLogo from 'assets/social/mailchimp.png';
import NasdaqLogo from 'assets/social/nasdaq.svg';
import RedditLogo from 'assets/social/reddit.svg';
import TVEyesLogo from 'assets/social/tveyes.svg';
import TwitterLogo from 'assets/social/twitter.svg';
import YouTubeLogo from 'assets/social/youtube.svg';
import SalesforceLogo from 'assets/social/salesforce.svg';
import NewsDataLogo from 'assets/social/newsdata.svg';
import TikTokLogo from 'assets/social/tiktok.png';
import GoogleSearchConsoleLogo from 'assets/social/google_search_console.svg';

// Third Party Media
import ARTICLE_ICON from 'assets/thirdPartyMedia/article_icon.svg'; // Used for Social Animal
import PODCAST_ICON from 'assets/thirdPartyMedia/podcast_icon.svg';
import RADIO_ICON from 'assets/thirdPartyMedia/radio_icon.svg';
import TV_AND_RADIO_ICON from 'assets/thirdPartyMedia/tv_and_radio_icon.svg';
import TV_ICON from 'assets/thirdPartyMedia/tv_icon.svg';

// Util
import TwentyFourHourIcon from 'assets/util/24hr_icon.svg';
import AnnotationWhiteIcon from 'assets/util/annotation_white.svg';
import AnnotationBlueIcon from 'assets/util/annotation.svg';
import BarGraphIcon from 'assets/util/bar_graph_icon.svg';
import BarGraphInvertedIcon from 'assets/util/bar_graph_inverted_icon.svg';
import EllipsisIcon from 'assets/util/ellipsis.svg';
import EyeIcon from 'assets/util/eye.svg';
import FireEmojiIcon from 'assets/util/fire_emoji.svg';
import SettingsIcon from 'assets/util/settings.svg';
import SparklineIcon from 'assets/util/sparkline.svg';
import TwoPeopleIcon from 'assets/util/two_people.svg';

/**
 * Dynamically loads a custom logo for a given ingest type
 *
 * @function getCustomIngestLogo
 * @param {string} type - The ingest type to load a logo for
 * @returns {string|null} The URL of the logo image, or null if no matching logo is found or if type is falsy
 * @description
 * This function attempts to find and load an SVG or PNG logo file that matches the provided type.
 * SVG files are prioritized over PNG files if both exist.
 */
const getCustomIngestLogo = type => {
  if (!type) return null;

  const normalizedType = type.toLowerCase();

  // Use a context module to tell webpack which files might be imported
  const svgContext = require.context('assets/social/custom', false, /\.svg$/);
  const pngContext = require.context('assets/social/custom', false, /\.png$/);

  // Try to find the file in the context
  const svgKey = `./${normalizedType}.svg`;
  const pngKey = `./${normalizedType}.png`;

  if (svgContext.keys().includes(svgKey)) {
    return svgContext(svgKey);
  }
  if (pngContext.keys().includes(pngKey)) {
    return pngContext(pngKey);
  }

  return null;
};

export {
  // Avatars
  DefaultContainerAvatar,
  DefaultUserAvatarColor,
  DefaultUserAvatar,
  // Brand
  Logo,
  LogoColored,
  LogoMarkOnly,
  LogoStacked,
  // Business Types
  AgencyImage,
  RestaurantImage,
  ConsumerImage,
  EntertainmentImage,
  FinanceImage,
  FitnessImage,
  HotelImage,
  NonProfitImage,
  OtherImage,
  ProfessionalImage,
  RestaurantBarImage,
  RetailImage,
  // Card Icons
  THIRD_PARTY_MEDIA_ICON,
  CALENDAR_ICON,
  EMAIL_ICON,
  HYPE_AND_FLOW_ICON,
  INSIGHTS_ICON,
  LINK_SHORTENER_ICON,
  MEDIA_VALUATION_ICON,
  ORG_TREE_ICON,
  PROGRAM_LIFT_ICON,
  TARGETING_ICON,
  RESURGENCE_ICON,
  SALESFORCE_ICON,
  SHARE_OF_VOICE_ICON,
  SOCIAL_MEDIA_ICON,
  SOCIAL_CV_ICON,
  SOCIAL_IMPACT_ICON,
  WEB_AND_APPS_ICON,
  // CRM
  AddressBookIcon,
  CongratsIcon,
  ReorderColumnsIcon,
  SmartFiltersBlueIcon,
  SmartFiltersWhiteIcon,
  // Detected Event
  CorrelationLogo,
  ShortlinkLogo,
  // Media
  NoImageAvailable,
  NoThumbnailAvailable,
  NoVideo,
  VideoPlaceholderImage,
  // Nav Bar Icons
  ObserveIcon,
  OptimizeIcon,
  PlanIcon,
  ValidateIcon,
  // Other
  PostDetailScreenshot,
  // Plan Dashboard
  CircleArrowsIcon,
  LightBulbIcon,
  TargetIcon,
  // Social
  AdobeAnalyticsLogo,
  AppleLogo,
  AppStoreConnectLogo,
  FacebookLogo,
  GoogleAnalyticsViewLogo,
  GoogleAnalyticsWWWLogo,
  GoogleAnalyticsLogo,
  AndroidLogo,
  GooglePlayConsoleLogo,
  GoogleLogo,
  InstagramLogo,
  InstagramReelLogo,
  InstagramStoryLogo,
  LinkedinLogo,
  MailchimpLogo,
  NasdaqLogo,
  RedditLogo,
  TVEyesLogo,
  TwitterLogo,
  YouTubeLogo,
  SalesforceLogo,
  NewsDataLogo,
  TikTokLogo,
  GoogleSearchConsoleLogo,
  // Third Party Media
  ARTICLE_ICON,
  PODCAST_ICON,
  RADIO_ICON,
  TV_AND_RADIO_ICON,
  TV_ICON,
  // Util
  TwentyFourHourIcon,
  AnnotationWhiteIcon,
  AnnotationBlueIcon,
  BarGraphIcon,
  BarGraphInvertedIcon,
  EllipsisIcon,
  EyeIcon,
  FireEmojiIcon,
  SettingsIcon,
  SparklineIcon,
  TwoPeopleIcon,
  // Custom Ingest
  getCustomIngestLogo
};
