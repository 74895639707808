import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Select, MenuItem, Checkbox } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import theme from 'theme';
import { getDrawerHeaderTitle, getReadableDetectedEventTitle } from 'util/detectedEvents';

const terminatingTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    },
    MuiSelect: {
      select: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        paddingTop: '11px',
        paddingLeft: '16px',

        '&:focus': {
          backgroundColor: 'unset'
        }
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '0px'
      }
    },
    MuiMenuItem: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    }
  }
});

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontWeight: 500
  },
  causalTitle: {
    fontSize: '20px',
    color: '#0A1734'
  },
  dropdownLabel: {
    fontSize: '15px',
    color: '#0D1A3A'
  },
  select: {
    width: '190px',
    height: '41px',
    color: '#0D1A3A',
    marginLeft: '10px'
  }
});

/**
 * TerminatingEventDropdown component
 * @description For filtering causal chains by terminating event
 * @param {Object} props - The component props
 * @param {array} props.selectedEventTypes - The selected event types
 * @param {function} props.setSelectedEventTypes - The function to set the selected event types
 * @param {array} props.terminatingEvents - The terminating events
 * @returns {JSX.Element} The TerminatingEventDropdown component
 */
function TerminatingEventDropdown(props) {
  const classes = useStyles();
  const { selectedEventTypes, setSelectedEventTypes, terminatingEvents } = props;

  const renderSelected = selected =>
    selected.length === terminatingEvents.length
      ? 'All'
      : selected
          .map(eventType =>
            getDrawerHeaderTitle({
              event: { type: getReadableDetectedEventTitle(eventType) }
            })
          )
          .join(', ');

  return (
    <MuiThemeProvider theme={terminatingTheme}>
      Filter by Terminating Event:
      <Select
        className={classes.select}
        variant="outlined"
        multiple
        labelId="input-label"
        value={selectedEventTypes}
        renderValue={renderSelected}
        onChange={e => setSelectedEventTypes(e.target.value)}
        IconComponent={ExpandMore}
      >
        {terminatingEvents.map(value => (
          <MenuItem key={value} value={value} onClick={() => {}}>
            <Checkbox checked={selectedEventTypes.includes(value)} />
            {/* convert label and use drawer header as fallback */}
            {getDrawerHeaderTitle({
              event: { type: getReadableDetectedEventTitle(value) }
            })}
          </MenuItem>
        ))}
      </Select>
    </MuiThemeProvider>
  );
}

TerminatingEventDropdown.propTypes = {
  selectedEventTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedEventTypes: PropTypes.func.isRequired,
  terminatingEvents: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TerminatingEventDropdown;
