import { gql } from '@apollo/client';

export const S3_LIST_FILES = gql`
  query listS3Files {
    listS3Files {
      objects {
        Key
        Size
        LastModified
        ETag
      }
    }
  }
`;

export const S3_GENERATE_URL = gql`
  query generatePresignedUrl($fileName: String!, $fileType: String!) {
    generatePresignedUrl(fileName: $fileName, fileType: $fileType) {
      url
      key
    }
  }
`;
