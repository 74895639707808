import {
  LinkAccountsPath,
  CausalPath
  // OptimizePath
  // ObserveEventsPathV4
} from './paths';

/**
 * Get the default route to dashboard
 * @param {number} currentUser container id to prefix
 * @param {string} containerIdOverride container id to use instead of currentUser.home_container_id (for non-link-only users)
 * @returns {String} path to default dashboard view
 */
const getDashboardRoute = (currentUser, containerIdOverride) => {
  // if they are not logged in, then our goal is to send them to the login page
  if (!currentUser) {
    return '/users/login';
  }

  // by default the next destination is the dashboard for the user's home
  // container or /causal view/page
  let containerId = containerIdOverride || currentUser.home_container_id;
  let path = CausalPath;

  // if you are a link only user, your dashboard is the link accounts page

  if (currentUser.role?.role_type === 'S' && currentUser.role?.name === 'Link Only') {
    containerId = currentUser.home_container_id;
    path = LinkAccountsPath;
  }

  return `/${containerId}${path}`;
};

export default getDashboardRoute;
