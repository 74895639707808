import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Button, InputLabel } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { LINK_ADOBE_ANALYTICS_ACCOUNT } from 'gql/linktoken';

import { AlbTooltip } from 'components/AlbTooltip';
import { goToRoute } from 'util/routesHelpers';
import handleGraphQLError from 'util/error';
import tooltipText from 'util/tooltipText';
import { showToast } from 'contexts/ToastContext';
import { LinkAccountsPath } from '../../util/paths';

/**
 * @method
 * @summary This component contains a form allowing the user to input credentials required to connect an adobe analytics account
 * @name LinkAdobeAnalyticsAccountForm
 * @return {Object} - React JSX
 */
const LinkAdobeAnalyticsAccountForm = props => {
  const { history } = props;

  const [globalCompanyId, setGlobalCompanyId] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const [linkAdobeAnalyticsAccount, { loading, error }] = useMutation(
    LINK_ADOBE_ANALYTICS_ACCOUNT,
    {
      onCompleted: result => {
        if (result?.createAdobeAnalyticsLink?.token?.remote_name) {
          showToast(
            // eslint-disable-next-line camelcase
            `${result?.createAdobeAnalyticsLink.token.remote_name} Adobe Analytics account has been linked. We will begin ingesting all segments and report suites within 1 minute.`,
            'success'
          );
          goToRoute(LinkAccountsPath, history);
        }
      }
    }
  );

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  return (
    <>
      <ValidatorForm
        onSubmit={() => {
          linkAdobeAnalyticsAccount({
            variables: {
              global_company_id: globalCompanyId.trim(),
              client_id: clientId.trim(),
              client_secret: clientSecret.trim()
            }
          });
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">Enter your API Credentials</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <InputLabel>Global Company ID</InputLabel>
              <span style={{ marginLeft: '8px', lineHeight: '0px' }}>
                <AlbTooltip
                  title={tooltipText.aaGlobalCompanyId}
                  placement="right"
                  maxWidth="none"
                />
              </span>
            </Grid>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Global Company ID"
              value={globalCompanyId}
              onChange={event => setGlobalCompanyId(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Client ID</InputLabel>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Client ID"
              value={clientId}
              onChange={event => setClientId(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Client Secret</InputLabel>
            <TextValidator
              fullWidth
              type="text"
              variant="filled"
              placeholder="Client Secret"
              value={clientSecret}
              onChange={event => setClientSecret(event.target.value.trim())}
              validators={['required']}
              errorMessages={['* Required']}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
              Save Credentials
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

LinkAdobeAnalyticsAccountForm.propTypes = {
  history: PropTypes.shape().isRequired
};

export default withRouter(LinkAdobeAnalyticsAccountForm);
