const SOCIAL_DISPLAY_NAME_ENUM = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  INSTAGRAM_STORY: 'Instagram Story',
  TWITTER: 'Twitter',
  LINKEDIN: 'LinkedIn',
  YOUTUBE: 'YouTube',
  TIKTOK: 'TikTok',
  GOOGLE: 'Google',
  GOOGLE_ANALYTICS: 'Google Analytics',
  GOOGLE_WEB: 'Google Web',
  GOOGLE_VIEW: 'Google View',
  SOCIAL_ANIMAL: 'Social Animal',
  ADOBE_ANALYTICS: 'Adobe Analytics',
  APP_STORE_CONNECT_APP: 'Apple',
  GOOGLE_PLAY_CONSOLE_APP: 'Android',
  APPLE_APP_STORE_CONNECT: 'App Store',
  GOOGLE_PLAY_CONSOLE: 'Google Play',
  OTHER: 'Other'
};

export default SOCIAL_DISPLAY_NAME_ENUM;
