import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton, SvgIcon } from '@material-ui/core';
import SocialLogo from 'components/SocialLogo';
import AlembicAvatar from 'components/AlembicAvatar';
import AddToProgramLiftButton from 'components/AddToProgramLiftButton';
import { convertToUserTimeZone } from 'util/date';
import { openExternalPost } from 'util/externalLinks';
import getSimpleSocialType from 'util/getSimpleSocialType';
import style from './detailsHeaderStyle';

const useStyles = makeStyles(style);

/**
 * @method
 * @summary This component renders the post header at the top of @see PostDrawerView inside @see PostDrawerDetails
 * @name DetailsHeader
 * @param {Object} props - React props passed to this component
 * @return {Object} - React JSX
 */
const DetailsHeader = props => {
  const { currentUser, postDetails } = props;
  const classes = useStyles();

  // fetch reports that include the campaign event already.
  const { linkTokenType, completedAt, avatarUrl, remoteName, publishedUrl } = postDetails;

  const formatPostTimeAndDate = () => {
    return `${convertToUserTimeZone(completedAt, currentUser.time_zone).format(
      'MM/DD/YY'
    )} @ ${convertToUserTimeZone(completedAt, currentUser.time_zone).format('h:mma')}`;
  };

  const [simpleSocialType, setSimpleSocialType] = useState(null);

  useEffect(() => {
    const loadSocialType = async () => {
      const socialType = await getSimpleSocialType(linkTokenType);
      setSimpleSocialType(socialType);
    };

    loadSocialType();
  }, [linkTokenType]);

  return (
    <div className={classes.textHeader}>
      <div className={classes.textHeaderContent}>
        {linkTokenType && <SocialLogo width={27} height={27} type={simpleSocialType} />}

        <div className={classes.avatar}>
          <AlembicAvatar avatarSrc={avatarUrl} width={27} height={27} />
        </div>

        <div className={classes.handleContainer}>
          <Typography className={classes.textHandle}>{remoteName}</Typography>
          <Typography className={classes.textTime}>{formatPostTimeAndDate()}</Typography>
        </div>
      </div>

      <AddToProgramLiftButton campaignEventId={postDetails.id} />

      <IconButton
        className={classes.externalLinkButton}
        onClick={() => {
          if (publishedUrl) {
            openExternalPost(publishedUrl);
          }
        }}
        disabled={!publishedUrl}
      >
        <SvgIcon viewBox="0 0 19 20" color="inherit" fontSize="small">
          <path d="M18.9954 1.6415C18.9954 1.05853 18.5228 0.585939 17.9398 0.585939L11.6065 0.585938C11.0235 0.585938 10.551 1.05853 10.551 1.6415C10.551 2.22446 11.0235 2.69706 11.6065 2.69706H15.5985L10.2457 8.04987C9.83347 8.4621 9.83347 9.13043 10.2457 9.54266C10.6579 9.95488 11.3263 9.95488 11.7385 9.54266L16.8843 4.3968V7.97484C16.8843 8.5578 17.3569 9.0304 17.9398 9.0304C18.5228 9.0304 18.9954 8.5578 18.9954 7.97484V1.6415Z" />
          <path d="M8.44445 1.64149C8.44445 1.05853 7.97186 0.585938 7.3889 0.585938H2.11111C0.945178 0.585938 0 1.53111 0 2.69705V17.4748C0 18.6408 0.945177 19.5859 2.11111 19.5859H16.8889C18.0549 19.5859 19 18.6408 19 17.4748V12.197C19 11.6141 18.5274 11.1415 17.9445 11.1415C17.3615 11.1415 16.8889 11.6141 16.8889 12.197V17.4748H2.11111V2.69705H7.3889C7.97186 2.69705 8.44445 2.22447 8.44445 1.64149Z" />
        </SvgIcon>
      </IconButton>
    </div>
  );
};

DetailsHeader.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  postDetails: PropTypes.shape()
};

DetailsHeader.defaultProps = {
  postDetails: {
    linkTokenType: null,
    completedAt: null,
    avatarUrl: null,
    remoteName: null,
    publishedUrl: null
  }
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(DetailsHeader);
