import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import theme from 'theme';

const sortByTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    },
    MuiSelect: {
      select: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        paddingTop: '11px',
        paddingLeft: '16px',

        '&:focus': {
          backgroundColor: 'unset'
        }
      }
    }
  }
});

const useStyles = makeStyles({
  select: {
    width: '190px',
    height: '41px',
    color: '#0D1A3A',
    marginLeft: '10px'
  }
});

const options = [
  { field: 'startDate', label: 'Start Date' },
  { field: 'endDate', label: 'End Date' },
  { field: 'revenue', label: 'Revenue' }
];

/**
 * SortByDropdown component
 * @description A dropdown component for sorting by a given field
 * @param {Object} props - The component props
 * @param {string} props.sort - The current sort value
 * @param {function} props.setSort - The function to set the sort value
 * @returns {JSX.Element} The SortByDropdown component
 */
function SortByDropdown(props) {
  const { sort, setSort } = props;
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={sortByTheme}>
      <span>
        Sort By:
        <Select
          className={classes.select}
          variant="outlined"
          labelId="input-label"
          value={sort}
          onChange={e => setSort(e.target.value)}
          IconComponent={ExpandMore}
        >
          {options.map(({ field, label }) => (
            <MenuItem key={field} value={field} onClick={() => {}}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </span>
    </MuiThemeProvider>
  );
}

SortByDropdown.propTypes = {
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired
};

export default SortByDropdown;
