const formatSalesforceDimension = dimension => {
  if (dimension) {
    return dimension
      .split('_')
      .map(str => str.charAt(0).toUpperCase() + str.substr(1))
      .join(' ');
  }

  return '';
};

export default formatSalesforceDimension;
