import { TV_ICON, RADIO_ICON, PODCAST_ICON, ARTICLE_ICON, TV_AND_RADIO_ICON } from 'util/assets';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';
import getSimpleSocialType from 'util/getSimpleSocialType';

export const ICON_TYPES = [
  { name: 'podcast', src: PODCAST_ICON },
  { name: 'broadcast', src: TV_ICON },
  { name: 'tv', src: TV_ICON },
  { name: 'radio', src: RADIO_ICON },
  { name: 'web', src: ARTICLE_ICON },
  { name: 'social_animal', src: ARTICLE_ICON },
  { name: 'broadcast_and_radio', src: TV_AND_RADIO_ICON }
];

export const getIconSrc = type => {
  const iconObj = ICON_TYPES.find(icon => icon.name === type);

  if (iconObj && iconObj.src) {
    return iconObj.src;
  }

  return '';
};

export const TYPE = {
  REPORT: 'report',
  GROUP: 'group'
};

export const BUTTON_TEXT = {
  CREATE: '+ Create Group',
  UPDATE: 'Update Group'
};

export const MENTION_GROUP_SOURCE_ENUM = {
  TV: 'TV',
  RADIO: 'RADIO',
  PODCAST_TRANSCRIPT: 'PODCAST_TRANSCRIPT',
  PODCAST_SUMMARY: 'PODCAST_SUMMARY',
  WEB: 'WEB'
};

export const excludedRatingsSourceTypes = [MENTION_GROUP_SOURCE_ENUM.PODCAST_SUMMARY];

export const mapSourceToEventType = mentionGroupSource => {
  switch (mentionGroupSource) {
    case MENTION_GROUP_SOURCE_ENUM.TV:
      return DETECTED_EVENT_TYPE.BC_TV_MENTIONS;
    case MENTION_GROUP_SOURCE_ENUM.RADIO:
      return DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS;
    case MENTION_GROUP_SOURCE_ENUM.PODCAST_TRANSCRIPT:
      return DETECTED_EVENT_TYPE.PC_TRANSCRIPT_MENTIONS;
    case MENTION_GROUP_SOURCE_ENUM.PODCAST_SUMMARY:
      return DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS;
    default:
      return null;
  }
};

export const mapEventTypeToIcon = async eventType => {
  const simpleType = await getSimpleSocialType(eventType);

  return getIconSrc(simpleType);
};
