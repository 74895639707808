import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MuiTablePagination from '@material-ui/core/TablePagination';
import { IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import theme from 'theme';
import PageOptions from './PageOptions';

const useStyles = makeStyles({
  actions: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
    flex: 1
  }
});

const paginationTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiTablePagination: {
      root: {
        flex: 1
      },
      toolbar: {
        padding: '24px'
      },
      spacer: {
        display: 'none'
      },
      input: {
        zIndex: 1
      }
    }
  }
});

const TablePaginationActions = props => {
  const { count, onPageChange, page: currentPage, rowsPerPage } = props;

  const classes = useStyles();

  const lastPage = Math.ceil(count / rowsPerPage);

  return (
    <Box className={classes.actions}>
      <IconButton
        onClick={e => onPageChange(e, currentPage - 1)}
        disabled={currentPage === 0}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <PageOptions page={currentPage} lastPage={lastPage} onPageChange={onPageChange} />
      <IconButton
        onClick={e => onPageChange(e, currentPage + 1)}
        disabled={currentPage >= lastPage - 1}
        aria-label="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const TablePagination = props => {
  const {
    count,
    page,
    rowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels,
    rowsPerPageOptions
  } = props;

  const safetyPage = useMemo(() => {
    const maxPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
    return Math.min(Math.max(0, page), maxPage);
  }, [count, page, rowsPerPage]);

  return (
    <TableFooter>
      <TableRow>
        <TableCell
          style={{
            display: 'flex',
            padding: '0px'
          }}
          colSpan={1000}
        >
          <MuiThemeProvider theme={paginationTheme}>
            <MuiTablePagination
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={safetyPage}
              labelRowsPerPage={textLabels?.rowsPerPage}
              labelDisplayedRows={() => null}
              backIconButtonProps={{
                'aria-label': textLabels?.previous
              }}
              nextIconButtonProps={{
                'aria-label': textLabels?.next
              }}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageChange={(_, currentPage) => changePage(currentPage)}
              onRowsPerPageChange={e => changeRowsPerPage(e.target.value)}
              ActionsComponent={TablePaginationActions}
            />
          </MuiThemeProvider>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

TablePagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  changeRowsPerPage: PropTypes.func,
  changePage: PropTypes.func,
  textLabels: PropTypes.shape(),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number)
};

TablePagination.defaultProps = {
  count: 0,
  page: 0,
  rowsPerPage: 15,
  changeRowsPerPage: null,
  changePage: null,
  textLabels: {},
  rowsPerPageOptions: [15, 30, 50]
};

const CustomFooter = (
  footerCount,
  footerPage,
  footerRowsPerPage,
  changeRowsPerPage,
  changePage,
  textLabels,
  rowsPerPageOptions
) => {
  const safeFooterPage = Math.min(
    Math.max(0, footerPage),
    Math.max(0, Math.ceil(footerCount / footerRowsPerPage) - 1)
  );

  return (
    <TablePagination
      count={footerCount}
      page={safeFooterPage}
      rowsPerPage={footerRowsPerPage}
      changeRowsPerPage={changeRowsPerPage}
      changePage={changePage}
      textLabels={textLabels}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

export default CustomFooter;
