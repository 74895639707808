import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import theme from 'theme';
import useDebounce from '../../hooks/useDebounce';

const searchTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInputBase: {
      root: {
        maxWidth: '213px'
      },
      input: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        '&::placeholder': {
          color: '#0D1A3A',
          opacity: 1
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: '10px'
      }
    }
  }
});

/**
 * SearchBox component
 * @description For searching causal chains
 * @param {Object} props - The component props
 * @param {string} props.searchInputValue - The current search input value
 * @param {function} props.setSearchInputValue - The function to set the search input value
 * @param {string} props.sort - The current sort value
 * @param {array} props.terminatingEvents - The terminating events
 * @returns {JSX.Element} The SearchBox component
 */
function SearchBox(props) {
  const { searchInputValue, setSearchInputValue } = props;
  const [inputValue, setInputValue] = React.useState(searchInputValue);
  const debouncedValue = useDebounce(inputValue, 500);

  // Update parent state when debounced value changes
  React.useEffect(() => {
    setSearchInputValue(debouncedValue);
  }, [debouncedValue]);

  const handleSearchInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleSearchSubmit = e => {
    e.preventDefault();
  };

  return (
    <MuiThemeProvider theme={searchTheme}>
      <form onSubmit={handleSearchSubmit}>
        <TextField
          fullWidth
          id="standard-bare"
          variant="outlined"
          placeholder="Search"
          value={inputValue}
          onChange={handleSearchInputChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            )
          }}
        />
      </form>
    </MuiThemeProvider>
  );
}

SearchBox.propTypes = {
  searchInputValue: PropTypes.string.isRequired,
  setSearchInputValue: PropTypes.func.isRequired
};

export default SearchBox;
