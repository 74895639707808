import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import colors from 'util/colors';
import camelCase from 'camelcase';
import tooltipText from 'util/tooltipText';
import PropTypes from 'prop-types';

/**
 * @method
 * @summary This component returns the text that is used in the title prop of the Tooltip component.
 *          Note, some of the incoming text has characters that break the Tooltip component, so we need to
 *          replace or escape those characters, which is why we use multiple `replace` methods.
 *          Also, there are three 'smoke' test values that start with 'test'... these are used to test the
 *          functionality of the tooltip.  The testNewLine, testArray and testObject values break by design.
 * @example
 *         <AlbTooltip title={getToolTipTitleValue("testText")}> // looks for the key 'testText' and returns "basic smoke test"
 *         <AlbTooltip title={getToolTipTitleValue("testText", "")}> // looks for the key 'testText' and returns "basic smoke test"
 *         <AlbTooltip title={getToolTipTitleValue("Text", "test")}> // looks for the key 'test' + 'Test' and returns "basic smoke test" as the values text and test are concatenated and camelCased to testText
 *         <AlbTooltip title={getToolTipTitleValue("testUnknownKey")}> // looks for the key 'testUnknownKey", does not find it and returns "" (an empty string)
 *         <AlbTooltip title={getToolTipTitleValue("", "testText")}> // also returns "basic smoke test" though this is not the intended use case
 * @name getToolTipTitleValue
 * @param {String} key - The key of the text object to return.  This is typically the title text used in the Tab or similar component.
 * @param {String} prefix - An optional prefix added to the key to help reduce or remove namespace conflicts
 * @return {String} - Returns the text that is used in the title prop of the Tooltip component, if we don't have the requested key, we return an empty string.
 */
const getToolTipTitleValue = (key, prefix = '') => {
  const clean = key
    .replace('+', 'Plus')
    .replace(/&/g, 'And')
    .replace('%', 'Percent')
    .replace(/\?|\//g, '');
  const tooltipKey = camelCase(prefix + clean);
  if (!(tooltipKey in tooltipText)) {
    // console.log(
    //   `looking for: ${tooltipKey} received: ${key} and ${prefix !== '' ? prefix : '[empty]'}`
    // );
    return '';
  }
  return tooltipText[tooltipKey];
};

/**
 * @method
 * @summary Use this function to get the styling for the info icon in the Tooltip component.
 * Styling Information:
 * default:
 *  width: 12px
 *  height: 12px
 *  color: #979797 aka colors.darkGray
 * @param {String} iconColorKey - used to specify the color of the info icon, currently only 'white' is supported
 * @param {Object} styles used to style the tooltip text
 */
const getIconAttributes = iconColorKey => {
  const iconColor = iconColorKey === 'white' ? colors.white : colors.darkGray;

  const infoIconAttributes = {
    color: iconColor,
    fontSize: 12
  };
  return infoIconAttributes;
};

/**
 * @method
 * @summary Use this to get a styled tooltip component.
 * @example
 *   <AlbTooltip title="An arbitrary string used as the tooltip title text" placement="top" />
 *   <AlbTooltip title="arbitrary string" lookupKey="bar" prefix="foo" /> // will use 'arbitrary string' as the tooltip title text, and will ignore lookupKey and prefix
 *   <AlbTooltip title={tooltipText.sbImpressions} lookupKey="bar" prefix="foo" /> // will look up the tooltip title text in the tooltipText object with the 'sbImpressions' key, and will ignore lookupKey and prefix
 *   <AlbTooltip lookupKey="Impressions" prefix="sb" /> // will look for the key 'sbImpressions' and return the text from the tooltipText object
 *   <AlbTooltip lookupKey="Impressions" prefix="sb" iconColor="white" /> // same as above but with white icon
 *
 * @param {Object} props - the props passed to this component
 * @param {String} props.title - (optional) title to be used in the tooltip, if provided this will be used instead of whatever would have been returned by getToolTipTitleValue()
 * @param {String} props.lookupKey - (optional) key used with prefix to lookup the text in the tooltipText object
 * @param {String} props.prefix - (optional) prefix used with key to lookup the text in the tooltipText object
 * @param {String} props.iconColor - (optional) used to change the color of the InfoIcon, either 'white' or the default 'darkGray'
 * @param {String} props.placement - (optional) If supplied this will be used to position the tooltip, if not supplied the default is 'bottom'
 * @return {React.Component} - Returns a styled tooltip component.
 */

const AlbTooltip = props => {
  const { title, lookupKey, prefix, iconColor, placement, maxWidth } = props;

  const styles = makeStyles({
    tooltip: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      backgroundColor: colors.black80,
      borderRadius: '3px',
      padding: '15px',
      ...(maxWidth && { maxWidth })
    }
  });
  const classes = styles();

  const tooltipTitle = lookupKey || prefix ? getToolTipTitleValue(lookupKey, prefix) : title;

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle} classes={{ tooltip: classes.tooltip }} placement={placement}>
      <InfoIcon style={getIconAttributes(iconColor)} />
    </Tooltip>
  ) : (
    <></>
  );
};

AlbTooltip.propTypes = {
  title: PropTypes.string,
  lookupKey: PropTypes.string,
  prefix: PropTypes.string,
  iconColor: PropTypes.string,
  placement: PropTypes.string,
  maxWidth: PropTypes.string
};

AlbTooltip.defaultProps = {
  title: '',
  lookupKey: '',
  prefix: '',
  iconColor: colors.darkGray,
  placement: 'bottom',
  maxWidth: null
};

// getIconAttributes() is included so the test can find the function to test it.
export { AlbTooltip, getToolTipTitleValue, getIconAttributes };
