/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography
} from '@material-ui/core';
import { DASHBOARD_INTELLIGENCE_EVENTS } from 'gql/dashboardIntelligenceEvent';
import { COINCIDENT_EVENT_GROUPS } from 'gql/coincidentEventGroup';
import { COINCIDENT_EVENTS_HEATMAP } from 'gql/coincident_event';
import { GET_ANNOTATIONS_FOR_CONTAINER } from 'gql/annotation';
import { useQuery } from '@apollo/client';
import { getDatesForAnalytics } from 'util/date';
import openDashboardDrawer from 'util/drawerUtil';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import AlbLoading from 'components/AlbLoading';
import SocialLogo from 'components/SocialLogo';
import Box from 'components/Box';
import { getDetectedEventProps } from 'components/DetectedEventsTable/DetectedEventsTable';
import { AnnotationBlueIcon, FireEmojiIcon } from 'util/assets';
import handleGraphQLError from 'util/error';
import formatValueWithSymbol from 'util/formatValueWithSymbol';
import SIMPLE_SOCIAL_TYPE_ENUM from 'util/getSimpleSocialTypeEnum';
import ActivityGraph from './ActivityGraph';

const CoincidenceEventsHeatmap = ({ isToggleSwitchOn, selectedDates }) => {
  const [squareSize, setSquareSize] = useState(28);
  const [fontSize, setFontSize] = useState(15);
  const [minWidth, setMinWidth] = useState(110);
  const squareHorizontalGap = 3;
  const annotationHeight = 5;
  const flyoverMaxWidth = 750;
  const flyoverColumns = ['LENGTH', 'METRIC', '% CHANGE', 'VALUATION CHANGE', 'SOURCE', 'DETAILS'];
  const { start, end } = getDatesForAnalytics(
    moment()
      .subtract(30, 'd')
      .utc()
      .toISOString(),
    moment()
      .subtract(1, 'd')
      .utc()
      .toISOString()
  );
  const drawerContext = useContext(DashboardIntelligenceDrawerContext);
  const [heatmapDateRange, setHeatmapDateRange] = useState({ start, end });
  const [activityData, setActivityData] = useState([]);
  const [correlationsA, setCorrelationsA] = useState({
    regularEvents: [],
    overlapEvents: []
  });
  const [correlationsB, setCorrelationsB] = useState({
    regularEvents: [],
    overlapEvents: []
  });
  const [correlationsC, setCorrelationsC] = useState({
    regularEvents: [],
    overlapEvents: []
  });
  const [days, setDays] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [annotationsLookup, setAnnotationsLookup] = useState({});
  const [annotationsByDayLookup, setAnnotationsByDayLookup] = useState([]);
  const types = ['media', 'social', 'web', 'conversion'];

  // 'media query' substitute used here since the style object would be unreasonably long if done in CSS
  const handleResize = () => {
    let newSquareSize = 28;
    let newFontSize = 15;
    let newMinWidth = 110;

    if (window.innerWidth <= 1280) {
      newSquareSize = 24;
      newFontSize = 12;
      newMinWidth = 100;
    }

    if (window.innerWidth <= 1140) {
      newSquareSize = 20;
      newFontSize = 10;
      newMinWidth = 92;
    }

    setSquareSize(newSquareSize);
    setFontSize(newFontSize);
    setMinWidth(newMinWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  const useStyles = makeStyles({
    container: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
    },
    // Header
    tabOverRide: {
      minWidth: 'unset',
      maxWidth: 'unset',
      cursor: 'default',
      fontSize: '18px',
      fontWeight: '500'
    },
    // Body
    bodyContainer: {
      minHeight: '240px',
      position: 'relative',
      padding: '24px'
    },
    loadingOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'none',
      zIndex: 1
    },
    graph: {
      position: 'relative',
      display: 'inline-grid',
      gridTemplateAreas: `"correlations0 pills0" "correlationsA pillsA" "correlationsB pillsB" "correlationsC pillsC" "spacer spacer" "empty days" "empty annotations"`,
      gridTemplateRows: `60px 60px 60px 60px 10px ${squareSize * 2}px ${squareSize}px`,
      gridTemplateColumns: `${minWidth}px 1fr`,
      columnGap: '10px',

      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: `${fontSize}px`,
      color: '#6F6F6F'
    },
    correlations0: {
      gridArea: 'correlations0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    correlationsA: {
      gridArea: 'correlationsA',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    correlationsB: {
      gridArea: 'correlationsB',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    correlationsC: {
      gridArea: 'correlationsC',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    pills0: {
      gridArea: 'pills0',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 1.5px'
    },
    pillsA: {
      gridArea: 'pillsA',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 1.5px'
    },
    pillsB: {
      gridArea: 'pillsB',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 1.5px'
    },
    pillsC: {
      gridArea: 'pillsC',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 1.5px'
    },
    grid0: {
      gridArea: 'pills0',
      display: 'grid',
      gridTemplateColumns: 'repeat(30, 1fr)',
      '& > span': {
        border: '1px solid #ECECEC'
      },

      '& > span:not(:last-child)': {
        borderRight: 'none'
      }
    },
    gridA: {
      gridArea: 'pillsA',
      display: 'grid',
      gridTemplateColumns: 'repeat(30, 1fr)',
      '& > span': {
        border: '1px solid #ECECEC'
      },

      '& > span:not(:last-child)': {
        borderRight: 'none'
      }
    },
    gridB: {
      gridArea: 'pillsB',
      display: 'grid',
      gridTemplateColumns: 'repeat(30, 1fr)',
      '& > span': {
        border: '1px solid #ECECEC'
      },

      '& > span:not(:last-child)': {
        borderRight: 'none'
      }
    },
    gridC: {
      gridArea: 'pillsC',
      display: 'grid',
      gridTemplateColumns: 'repeat(30, 1fr)',
      '& > span': {
        border: '1px solid #ECECEC'
      },

      '& > span:not(:last-child)': {
        borderRight: 'none'
      }
    },
    pill: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '3px',
      textAlign: 'center',
      fontSize: '10px',
      color: '#FFFFFF',
      height: `${squareSize}px`,
      cursor: 'pointer'
    },
    overlapPill: {
      position: 'relative',
      top: `${squareSize + 5}px`,
      height: '5px',
      borderRadius: '5px'
    },
    annotations: {
      display: 'grid',
      gridArea: 'annotations',
      columnGap: `${squareHorizontalGap}px`,
      marginTop: `${squareSize * -1}px`,
      padding: '0px 1.5px'
    },
    annotation: {
      position: 'relative',
      backgroundColor: '#009FC4',
      borderRadius: `${annotationHeight}px`,
      height: `${annotationHeight}px`,
      opacity: '50%'
    },
    annotationDays: {
      display: 'grid',
      gridArea: 'annotations',
      gridTemplateColumns: `repeat(30, 1fr)`,
      marginTop: `${squareSize * -1}px`,
      columnGap: '3px',
      padding: '0px 1.5px'
    },
    annotationDay: {
      position: 'relative',
      height: `${annotationHeight}px`
    },
    flyover: {
      position: 'absolute',
      backgroundColor: 'white',
      boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.2)',
      borderRadius: '3px',
      padding: '10px',
      zIndex: 2,
      maxWidth: `${flyoverMaxWidth}px`,
      cursor: 'default',

      '& > table': {
        display: 'block',
        overflow: 'hidden'
      }
    },
    flyoverTitle: {
      color: '#0A1734',
      whiteSpace: 'nowrap',
      marginBottom: '10px',
      fontSize: '15px',
      textAlign: 'left'
    },
    flyoverHeader: {
      '& > td': {
        padding: '0px 15px 10px 0px',
        fontSize: '12px',
        fontWeight: 500,
        borderBottom: 'none',
        whiteSpace: 'nowrap'
      },

      '& > :last-child': {
        paddingRight: '0px'
      }
    },
    flyoverRow: {
      '& > td': {
        padding: '0px 15px 10px 0px',
        fontSize: '12px',
        borderBottom: 'none'
      },

      '& > td:nth-child(-n+4)': {
        paddingRight: '10px'
      },

      '& > :nth-last-child(2)': {
        width: '150px'
      },

      '& > :last-child': {
        width: '150px',
        paddingRight: '0px'
      }
    },
    detailsButton: {
      border: '1px solid #6F6F6F',
      color: '#6F6F6F',

      '& > span': { fontSize: '12px' }
    },
    cellContent: {
      alignItems: 'center',
      whiteSpace: 'nowrap',
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '& > img': {
        marginRight: '5px',
        verticalAlign: 'middle'
      },

      '& > span': {
        verticalAlign: 'middle'
      }
    },
    days: {
      display: 'grid',
      gridArea: 'days',
      gridGap: `${squareHorizontalGap}px`,
      gridAutoFlow: 'column',
      gridAutoColumns: squareSize,
      lineHeight: `${squareSize}px`,
      overflow: 'hidden',
      padding: '0px 1.5px'
    },
    day: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      color: '#32327D'
    },
    // faux days is a grid style position on top of the orange pills to get the day.
    fauxDays: {
      display: 'grid',
      gridArea: 'days',
      gridAutoFlow: 'column',
      gridAutoColumns: `${squareSize + 3}px`,
      position: 'absolute',
      marginTop: '-5px',
      height: '5px',
      top: `${squareSize + 5}px`
    },
    fauxDay: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      color: '#32327D',
      maxHeight: '5px'
    },
    monthStart: {
      borderLeft: '1px solid #979797',
      height: `${squareSize * 2}px`
    },
    selectedDate: {
      position: 'absolute',
      backgroundColor: 'transparent',
      height: `${250 + squareSize + annotationHeight}px`,
      width: `${squareSize}px`,
      border: '1px solid #32327D',
      borderRadius: '3px',
      marginBottom: `${squareSize * 2 - annotationHeight}px`,
      bottom: '0px',
      pointerEvents: 'none'
    },
    annotationIcon: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginRight: '10px'
    },
    annotationsList: {
      display: 'grid',

      '& > :not(:last-child)': {
        marginBottom: '10px'
      }
    },
    annotationRow: {
      display: 'flex',
      color: '#000000',
      fontWeight: 400
    },
    annotationText: {
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      lineClamp: 2,
      wordBreak: 'break-word',
      fontSize: '12px',
      marginRight: '10px'
    },
    annotationDates: {
      whiteSpace: 'nowrap',
      fontSize: '12px'
    },
    legendText: {
      fontWeight: '500',
      fontSize: '14px',
      color: '#6F6F6F'
    }
  });

  const classes = useStyles();

  // map datePicker to heatmap. We want the date to be in the middle, unless there are not enough days.
  useEffect(() => {
    // check if we need to move the heatmap range.
    // Per spec, only need to move if selected date is within the last or first 4 days of the range.
    const startThreshold = moment.utc(heatmapDateRange.start).add(4, 'days');
    const endThreshold = moment.utc(heatmapDateRange.end).subtract(4, 'days');

    if (
      moment.utc(selectedDates.start) < startThreshold ||
      moment.utc(selectedDates.start) > endThreshold
    ) {
      const lastPossibleDate = moment.utc().subtract(1, 'days');

      let heatmapEndDate = lastPossibleDate;
      let heatmapStartDate = moment.utc(lastPossibleDate).subtract(29, 'days');

      if (moment.utc(selectedDates.start).add(15, 'd') < lastPossibleDate) {
        heatmapEndDate = moment.utc(selectedDates.start).add(15, 'd');
        heatmapStartDate = moment.utc(selectedDates.start).subtract(14, 'd');
      }

      // always use the getDatesForAnalytics function when setting heatmapDateRange to properly convert the time for the query.
      const { start: startDate, end: endDate } = getDatesForAnalytics(
        heatmapStartDate,
        heatmapEndDate
      );

      setHeatmapDateRange({ start: startDate, end: endDate });
    }
  }, [selectedDates]);

  const { data: detectedEventsByGroupData, loading: eventsLoading, error: eventsError } = useQuery(
    DASHBOARD_INTELLIGENCE_EVENTS,
    {
      variables: {
        types: types.map(type => type.toUpperCase()),
        startDate: heatmapDateRange.start,
        endDate: heatmapDateRange.end,
        enableNewDetectedEvents: true,
        useNewEventToggle: !isToggleSwitchOn
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (detectedEventsByGroupData?.dashboardIntelligenceEvents?.all) {
      const { all } = detectedEventsByGroupData.dashboardIntelligenceEvents;

      // generateSquares(detectedEventsByGroupData.dashboardIntelligenceEvents);
      setDays(all.map(({ day }) => [day, moment.utc(day).format('D')]));
    }
  }, [detectedEventsByGroupData]);

  const separateOverlappingIntervals = events => {
    const nonOverlap = [];
    const overlap = [];

    if (events.length > 0) {
      // Pre-sort the events by the start date
      const sortFn = (a, b) => {
        const startDateA = moment.utc(a.coinc_start_date);
        const startDateB = moment.utc(b.coinc_start_date);

        if (startDateA.isBefore(startDateB)) {
          return -1;
        }

        if (startDateA.isAfter(startDateB)) {
          return 1;
        }

        return 0;
      };
      events.sort(sortFn);

      // Remove overlapping intervals
      let prevEnd = moment.utc(events[0].coinc_end_date);
      let prevEvent = events[0];
      for (let i = 1; i < events.length; i += 1) {
        const startDate = moment.utc(events[i].coinc_start_date);

        if (startDate.isAfter(prevEnd)) {
          nonOverlap.push(prevEvent);
          prevEnd = moment.utc(events[i].coinc_end_date);
          prevEvent = events[i];
        } else {
          const min = moment.utc(events[i].coinc_end_date);

          // We push the event with the longer end date to the overlap array
          if (min.isAfter(prevEnd)) {
            overlap.push(events[i]);
          } else {
            overlap.push(prevEvent);
            prevEnd = min;
            prevEvent = events[i];
          }
        }
      }

      nonOverlap.push(prevEvent);
    }

    return {
      nonOverlap,
      overlap
    };
  };

  const mergeOverLappingIntervals = events => {
    const result = [];

    // Merge overlapping intervals
    if (events.length > 0) {
      result.push({
        id: events[0].id,
        coinc_start_date: events[0].coinc_start_date,
        coinc_end_date: events[0].coinc_end_date,
        events: [events[0]]
      });

      for (let i = 1; i < events.length; i += 1) {
        const lastMergedEndDate = moment.utc(result[result.length - 1].coinc_end_date);
        const currentStartDate = moment.utc(events[i].coinc_start_date);

        if (currentStartDate.isAfter(lastMergedEndDate)) {
          result.push({
            id: events[i].id,
            coinc_start_date: events[i].coinc_start_date,
            coinc_end_date: events[i].coinc_end_date,
            events: [events[i]]
          });
        } else {
          const currentEndDate = moment.utc(events[i].coinc_end_date);

          if (currentEndDate.isAfter(lastMergedEndDate)) {
            result[result.length - 1].coinc_end_date = events[i].coinc_end_date;
          }

          result[result.length - 1].events.push(events[i]);
        }
      }
    }

    return result;
  };

  const buildPills = coincidentEvents => {
    const heatmapStartDate = moment.utc(heatmapDateRange.start);
    const heatmapEndDate = moment.utc(heatmapDateRange.end);

    const { list } = coincidentEvents.reduce(
      (
        acc,
        { id, coinc_start_date: coincStart, coinc_end_date: coincEnd, detectedEvents, ...rest }
      ) => {
        // the dates here tend to be fragile since we're displaying pills with 1 extra day appended from the queried data
        // if there is a problem with the pill width or heatmap alignment this would be the place to look
        const previousEndDate = moment.utc(acc.prevDate);
        const correlationStartDate = moment.utc(coincStart);
        // +1 day append here
        const correlationEndDate = moment.utc(coincEnd).add(1, 'd');

        /*
          Scenario 1: Correlation start/end day is between window start day

          - - - | 30 Day Window | - - -
          - - | Correlation | - - - - - 
        */
        if (
          correlationStartDate.isBefore(heatmapStartDate) &&
          correlationEndDate.isSameOrBefore(heatmapEndDate)
        ) {
          const length = correlationEndDate.diff(previousEndDate, 'd');

          acc.list.push({
            length,
            count: detectedEvents.length || 1,
            id,
            detectedEvents,
            coincStart,
            coincEnd,
            ...rest
          });
          acc.prevDate = coincEnd;
        }

        /*
          Scenario 2: Correlation start/end day is after last event date and between window

          - - - | # # # # # # # # 30 Day Window # # # # # # # # | - - -
          - - - - | Last Event | - - | Correlation | - - - - - - - - - -
        */
        if (
          correlationStartDate.isSameOrAfter(previousEndDate) &&
          correlationStartDate.isSameOrAfter(heatmapStartDate) &&
          correlationEndDate.isSameOrBefore(heatmapEndDate)
        ) {
          const gapLength = correlationStartDate.diff(previousEndDate, 'd');

          if (gapLength >= 1) {
            acc.list.push({
              length: gapLength,
              count: 0,
              id: `${id}gap`
            });
          }

          // some weirdness with moment.diff if 3rd arg (precise diff) is omitted here
          const length = Math.round(correlationEndDate.diff(correlationStartDate, 'd', true));
          acc.list.push({
            length,
            count: detectedEvents.length || 1,
            id,
            detectedEvents,
            coincStart,
            coincEnd,
            ...rest
          });
        }

        /*
          Scenario 3: Correlation start/end day is between window end day

          - - - | 30 Day Window | - - -
          - - - - - - | Correlation | - 
        */
        if (
          correlationStartDate.isSameOrAfter(previousEndDate) &&
          correlationStartDate.isSameOrAfter(heatmapStartDate) &&
          correlationEndDate.isAfter(heatmapEndDate)
        ) {
          const gapLength = correlationStartDate.diff(previousEndDate, 'd');

          if (gapLength >= 1) {
            acc.list.push({
              length: gapLength,
              count: 0,
              id: `${id}gap`
            });
          }

          // some weirdness with moment.diff if 3rd arg (precise diff) is omitted here
          const length = Math.round(heatmapEndDate.diff(correlationStartDate, 'd', true));
          acc.list.push({
            length,
            count: detectedEvents.length || 1,
            id,
            detectedEvents,
            coincStart,
            coincEnd,
            ...rest
          });
        }

        /*
          Scenario 4: Correlation start/end day is between window end day

          - - - | 30 Day Window | - - -
          - - |    Correlation    | - -
        */
        if (
          correlationStartDate.isBefore(heatmapStartDate) &&
          correlationEndDate.isAfter(heatmapEndDate)
        ) {
          // some weirdness with moment.diff if 3rd arg (precise diff) is omitted here
          const length = Math.round(heatmapEndDate.diff(heatmapStartDate, 'd', true));
          acc.list.push({
            length,
            count: detectedEvents.length || 1,
            id,
            detectedEvents,
            coincStart,
            coincEnd,
            ...rest
          });
        }

        acc.prevDate = correlationEndDate;

        return acc;
      },
      { list: [], prevDate: heatmapDateRange.start }
    );

    return list;
  };

  const buildOverlapPills = coincidentEvents => {
    const heatmapStartDate = moment.utc(heatmapDateRange.start);
    const heatmapEndDate = moment.utc(heatmapDateRange.end);

    const { list } = coincidentEvents.reduce(
      (acc, { id, coinc_start_date: coincStart, coinc_end_date: coincEnd, events }) => {
        const previousEndDate = moment.utc(acc.prevDate);
        const correlationStartDate = moment.utc(coincStart);
        const correlationEndDate = moment.utc(coincEnd).add(1, 'd');

        if (
          correlationStartDate.isBefore(heatmapStartDate) &&
          correlationEndDate.isSameOrBefore(heatmapEndDate)
        ) {
          const length = correlationEndDate.diff(previousEndDate, 'd');

          acc.list.push({
            id,
            length,
            events,
            coincStart,
            coincEnd
          });
          acc.prevDate = coincEnd;
        }

        if (
          correlationStartDate.isSameOrAfter(previousEndDate) &&
          correlationStartDate.isSameOrAfter(heatmapStartDate) &&
          correlationEndDate.isSameOrBefore(heatmapEndDate)
        ) {
          const gapLength = correlationStartDate.diff(previousEndDate, 'd');

          if (gapLength >= 1) {
            acc.list.push({
              id: `${id}gap`,
              length: gapLength
            });
          }

          const length = Math.round(correlationEndDate.diff(correlationStartDate, 'd', true));
          acc.list.push({
            id,
            length,
            events,
            coincStart,
            coincEnd
          });
        }

        if (
          correlationStartDate.isSameOrAfter(previousEndDate) &&
          correlationStartDate.isSameOrAfter(heatmapStartDate) &&
          correlationEndDate.isAfter(heatmapEndDate)
        ) {
          const gapLength = correlationStartDate.diff(previousEndDate, 'd');

          if (gapLength >= 1) {
            acc.list.push({
              id: `${id}gap`,
              length: gapLength
            });
          }

          // some weirdness with moment.diff if 3rd arg (precise diff) is omitted here
          const length = Math.round(heatmapEndDate.diff(correlationStartDate, 'd', true));
          acc.list.push({
            id,
            length,
            events,
            coincStart,
            coincEnd
          });
        }

        if (
          correlationStartDate.isBefore(heatmapStartDate) &&
          correlationEndDate.isAfter(heatmapEndDate)
        ) {
          // some weirdness with moment.diff if 3rd arg (precise diff) is omitted here
          const length = Math.round(heatmapEndDate.diff(heatmapStartDate, 'd', true));
          acc.list.push({
            id,
            length,
            events,
            coincStart,
            coincEnd
          });
        }

        acc.prevDate = correlationEndDate;

        return acc;
      },
      { list: [], prevDate: heatmapDateRange.start }
    );

    return list;
  };

  const generatePills = events => {
    let regularEvents = [];
    let overlapEvents = [];

    if (events.length > 0) {
      ({ nonOverlap: regularEvents, overlap: overlapEvents } = separateOverlappingIntervals(
        events
      ));
      overlapEvents = mergeOverLappingIntervals(overlapEvents);
    }

    regularEvents = buildPills(regularEvents);
    overlapEvents = buildOverlapPills(overlapEvents);

    return {
      regularEvents,
      overlapEvents
    };
  };

  const { data: groupData, loading: groupLoading, error: groupError } = useQuery(
    COINCIDENT_EVENT_GROUPS,
    {
      variables: {
        startDate: heatmapDateRange.start,
        endDate: heatmapDateRange.end
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (groupData?.coincidentEventGroups && !groupLoading) {
      for (let i = 0; i < groupData.coincidentEventGroups.length; i += 1) {
        const group = groupData.coincidentEventGroups[i];
        group.coinc_start_date = group.group_start_date;
        group.coinc_end_date = group.group_end_date;
        group.detectedEvents = [];
      }

      const { regularEvents } = generatePills(groupData.coincidentEventGroups);
      setActivityData(regularEvents);
    }
  }, [groupData, groupLoading]);

  const { data: coincidentEventsData, loading: coincLoading, error: coincError } = useQuery(
    COINCIDENT_EVENTS_HEATMAP,
    {
      variables: {
        startDate: heatmapDateRange.start,
        endDate: heatmapDateRange.end,
        graph: false
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (coincidentEventsData?.coincidentEvents && !coincLoading) {
      // Separate coincidentEvents into 3 groups
      const {
        coincidentEventsA,
        coincidentEventsB,
        coincidentEventsC
      } = coincidentEventsData.coincidentEvents.reduce(
        (accumulator, currentValue) => {
          const startDate = moment.utc(currentValue.coinc_start_date);
          const endDate = moment.utc(currentValue.coinc_end_date);
          const diff = endDate.diff(startDate, 'days');

          if (diff >= 15) {
            accumulator.coincidentEventsA.push(currentValue);
          } else if (diff >= 6 && diff <= 14) {
            accumulator.coincidentEventsB.push(currentValue);
          } else {
            accumulator.coincidentEventsC.push(currentValue);
          }

          return accumulator;
        },
        {
          coincidentEventsA: [],
          coincidentEventsB: [],
          coincidentEventsC: []
        }
      );

      // Run generatePills for each group
      const {
        regularEvents: regularEventsTempA,
        overlapEvents: overlapEventsTempA
      } = generatePills(coincidentEventsA);
      const {
        regularEvents: regularEventsTempB,
        overlapEvents: overlapEventsTempB
      } = generatePills(coincidentEventsB);
      const {
        regularEvents: regularEventsTempC,
        overlapEvents: overlapEventsTempC
      } = generatePills(coincidentEventsC);

      setCorrelationsA({
        regularEvents: regularEventsTempA,
        overlapEvents: overlapEventsTempA
      });
      setCorrelationsB({
        regularEvents: regularEventsTempB,
        overlapEvents: overlapEventsTempB
      });
      setCorrelationsC({
        regularEvents: regularEventsTempC,
        overlapEvents: overlapEventsTempC
      });
    }
  }, [coincidentEventsData, coincLoading]);

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    const dates = [];
    const now = moment(startDate).clone();

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format());
      now.add(1, 'days');
    }

    return dates;
  };

  const generateAnnotations = annotationsForContainer => {
    const heatmapStartDate = moment.utc(heatmapDateRange.start);
    const heatmapEndDate = moment.utc(heatmapDateRange.end);
    const heatmapRange = enumerateDaysBetweenDates(heatmapStartDate, heatmapEndDate);

    const list = annotationsForContainer.reduce(
      (acc, { id, start_date: annoStart, end_date: annoEnd }) => {
        const annoStartDate = moment.utc(annoStart).startOf('d');
        // +1 day append here
        const annoEndDate = moment
          .utc(annoEnd)
          .add(1, 'd')
          .startOf('d');
        const layer = [];

        /*
          Scenario 1: Annotation starts before window and ends inside window

          - - - | 30 Day Window | - - -
          - - | Annotation | - - - - -
        */
        if (
          annoStartDate.isBefore(heatmapStartDate) &&
          annoEndDate.isSameOrBefore(heatmapEndDate)
        ) {
          const length = annoEndDate.diff(heatmapStartDate, 'd');

          layer.push({ length, count: 1, id, annoStart, annoEnd });

          acc.push(layer);
          return acc;
        }

        /*
          Scenario 2: Annotation start/end day is after last event date and between window

          - - - | # # # # # # # # 30 Day Window # # # # # # # # | - - -
          - - - - | Last Event | - - | Annotation | - - - - - - - - - -
        */
        if (
          annoStartDate.isSameOrAfter(heatmapStartDate) &&
          annoEndDate.isSameOrBefore(heatmapEndDate)
        ) {
          const gapLength = annoStartDate.diff(heatmapStartDate, 'd');

          if (gapLength >= 1) {
            layer.push({ length: gapLength, count: 0, id: `${id}gap` });
          }

          const length = Math.round(annoEndDate.diff(annoStartDate, 'd', true));

          layer.push({ length, count: 1, id, annoStart, annoEnd });

          acc.push(layer);
          return acc;
        }

        /*
          Scenario 3: Annotation starts inside window and ends after window

          - - - | 30 Day Window | - - -
          - - - - - - | Annotation | -
        */
        if (
          // annoStartDate.isSameOrAfter(previousEndDate) &&
          annoStartDate.isSameOrAfter(heatmapStartDate) &&
          annoEndDate.isAfter(heatmapEndDate)
        ) {
          const gapLength = annoStartDate.diff(heatmapStartDate, 'd');

          if (gapLength >= 1) {
            layer.push({ length: gapLength, count: 0, id: `${id}gap` });
          }

          const length = Math.round(heatmapEndDate.diff(annoStartDate, 'd', true));

          layer.push({ length, count: 1, id, annoStart, annoEnd });

          acc.push(layer);
          return acc;
        }

        /*
          Scenario 4: Annotation is larger than window

          - - - | 30 Day Window | - - -
          - - | # # Annotation # # | - -
        */
        if (
          annoStartDate.isSameOrBefore(heatmapStartDate) &&
          annoEndDate.isSameOrAfter(heatmapEndDate)
        ) {
          const length = Math.round(heatmapEndDate.diff(heatmapStartDate, 'd', true));

          layer.push({ length, count: 1, id, annoStart, annoEnd });

          acc.push(layer);
          return acc;
        }

        return acc;
      },
      []
    );

    const annotationsByDay = heatmapRange.map(date => [
      // returning the date to create keys
      date,
      annotationsForContainer
        .filter(
          ({ start_date: startDate, end_date: endDate }) =>
            moment.utc(date).isBetween(startDate, endDate) ||
            (moment.utc(date).isSameOrBefore(endDate) && moment.utc(date).isSameOrAfter(startDate))
        )
        .map(({ id }) => id)
    ]);

    setAnnotations(list);
    setAnnotationsByDayLookup(annotationsByDay);
  };

  const createAnnotationsLookup = list => {
    const lookup = list.reduce((acc, curr) => {
      acc[curr.id] = curr;

      return acc;
    }, {});

    setAnnotationsLookup(lookup);
  };

  const { data: annotationsData, loading: annotationsLoading, error: annotationsError } = useQuery(
    GET_ANNOTATIONS_FOR_CONTAINER,
    {
      variables: {
        startDate: heatmapDateRange.start,
        endDate: heatmapDateRange.end
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (annotationsData?.annotationsForContainer && !annotationsLoading) {
      generateAnnotations(annotationsData.annotationsForContainer);
      createAnnotationsLookup(annotationsData.annotationsForContainer);
    }
  }, [annotationsData]);

  const ActivityFlyover = ({
    position,
    timeseries,
    seasonality,
    activityStartDate,
    activityEndDate
  }) => {
    return (
      <div
        style={{
          position: 'absolute',
          left: `${position.x}px`,
          top: `${position.y}px`
        }}
      >
        <div
          className={classes.flyover}
          style={{
            ...(position.offset + flyoverMaxWidth > window.innerWidth
              ? { right: '0px' }
              : { left: '0px' })
          }}
          role="button"
          aria-hidden="true"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {timeseries?.length && (
            <>
              <div className={classes.flyoverTitle}>
                {seasonality == null &&
                  'Period of Heightened Activity - No Annual Seasonality Detected'}
                {seasonality != null &&
                  'Period of Heightened Activity - Annual Seasonality Detected'}
              </div>
              <div style={{ paddingLeft: '30px', position: 'relative' }}>
                {/* Left column for vertical text */}
                <div
                  style={{
                    transform: 'rotate(-90deg)',
                    position: 'absolute',
                    top: '65px',
                    left: '-30px',
                    fontWeight: '600',
                    fontSize: '10px',
                    color: '#7A7A7A'
                  }}
                >
                  # of Correlations
                </div>
                <div>
                  <ActivityGraph
                    data={timeseries}
                    seasonality={seasonality}
                    activityStartDate={activityStartDate}
                    activityEndDate={activityEndDate}
                  />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* Row with last year and current year */}
                    {/* 2022                         | 2023 */}
                    <div style={{ color: '#0A1734', fontSize: '10px', fontWeight: '500' }}>
                      {moment.utc(timeseries[0].x).format('Y')}
                    </div>
                    <div style={{ color: '#0A1734', fontSize: '10px', fontWeight: '500' }}>
                      <div
                        style={{
                          position: 'absolute',
                          width: '1px',
                          height: '230px',
                          background: '#979797',
                          top: '0',
                          right: '30px'
                        }}
                      />
                      {moment.utc(timeseries[timeseries.length - 1].x).format('Y')}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {!timeseries?.length && (
            <div className={classes.flyoverTitle} style={{ marginBottom: '0px' }}>
              <Typography>There is no data for this graph.</Typography>
            </div>
          )}
        </div>
      </div>
    );
  };

  ActivityFlyover.propTypes = {
    position: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      offset: PropTypes.number.isRequired
    }).isRequired,
    timeseries: PropTypes.arrayOf(PropTypes.shape).isRequired,
    seasonality: PropTypes.shape(),
    activityStartDate: PropTypes.string.isRequired,
    activityEndDate: PropTypes.string.isRequired
  };

  ActivityFlyover.defaultProps = {
    seasonality: null
  };

  const EventRow = ({ detectedEvent }) => {
    const [eventProps, setEventProps] = useState(null);

    useEffect(() => {
      let isMounted = true;

      const loadEventProps = async () => {
        const props = await getDetectedEventProps(detectedEvent);
        if (isMounted) setEventProps(props);
      };

      loadEventProps();

      return () => {
        isMounted = false;
      };
    }, [detectedEvent]);

    if (!eventProps) return null;

    const { id, event, metric, aboveMedian, belowMedian, valueChange, source } = eventProps;
    const { eventInfo } = detectedEvent;

    let growth = null;

    if (Object.prototype.hasOwnProperty.call(eventProps, 'aboveMedian')) {
      growth = aboveMedian;
    }

    if (Object.prototype.hasOwnProperty.call(eventProps, 'belowMedian')) {
      growth = belowMedian;
    }

    const eventLength = `${moment(event.endDate).diff(event.startDate, 'd')}D`;
    const magnitude = metric;
    const changeColor = `${growth > 0 ? '#05A74F' : ''}${growth < 0 ? '#E81828' : ''}${
      !growth ? '#000000' : ''
    }`;

    let change = '-';

    if (Number.isFinite(growth)) {
      change = formatValueWithSymbol(growth, {
        usePositive: true,
        usePostfixSymbol: true
      });
    } else {
      change = <>&infin;%</>;
    }

    const eventValue = valueChange;

    const values = [
      { key: 'days', color: '#6F6F6F', value: eventLength },
      { key: 'mag', color: '#33337E', value: magnitude },
      { key: 'change', color: changeColor, value: change },
      { key: 'val', color: '#33337E', value: eventValue }
    ];

    let text =
      eventInfo?.postBody?.length > 30
        ? eventInfo?.postBody?.slice(0, 27)?.concat('...')
        : eventInfo?.postBody;

    let name = eventInfo?.sourceName || '';

    if (source === SIMPLE_SOCIAL_TYPE_ENUM.SHORTLINK) {
      const temp = name;
      name = text;
      text = temp;
    }

    return (
      <TableRow key={id} className={classes.flyoverRow}>
        {/* Metrics */}
        {values.map(({ key, color, value }) => (
          <TableCell key={key} align={value !== '-' ? 'left' : 'center'}>
            <span style={{ color }}>{value}</span>
          </TableCell>
        ))}
        {/* Linktoken */}
        <TableCell>
          <div className={classes.cellContent}>
            {source && <SocialLogo type={source} width={20} height={20} />}
            <span>{name}</span>
          </div>
        </TableCell>
        {/* Event content */}
        <TableCell>
          <div className={classes.cellContent}>
            <span>{text || ''}</span>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  EventRow.propTypes = {
    detectedEvent: PropTypes.shape({
      id: PropTypes.string.isRequired,
      eventInfo: PropTypes.shape({
        postBody: PropTypes.string,
        sourceName: PropTypes.string
      })
    }).isRequired
  };

  const PillFlyover = ({ id: correlationId, list, dates, position }) => (
    <div
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`
      }}
    >
      <div
        className={classes.flyover}
        style={{
          ...(position.offset + flyoverMaxWidth > window.innerWidth
            ? { right: '0px' }
            : { left: '0px' })
        }}
        role="button"
        aria-hidden="true"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className={classes.flyoverTitle}>
          {`${list.length} Event${list.length > 1 ? 's' : ''} - ${dates
            .map(date => moment.utc(date).format('MM/DD/YYYY'))
            .join(' - ')}`}
        </div>
        <Table className={classes.flyoverBody}>
          <TableBody>
            <TableRow className={classes.flyoverHeader}>
              {flyoverColumns.map(column => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
            {list.slice(0, 4).map(event => (
              <EventRow key={event.id} detectedEvent={event} />
            ))}
          </TableBody>
        </Table>
        <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
          <Button
            className={classes.detailsButton}
            size="small"
            variant="outlined"
            onClick={() => {
              const { start: graphStartDate } = getDatesForAnalytics(
                selectedDates.start,
                selectedDates.end
              );

              openDashboardDrawer(
                {
                  id: correlationId,
                  detectedEvents: list,
                  type: 'CORRELATION',
                  coinc_start_date: dates[0],
                  coinc_end_date: dates[1],
                  date: moment.utc(graphStartDate)
                },
                drawerContext
              );
            }}
          >
            See Full Details
          </Button>
        </Grid>
      </div>
    </div>
  );

  PillFlyover.propTypes = {
    id: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    dates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    position: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      offset: PropTypes.number.isRequired
    }).isRequired
  };

  const OverlapPillFlyover = ({ events, position, timestamp }) => {
    const filteredEvents = [];

    const dateToCheck = moment.utc(timestamp);

    for (let i = 0; i < events.length; i += 1) {
      const coincStart = moment.utc(events[i].coinc_start_date);
      const coincEnd = moment.utc(events[i].coinc_end_date);

      if (
        dateToCheck.isBetween(coincStart, coincEnd) ||
        (dateToCheck.isSameOrBefore(coincEnd) && dateToCheck.isSameOrAfter(coincStart))
      ) {
        filteredEvents.push(events[i]);
      }
    }

    return (
      <div
        style={{
          position: 'relative',
          left: `${position.x}px`,
          top: `${position.y}px`,
          zIndex: '10'
        }}
      >
        <div
          className={classes.flyover}
          style={{
            ...(position.offset + flyoverMaxWidth > window.innerWidth
              ? { right: '20px' }
              : { left: '0px' })
          }}
        >
          {filteredEvents.length > 1 && <div className={classes.flyoverTitle}>Multiple Events</div>}
          {filteredEvents.map(coincidentEvent => {
            return (
              <Box key={coincidentEvent.id} mb={12}>
                <div className={classes.flyoverTitle}>
                  {`${coincidentEvent.detectedEvents.length} Event${
                    coincidentEvent.detectedEvents.length > 1 ? 's' : ''
                  } - ${moment
                    .utc(coincidentEvent.coinc_start_date)
                    .format('MM/DD/YYYY')} - ${moment
                    .utc(coincidentEvent.coinc_end_date)
                    .format('MM/DD/YYYY')}`}
                </div>
                <Table className={classes.flyoverBody}>
                  <TableBody>
                    <TableRow className={classes.flyoverHeader}>
                      {flyoverColumns.map(column => (
                        <TableCell key={column}>{column}</TableCell>
                      ))}
                    </TableRow>
                    {coincidentEvent.detectedEvents.slice(0, 4).map(event => (
                      <EventRow key={event.id} detectedEvent={event} />
                    ))}
                  </TableBody>
                </Table>
                <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                  <Button
                    className={classes.detailsButton}
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      const { start: graphStartDate } = getDatesForAnalytics(
                        selectedDates.start,
                        selectedDates.end
                      );

                      openDashboardDrawer(
                        {
                          id: coincidentEvent.id,
                          detectedEvents: coincidentEvent.detectedEvents,
                          type: 'CORRELATION',
                          coinc_start_date: coincidentEvent.coinc_start_date,
                          coinc_end_date: coincidentEvent.coinc_end_date,
                          date: moment.utc(graphStartDate)
                        },
                        drawerContext
                      );
                    }}
                  >
                    See Full Details
                  </Button>
                </Grid>
              </Box>
            );
          })}
        </div>
      </div>
    );
  };

  OverlapPillFlyover.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
    position: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      offset: PropTypes.number.isRequired
    }).isRequired,
    timestamp: PropTypes.string.isRequired
  };

  const Correlations = ({ list, overlapList, backgroundColor }) => {
    const [flyover, setFlyover] = useState({});
    const [overlapFlyover, setOverlapFlyover] = useState({});
    const pillsRef = useRef([]);
    const daysRef = useRef([]);
    const [overlapFlyoverEvents, setOverlapFlyoverEvents] = useState([]);

    useEffect(() => {
      pillsRef.current = pillsRef.current.slice(0, list.length);
    }, [list]);

    useEffect(() => {
      daysRef.current = daysRef.current.slice(0, days.length);
    }, [days]);

    const gridTemplateColumns = `${list
      .map(({ length }) => `${(squareSize + squareHorizontalGap) * length - squareHorizontalGap}px`)
      .join(' ')}`;

    const overlapGridTemplateColumns = `${overlapList
      .map(({ length }) => `${(squareSize + squareHorizontalGap) * length - squareHorizontalGap}px`)
      .join(' ')}`;

    return (
      <div
        style={{
          display: 'grid',
          columnGap: `${squareHorizontalGap}px`,
          gridTemplateColumns
        }}
      >
        {list.map(({ id, count, length, detectedEvents, coincStart, coincEnd, ...rest }, i) => {
          if (count === 0 || length === 0) {
            return <span key={id} />;
          }

          if ('seasonality' in rest) {
            // console.log(rest);
            return (
              <span
                key={id}
                className={classes.pill}
                style={{
                  backgroundColor
                }}
                onMouseEnter={e => {
                  const { left, top } = pillsRef.current[i].getBoundingClientRect();
                  const x = e.clientX - left;
                  const y = e.clientY - top;

                  setFlyover({ id, x, y, offset: e.clientX });
                }}
                onMouseLeave={() => setFlyover({})}
                role="button"
                aria-hidden="true"
                // eslint-disable-next-line no-return-assign
                ref={e => (pillsRef.current[i] = e)}
              >
                <img src={FireEmojiIcon} alt="Fire Emoji" width={20} height={20} />
                {flyover.id === id && (
                  <ActivityFlyover
                    position={flyover}
                    timeseries={rest.timeseries}
                    seasonality={rest.seasonality}
                    activityStartDate={rest.group_start_date}
                    activityEndDate={rest.group_end_date}
                  />
                )}
              </span>
            );
          }

          return (
            <span
              key={id}
              className={classes.pill}
              style={{
                backgroundColor
              }}
              onMouseEnter={e => {
                const { left, top } = pillsRef.current[i].getBoundingClientRect();
                const x = e.clientX - left;
                const y = e.clientY - top;

                setFlyover({ id, x, y, offset: e.clientX });
              }}
              onMouseLeave={() => setFlyover({})}
              onClick={() => {
                const { start: graphStartDate } = getDatesForAnalytics(
                  selectedDates.start,
                  selectedDates.end
                );

                openDashboardDrawer(
                  {
                    id,
                    detectedEvents,
                    type: 'CORRELATION',
                    coinc_start_date: moment.utc(coincStart),
                    coinc_end_date: moment.utc(coincEnd),
                    date: moment.utc(graphStartDate)
                  },
                  drawerContext
                );
              }}
              role="button"
              aria-hidden="true"
              // eslint-disable-next-line no-return-assign
              ref={e => (pillsRef.current[i] = e)}
            >
              {count}
              {length > 1 ? ` Events` : ''}
              {!!detectedEvents.length && flyover.id === id && (
                <PillFlyover
                  id={id}
                  list={detectedEvents}
                  dates={[coincStart, coincEnd]}
                  position={flyover}
                />
              )}
            </span>
          );
        })}
        <div
          style={{
            position: 'absolute',
            display: 'grid',
            columnGap: `${squareHorizontalGap}px`,
            gridTemplateColumns: overlapGridTemplateColumns
          }}
        >
          {overlapList.map(({ id, events }) => {
            if (events == null) {
              return <span key={id} />;
            }

            return <span key={id} className={classes.overlapPill} style={{ backgroundColor }} />;
          })}

          <div className={classes.fauxDays}>
            {days.map(([timestamp], j) => {
              return (
                <span
                  // eslint-disable-next-line no-return-assign
                  ref={e => (daysRef.current[j] = e)}
                  className={classes.fauxDay}
                  key={timestamp}
                  onMouseLeave={() => setOverlapFlyover({})}
                  onMouseEnter={e => {
                    const { left } = daysRef.current[j].getBoundingClientRect();
                    const x = e.clientX - left;
                    const y = 0;

                    const dateToCheck = moment.utc(timestamp);

                    for (let i = 0; i < overlapList.length; i += 1) {
                      const coincStart = moment.utc(overlapList[i].coincStart);
                      const coincEnd = moment.utc(overlapList[i].coincEnd);

                      if (
                        dateToCheck.isBetween(coincStart, coincEnd) ||
                        (dateToCheck.isSameOrBefore(coincEnd) &&
                          dateToCheck.isSameOrAfter(coincStart))
                      ) {
                        setOverlapFlyoverEvents(overlapList[i].events);

                        setOverlapFlyover({
                          id: overlapList[i].id,
                          x,
                          y,
                          offset: e.clientX,
                          timestamp
                        });
                        break;
                      }
                    }
                  }}
                >
                  {overlapFlyover.id && overlapFlyover.timestamp === timestamp && (
                    <OverlapPillFlyover
                      events={overlapFlyoverEvents}
                      position={overlapFlyover}
                      timestamp={overlapFlyover.timestamp}
                    />
                  )}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  Correlations.propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired
      }).isRequired
    ).isRequired,
    overlapList: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
    backgroundColor: PropTypes.string.isRequired
  };

  const Days = ({ list }) => (
    <div className={classes.days}>
      {list.map(([timestamp, day]) => {
        const isMonthStart = day === '1';
        const isSelectedDate = moment.utc(timestamp).isSame(selectedDates.start, 'd');
        return (
          <span
            className={`${classes.day} ${isMonthStart ? classes.monthStart : ''}`}
            key={timestamp}
          >
            <span>{day}</span>
            {isSelectedDate && <div className={classes.selectedDate} />}
            {isMonthStart ? (
              <span style={{ paddingLeft: 10, color: '#0A1734' }}>
                {moment.utc(timestamp).format('MMMM')}
              </span>
            ) : (
              <br />
            )}
          </span>
        );
      })}
    </div>
  );

  Days.propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string.isRequired, PropTypes.string.isRequired).isRequired
    ).isRequired
  };

  const AnnoFlyover = ({ list }) => {
    const { x, y, offset, annotationIds } = list;

    const formatAnnotationDates = annotation => {
      const { start_date, end_date } = annotation;

      const dateArray = [
        ...new Set([
          moment.utc(start_date).format('MM/DD/YYYY'),
          moment.utc(end_date).format('MM/DD/YYYY')
        ])
      ];

      return `${dateArray.join(' - ')}`;
    };

    return (
      <div
        style={{
          position: 'absolute',
          left: `${x}px`,
          top: `${y}px`
        }}
      >
        <div
          className={classes.flyover}
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '600px',
            width: 'max-content',
            overflowY: 'auto',
            paddingBottom: '15px',
            ...(offset + flyoverMaxWidth > window.innerWidth ? { right: '0px' } : { left: '0px' })
          }}
          role="button"
          aria-hidden="true"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <div className={classes.flyoverTitle}>Annotations</div>
          <Grid
            style={{
              display: 'flex'
            }}
          >
            <div className={classes.annotationIcon}>
              <img src={AnnotationBlueIcon} alt="Annotation icon" width={20} height={20} />
            </div>
            <Grid className={classes.annotationsList}>
              {annotationIds.map(id => (
                <Grid key={id} className={classes.annotationRow}>
                  <div className={classes.annotationText}>{annotationsLookup[id].message}</div>
                  <div className={classes.annotationDates}>
                    {formatAnnotationDates(annotationsLookup[id])}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  AnnoFlyover.propTypes = {
    list: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      offset: PropTypes.number.isRequired,
      annotationIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    }).isRequired
  };

  // this element is only for display, the hover and lookups are done by day
  const Annotations = ({ list }) => {
    const gridTemplateColumns = `${list
      .map(({ length }) => `${(squareSize + squareHorizontalGap) * length - squareHorizontalGap}px`)
      .join(' ')}`;

    return (
      <div className={classes.annotations} style={{ pointerEvents: 'none', gridTemplateColumns }}>
        {list.map(({ count, id, length }) => {
          return count && length ? (
            <span key={id} className={classes.annotation} />
          ) : (
            <span key={id} />
          );
        })}
      </div>
    );
  };

  Annotations.propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.number.isRequired
      }).isRequired
    ).isRequired
  };

  const AnnotationsByDay = ({ list }) => {
    const [flyover, setFlyover] = useState({});
    const annoRef = useRef([]);

    useEffect(() => {
      annoRef.current = annoRef.current.slice(0, list.length);
    }, [list]);

    return (
      <div className={classes.annotationDays} style={{ gridAutoFlow: 'column' }}>
        {list.map(([timestamp, annotationIds], i) => {
          return annotationIds.length > 0 ? (
            <span
              key={timestamp}
              className={classes.annotationDay}
              onMouseEnter={e => {
                const { left, top } = annoRef.current[i].getBoundingClientRect();
                const x = e.clientX - left;
                const y = e.clientY - top;

                setFlyover({ timestamp, x, y, offset: e.clientX, annotationIds });
              }}
              onMouseLeave={() => setFlyover({})}
              role="button"
              aria-hidden="true"
              // eslint-disable-next-line no-return-assign
              ref={e => (annoRef.current[i] = e)}
            >
              {annotationIds && flyover.timestamp === timestamp && <AnnoFlyover list={flyover} />}
            </span>
          ) : (
            <span key={timestamp} />
          );
        })}
      </div>
    );
  };

  AnnotationsByDay.propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      )
    ).isRequired
  };

  const GridDisplay = ({ list, classProp, hideTop, hideBottom }) => (
    <div className={classProp}>
      {list.map(([timestamp]) => {
        return (
          <span
            key={timestamp}
            style={{
              // border: '1px solid #ECECEC',
              ...(hideTop === true && { borderTop: 'none' }),
              ...(hideBottom === true && { borderBottom: 'none' })
            }}
          />
        );
      })}
    </div>
  );

  GridDisplay.defaultProps = {
    hideTop: false,
    hideBottom: false
  };

  GridDisplay.propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string.isRequired, PropTypes.string.isRequired).isRequired
    ).isRequired,
    classProp: PropTypes.string.isRequired,
    hideTop: PropTypes.bool,
    hideBottom: PropTypes.bool
  };

  useEffect(() => {
    if (eventsError) handleGraphQLError(eventsError);
    if (coincError) handleGraphQLError(coincError);
    if (annotationsError) handleGraphQLError(annotationsError);
    if (groupError) handleGraphQLError(groupError);
  }, [eventsError, coincError, annotationsError, groupError]);

  return (
    <Grid container className={classes.container}>
      {/* Header Row /w Tab */}
      <Grid container>
        <Tabs value={0} onChange={() => {}}>
          <Tab
            label="Correlations"
            classes={{
              root: classes.tabOverRide
            }}
          />
        </Tabs>
      </Grid>

      {/* Body Row /w Heat Map */}
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.bodyContainer}
        style={{ opacity: eventsLoading ? 0.5 : 1 }}
      >
        {(eventsLoading || coincLoading) && (
          <Grid container className={classes.loadingOverlay}>
            <AlbLoading />
          </Grid>
        )}

        {/* Heat Map */}
        <Box className={classes.graph}>
          {!!days.length && (
            <>
              <div className={classes.correlations0}>
                <span>+ Activity</span>
              </div>

              <div className={classes.correlationsA}>
                <span>15+ Days</span>
              </div>

              <div className={classes.correlationsB}>
                <span>6 - 14 Days</span>
              </div>

              <div className={classes.correlationsC}>
                <span>1 - 5 Days</span>
              </div>

              <div className={classes.pills0}>
                <Correlations list={activityData} overlapList={[]} backgroundColor="#C40075" />
              </div>

              <GridDisplay list={days} classProp={classes.grid0} hideTop hideBottom />

              <div className={classes.pillsA}>
                <Correlations
                  list={correlationsA.regularEvents}
                  overlapList={correlationsA.overlapEvents}
                  backgroundColor="#979797"
                />
              </div>

              <GridDisplay list={days} classProp={classes.gridA} hideBottom />

              <div className={classes.pillsB}>
                <Correlations
                  list={correlationsB.regularEvents}
                  overlapList={correlationsB.overlapEvents}
                  backgroundColor="#32327D"
                />
              </div>

              <GridDisplay list={days} classProp={classes.gridB} hideBottom />

              <div className={classes.pillsC}>
                <Correlations
                  list={correlationsC.regularEvents}
                  overlapList={correlationsC.overlapEvents}
                  backgroundColor="#00A84F"
                />
              </div>

              <GridDisplay list={days} classProp={classes.gridC} hideBottom />

              <Days list={days} />
              {annotations.map(layer => (
                // key is either the id of the leading space or the annotation
                <Annotations key={layer[0].id} list={layer} />
              ))}
              <AnnotationsByDay list={annotationsByDayLookup} />
            </>
          )}
        </Box>

        {/* Legend */}
        {!!days.length && (
          <Grid container justifyContent="center" alignItems="center" style={{ marginTop: '15px' }}>
            <div style={{ marginRight: '10px' }}>
              <div
                style={{ width: '18px', height: '5px', background: '#979797', borderRadius: '5px' }}
              />
              <div
                style={{
                  width: '18px',
                  height: '5px',
                  background: '#32327D',
                  borderRadius: '5px',
                  margin: '3px 0px'
                }}
              />
              <div
                style={{ width: '18px', height: '5px', background: '#00A84F', borderRadius: '5px' }}
              />
            </div>
            <Typography className={classes.legendText} style={{ marginRight: '50px' }}>
              Day with Multiple Correlations
            </Typography>
            <div
              style={{
                width: '18px',
                height: '5px',
                borderRadius: '5px',
                background: '#009FC4',
                marginRight: '10px',
                opacity: '50%'
              }}
            />
            <Typography className={classes.legendText}>Day with Annotation</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CoincidenceEventsHeatmap;

CoincidenceEventsHeatmap.propTypes = {
  isToggleSwitchOn: PropTypes.bool.isRequired,
  selectedDates: PropTypes.shape().isRequired
};
