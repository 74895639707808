/* eslint-disable react/no-children-prop */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Grid, Button, Paper, IconButton } from '@material-ui/core';
import { Close, Print } from '@material-ui/icons';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import EventLink from 'views/DashboardInsights/EventLink';
import MDXContent from 'components/MDXContent';
import { ObserveEventsPathV4 } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import { LogoColored } from 'util/assets';
import theme from 'theme';
import { useReactToPrint } from 'ReactToPrint';

const components = { EventLink };

const setPrintStyling = () => {
  return `@page { 
    margin: 50px 40px 50px 40px !important; 
  }
  .printLogo {
  display: none;

  @media print {
    display: block;
  }
}
  `;
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '10px',
    padding: '48px',
    fontFamily: 'Poppins'
  },
  title: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    marginBottom: '28px'
  },
  summaryTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    direction: 'ltr'
  },
  actions: {
    position: 'absolute',
    top: '-9px',
    right: '0px',
    justifyContent: 'flex-end'
  },
  body: {
    fontSize: '20px',
    overflow: 'hidden'
  },
  actionButton: {
    color: '#000000'
  },
  printButton: {
    color: '#000000'
  },
  footerButton: {
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  }
});

const modalTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      ...theme.overrides.MuiDialog
    }
  }
});

const CausalChainJustificationModal = props => {
  const {
    title,
    justification,
    endDate,
    isModalOpen,
    onChange,
    feedbackComponent,
    history
  } = props;

  const classes = useStyles();

  // react-to-print use for printing the modal
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });

  const onClose = e => {
    onChange({ isModalOpen: false });
    e.stopPropagation();
  };

  const showMeTheData = e => {
    const dateQueryString =
      endDate != null ? `?date=${moment.utc(endDate).format('YYYY-MM-DD')}` : '';

    goToRoute(`${ObserveEventsPathV4}${dateQueryString}`, history);
    e.stopPropagation();
  };

  return (
    <MuiThemeProvider theme={modalTheme}>
      <Dialog maxWidth="lg" open={isModalOpen} onClose={onClose}>
        <Paper className={classes.box}>
          <Grid container>
            <Grid item className={classes.title}>
              Justification
              <Grid container className={classes.actions}>
                <Grid item>
                  <IconButton className={classes.printButton} onClick={reactToPrintFn}>
                    <Print />
                  </IconButton>
                </Grid>
                <Grid item>{feedbackComponent}</Grid>
                <Grid item>
                  <IconButton className={classes.actionButton} onClick={onClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.summaryTitle}>{title}</div>

          <Grid className={`${classes.body} ${classes.markdown}`} ref={printRef}>
            <style>{setPrintStyling()}</style>
            <Grid
              className="printLogo"
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <img src={LogoColored} alt="Alembic Logo" height="40px" />
            </Grid>
            <MDXContent components={components} content={justification} />
          </Grid>
          <Button className={classes.footerButton} variant="contained" onClick={showMeTheData}>
            SHOW ME THE DATA
          </Button>
        </Paper>
      </Dialog>
    </MuiThemeProvider>
  );
};

CausalChainJustificationModal.propTypes = {
  title: PropTypes.string,
  justification: PropTypes.string,
  // update if needed
  endDate: PropTypes.instanceOf(moment),
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  feedbackComponent: PropTypes.node.isRequired,
  history: PropTypes.shape().isRequired
};

CausalChainJustificationModal.defaultProps = {
  title: null,
  justification: null,
  endDate: null
};

export default withRouter(CausalChainJustificationModal);
