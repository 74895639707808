import React from 'react';
import PropTypes from 'prop-types';
import { goToRoute } from 'util/routesHelpers';
import { FilesPath } from 'util/paths';

const FileRedirect = ({ history }) => {
  React.useEffect(() => {
    goToRoute(FilesPath, history);
  }, []);
  return <></>;
};

FileRedirect.propTypes = {
  history: PropTypes.shape().isRequired
};

export default FileRedirect;
