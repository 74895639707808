import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { useQuery } from '@apollo/client';
import colors, { topPostsGradient } from 'util/colors';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { Palette } from 'components/AlbGraphs/Legend';
import { THIRD_PARTY_NARRATIVES_GRAPH_TOP } from 'gql/analyticsThirdPartyMedia';
import { getDatesForAnalytics } from 'util/date';
import { TV_ICON, RADIO_ICON, PODCAST_ICON } from 'util/assets';
import { thirdPartyTooltip } from '../../AlbGraphs/CustomTooltipTemplates';
import { excludedRatingsSourceTypes } from '../util';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%'
  },
  point: {
    position: 'absolute',
    height: '5px',
    width: '5px',
    backgroundColor: '#0A1734',
    borderRadius: '50%',
    transformStyle: 'preserve-3d'
  },
  hidden: {
    display: 'none !important'
  },
  legendTitle: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#6F6F6F',
    textAlign: 'center'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
});

const TopNarrativesGraph = props => {
  const {
    selectedDates,
    broadcastSearchIds,
    podcastSearchIds,
    isTotaledSwitchChecked,
    isImpactSwitchChecked,
    mediaType,
    isEnabled,
    after,
    sortOrder
  } = props;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [formattedGraphData, setFormattedGraphData] = useState([]);
  const [tooltipReference, setTooltipReference] = useState({});
  const [suggestedMax, setSuggestedMax] = useState(0);
  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);
  const classes = useStyles();

  const { start: startDate, end: endDate } = getDatesForAnalytics(
    selectedDates.start,
    selectedDates.end
  );

  const { data, loading, error } = useQuery(THIRD_PARTY_NARRATIVES_GRAPH_TOP, {
    variables: {
      startDate,
      endDate,
      broadcastSearchIds,
      podcastSearchIds,
      isViewershipSelected: isImpactSwitchChecked,
      mediaType,
      isEnabled,
      after: after * 10,
      count: 10,
      orderBy: sortOrder
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.thirdPartyNarrativesGraphTop?.length) {
      const tempTooltipData = {};
      const tempMaxes = [];

      const filteredNarratives = !isImpactSwitchChecked
        ? data.thirdPartyNarrativesGraphTop
        : data.thirdPartyNarrativesGraphTop.filter(
            ({ mentionGroup }) => !excludedRatingsSourceTypes.includes(mentionGroup.source)
          );

      const tempGraphData = filteredNarratives.map(
        ({ mentionGroup, dataset, lifetimeTotal }, i) => {
          const periodTotal = dataset.reduce((acc, currentValue) => acc + currentValue.y, 0);
          const [color] = topPostsGradient[i];

          tempMaxes.push(Math.max(...dataset.map(({ y }) => y)));

          tempTooltipData[mentionGroup.id] = { periodTotal, lifetimeTotal, color, ...mentionGroup };
          return { id: mentionGroup.id, timeseries: dataset };
        }
      );

      setFormattedGraphData(tempGraphData);
      setTooltipReference(tempTooltipData);
      setSuggestedMax(Math.ceil(Math.max(...tempMaxes) * 1.1));
    } else {
      setFormattedGraphData([]);
      setTooltipReference({});
      setSuggestedMax(0);
    }
  }, [data]);

  const options = {
    // https://www.chartjs.org/docs/2.9.4/configuration/legend.html
    legend: {
      display: false
    },
    // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
    tooltips: {
      enabled: false,
      intersect: false,
      custom: tooltipModel => {
        const { offsetTop, offsetLeft } = canvasRef.current.canvas;
        const { dataPoints } = tooltipModel;
        // if tooltip.opacity is 0 then we need to hide the tooltip
        const tempIsTooltipVisible = tooltipModel.opacity !== 0;
        let tooltipWidth = 345;
        setIsTooltipVisible(tempIsTooltipVisible);

        let mentionGroupId;

        if (tooltipModel.body) {
          const [line] = tooltipModel.body[0].lines;
          const [id] = line.split(':');

          mentionGroupId = id;
        }

        if (tempIsTooltipVisible) {
          dataPoints.forEach(dataPoint => {
            if (tooltipRef.current) {
              const {
                source,
                firstAirDate,
                lastAirDate,
                title,
                promotion_type: classification,
                group_seq: groupSeq,
                periodTotal,
                lifetimeTotal,
                color
              } = tooltipReference[mentionGroupId];

              const innerHTML = thirdPartyTooltip({
                mediaTypes: [source],
                classification,
                firstAirDate: firstAirDate ? moment.utc(firstAirDate).format('l') : '',
                lastAirDate: lastAirDate ? moment.utc(lastAirDate).format('l') : '',
                narrativeTitle: title || (groupSeq != null ? `Narrative ${groupSeq + 1}` : ''),
                dataCount: dataPoint.yLabel,
                periodTotal: periodTotal || 0,
                lifetimeTotal: lifetimeTotal || 0,
                boxColor: color,
                dataType: isImpactSwitchChecked === true ? 'Ratings / Impact' : 'Mentions',
                isTotalGraph: isTotaledSwitchChecked,
                isNarrativesGraph: true
              });

              const style = {
                display: dataPoint.yLabel ? 'unset' : 'none'
              };

              const top = offsetTop + dataPoint.y;
              const left = offsetLeft + dataPoint.x;

              style.direction = 'ltr';
              style.top = `${top - 2.5}px`;
              style.left = `${left - 2.5}px`;

              if (tooltipRef.current.offsetWidth > tooltipWidth) {
                tooltipWidth = tooltipRef.current.offsetWidth;
              }

              if (left + tooltipWidth > canvasRef.current.canvas.getBoundingClientRect().width) {
                style.direction = 'rtl';
              }

              Object.assign(tooltipRef.current.style, style);
              Object.assign(tooltipRef.current, { innerHTML });
            }
          });
        }
      }
    },
    // https://www.chartjs.org/docs/2.9.4/general/interactions/
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    animation: {
      duration: 0 // general animation time
    },
    // https://www.chartjs.org/docs/2.9.4/general/performance.html#disable-animations
    responsiveAnimationDuration: 0, // animation duration after a resize
    scales: {
      xAxes: [
        {
          stacked: true,
          // https://www.chartjs.org/docs/2.9.4/axes/styling.html#grid-line-configuration
          gridLines: {
            display: false
          },
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/#tick-configuration
          // https://www.chartjs.org/docs/2.9.4/axes/cartesian/linear.html
          ticks: {
            maxTicksLimit: 5,
            maxRotation: 0,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 14
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: colors.gray,
            drawBorder: false
          },
          ticks: {
            callback: value => value.toLocaleString(),
            precision: 2,
            beginAtZero: true,
            fontColor: colors.darkGray,
            fontFamily: 'Poppins',
            fontSize: 14,
            suggestedMax,
            suggestedMin: 0
          }
        }
      ]
    }
  };

  const graphData = canvas => {
    canvasRef.current = canvas.getContext('2d');

    let labels = [];

    const datasets = formattedGraphData.map((row, index) => {
      if (!index) {
        labels = row.timeseries.map(dataPoint =>
          moment
            .utc(dataPoint.x)
            .format('MMM D')
            .toUpperCase()
        );
      }

      const canvasHeight = canvasRef.current.canvas.offsetHeight;

      const gradientFill = canvasRef.current.createLinearGradient(0, 0, 0, canvasHeight);
      gradientFill.addColorStop(0, topPostsGradient[index][0]);
      gradientFill.addColorStop(1, topPostsGradient[index][1]);

      const dataPoints = row.timeseries.map(dataPoint => dataPoint.y);

      return {
        data: dataPoints,
        backgroundColor: gradientFill,
        borderColor: 'transparent',
        fill: true,
        label: row.id,
        lineTension: 0.4,
        pointRadius: 0,
        type: dataPoints.length === 1 ? 'bar' : 'line'
      };
    });

    return { labels, datasets };
  };

  return (
    <div className={classes.container}>
      {loading && (
        <Box className={classes.loadingOverlay}>
          <AlbLoading />
        </Box>
      )}
      {error && <AlbError error={error} />}
      <Bar data={graphData} options={options} />
      <Box
        ref={tooltipRef}
        className={`${classes.point} ${isTooltipVisible ? '' : classes.hidden}`}
      />
      <Grid container justifyContent="center" direction="column">
        {isTotaledSwitchChecked === false && (
          <Typography className={classes.legendTitle}>
            Top Narratives by {isImpactSwitchChecked === true ? 'Ratings / Impact' : 'Mentions'}
          </Typography>
        )}
        <Palette
          legend={{
            palette: formattedGraphData
              .filter(
                ({ id }) => tooltipReference[id].title || tooltipReference[id].group_seq != null
              )
              .map(({ id }) => {
                const { title, group_seq: groupSeq, source, color } = tooltipReference[id];
                const label = title || `Narrative ${groupSeq + 1}`;
                let icon;

                switch (source) {
                  case 'TV': {
                    icon = TV_ICON;
                    break;
                  }
                  case 'RADIO': {
                    icon = RADIO_ICON;
                    break;
                  }
                  case 'PODCAST_TRANSCRIPT':
                  case 'PODCAST_SUMMARY': {
                    icon = PODCAST_ICON;
                    break;
                  }
                  default:
                    icon = '';
                }

                return {
                  icon,
                  label,
                  key: label + id,
                  color
                };
              })
          }}
        />
      </Grid>
    </div>
  );
};

TopNarrativesGraph.propTypes = {
  isImpactSwitchChecked: PropTypes.bool.isRequired,
  isTotaledSwitchChecked: PropTypes.bool.isRequired,
  selectedDates: PropTypes.shape().isRequired,
  broadcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  podcastSearchIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEnabled: PropTypes.bool.isRequired,
  mediaType: PropTypes.string,
  after: PropTypes.number.isRequired,
  sortOrder: PropTypes.arrayOf(PropTypes.string).isRequired
};

TopNarrativesGraph.defaultProps = {
  mediaType: null
};

export default TopNarrativesGraph;
