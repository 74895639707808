import { getPlatformName, platforms } from 'util/social';
import { renderUILongDate } from 'util/date';
import getValueAbbreviation from 'util/getValueAbbreviation';
import { TV_ICON, RADIO_ICON, PODCAST_ICON, ARTICLE_ICON } from 'util/assets';

/**
 * Generates an HTML String to be injected into an AlbGraphDruid legend tooltip label.
 * @param {Object[]} dataArr - an array of objects in the following format: { date, ageRange, yValue, labelColor }
 * @return {String} The whole HTML String of the label, including styles and injected variables.
 */
export const formatFlowLabel = (dataArr, title, missingData) => {
  const { date } = dataArr[0];

  const tableStyle =
    'width: 165px; background: #FFFFFF; color: #0A1734; font-size: 10px; font-weight: 500; z-index: 100';
  const tableHeader = `<tr><td style="text-align: left;">${title}</td><td style="text-align: right; white-space: nowrap;">${date}</td></tr>`;
  const divider = "<tr><td style='border-top: 1px solid #D8D8D8;' colspan='2'></tr>";
  const missingDataText =
    '<tr><td><div style="margin-bottom: 10px;">Warning: Incomplete API data received from social networks on this date. This does NOT affect lifetime values.</div></td></tr>';

  const tableRows = dataArr
    .map(labelInfo => {
      const { ageRange, yValue, labelColor } = labelInfo;

      const legendStyle = `display: inline-block; width: 12px; height: 12px; background: ${labelColor}; border-radius: 3px; margin-right: 5px;`;

      return `<tr><td style="display: flex; align-items: center; white-space: nowrap;"><div style="${legendStyle}"></div>${ageRange}</td><td style="text-align: right">${yValue}</td><tr>`;
    })
    .join('');

  return `<table style="${tableStyle}">${tableHeader}${divider}${
    missingData ? missingDataText : ''
  }${tableRows}</table>`;
};

/**
 * Generates an HTML String to be injected into an AlbGraphDruid sources and pages tooltip label.
 * @return {String} The whole HTML String of the label, including styles and injected variables.
 */
export const sourcesAndPagesTooltip = async (data, value, date, rank) => {
  const { linktoken, label, value: total, unit } = data;

  const tooltipStyle = `font-family: 'Poppins'; font-style: normal; pointer-events: none; width: 361px; background: #FFFFFF; font-size: 10px; font-weight: 500; left: 2.5px; right: 2.5px; top: 2.5px; position: relative; transform: translateZ(-1px); direction: ltr; box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2); border-radius: 3px;`;
  const headerStyle =
    'display: flex; align-items: center; justify-content: space-between; padding: 13px 10px;';
  const headerLabelStyle =
    'display: flex; align-items: center; font-size: 12px; font-weight: 600; color: #32327D; text-align: left; overflow-wrap: break-word; white-space: initial; word-break: break-word; overflow: hidden;';
  const accountLabelStyle = `text-align: right; font-weight: 500; color: #0A1734;`;
  const socialIconStyle = 'height: 27px; width: 27px; margin-right: 10px;';
  const hrStyle = 'background-color: #D8D8D8; height: 1px; border: 0; margin: 0;';
  const dateStyle =
    'font-size: 15px; font-weight: 600; color: #6F6F6F; text-align: left; margin-bottom: 5px';
  const metricValueStyle = 'font-size: 20px; font-weight: 600; color: #32327D; text-align: left;';
  const rankStyle = 'font-size: 15px; font-weight: 600;';

  const platformName = await getPlatformName(linktoken);
  const platformIcon = platforms.find(({ name }) => name === platformName)?.icon;

  const socialIcon = platformIcon
    ? `<img alt="logo" style="${socialIconStyle}" src="${platformIcon}" />`
    : '';
  const headerLabel = `<div style="${headerLabelStyle}">${socialIcon}${label}</div>`;
  const accountLabel = `<div style="${accountLabelStyle}">${
    label === 'Other'
      ? ''
      : linktoken.fullRemoteName
          .split(' > ')
          .map(item => `<div>${item}</div>`)
          .join('')
  }</div>`;
  const header = `<div style="${headerStyle}">${headerLabel}${accountLabel}</div>`;
  const dateLabel = `<div style="${dateStyle}">${renderUILongDate(date)}</div>`;
  // if we need to change the 'metric' we can make Visits here conditional
  const metricValue = `<div style="display: flex; align-items: center; color: #0A1734;"><span style="${metricValueStyle}">${
    unit === 'Revenue' ? '$' : ''
  }${value}&nbsp;</span>${unit}</div>`;
  const rankLabel = `<div style="${rankStyle}">${label === 'Other' ? '' : rank}</div>`;
  const inTimePeriod = `<div>In Time Period - ${
    unit === 'Revenue' ? '$' : ''
  }${total.toLocaleString()}</div>`;

  const body = `<div style="display: flex; align-items: center; padding: 3px 10px 5px 10px;"><div>${dateLabel}${metricValue}</div><div style="flex: 1; text-align: center; color: #0A1734;">${rankLabel}${inTimePeriod}</div></div>`;
  return `<div style="${tooltipStyle}">${header}<hr style="${hrStyle}">${body}</div>`;
};

/**
 * Generates an HTML String to be injected into an AlbGraphDruid sources and pages tooltip label.
 * @return {String} The whole HTML String of the label, including styles and injected variables.
 */
export const thirdPartyTooltip = tooltipOptions => {
  const {
    mediaTypes,
    classification,
    firstAirDate,
    lastAirDate,
    narrativeTitle,
    dataCount,
    periodTotal,
    lifetimeTotal,
    boxColor,
    dataType,
    isTotalGraph,
    isNarrativesGraph
  } = tooltipOptions;

  const tooltipStyle = `font-family: 'Poppins';font-style: normal; pointer-events: none; width: ${
    isNarrativesGraph ? 345 : 245
  }px; background-color: #FFFFFF; left: 2.5px; right: 2.5px; top: 2.5px; position: relative; transform: translateZ(-1px); direction: ltr; box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2); border-radius: 3px;`;
  const hrStyle = 'background-color: #D8D8D8; height: 1px; border: 0; margin: 0;';

  const tvIcon = `<img style="width: 17px; height: 13px; margin-right: 10px;" src="${TV_ICON}"/>`;
  const radioIcon = `<img style="width: 17px; height: 13px; margin-right: 10px;" src="${RADIO_ICON}"/>`;
  const podcastIcon = `<img style="width: 10px; height: 14px; margin-right: 10px;" src="${PODCAST_ICON}"/>`;
  const webIcon = `<img style="width: 10px; height: 14px; margin-right: 10px;" src="${ARTICLE_ICON}"/>`;

  const classificationType = {
    campaign: 'Campaign',
    syndication: 'Syndication',
    partner: 'Partner',
    none: ' '
  }[classification];

  const mediaTypeList = [];

  const icons = mediaTypes.map(mediaType => {
    switch (mediaType) {
      case 'TV': {
        mediaTypeList.push('TV');
        return tvIcon;
      }
      case 'RADIO': {
        mediaTypeList.push('Radio');
        return radioIcon;
      }
      case 'PODCAST_TRANSCRIPT':
      case 'PODCAST_SUMMARY': {
        mediaTypeList.push('Podcast');
        return podcastIcon;
      }
      case 'WEB':
        mediaTypeList.push('Web');
        return webIcon;
      default:
        return '';
    }
  });

  const mediaTypeStyle = `display: flex; align-items: center; font-weight: 600; font-size: 12px; line-height: 18px; text-align: right; color: #32327D;`;
  const classificationStyle = `font-weight: 500; font-size: 10px; line-height: 15px; text-align: right; color: #0A1734;`;
  const mediaTypeMarkup = `<div style="${mediaTypeStyle}">${[...new Set(icons)].join('')}${[
    ...new Set(mediaTypeList)
  ].join(', ')}</div>`;
  const classificationMarkup = `<div style="${classificationStyle}">${classificationType ||
    'Not Set'}</div>`;
  const header = `<div style="display: flex; justify-content: space-between; align-items: center; padding: 13px 10px">${mediaTypeMarkup}${classificationMarkup}</div>`;

  const dateRangeStyle = `font-weight: 600; font-size: 15px; line-height: 22px; color: #6F6F6F;`;
  const narrativeTitleStyle = `display: flex; align-items: center; font-weight: 600; font-size: 15px; line-height: 22px; color: #0A1734;`;
  const colorBoxStyle = `width: 18px; height: 18px; margin-right: 10px; background-color: ${boxColor};`;
  let dateRangeMarkup = '';

  if (firstAirDate && !lastAirDate) {
    dateRangeMarkup = `<div style="${dateRangeStyle}">${firstAirDate}</div>`;
  } else if (firstAirDate && lastAirDate) {
    dateRangeMarkup = `<div style="${dateRangeStyle}">${firstAirDate} - ${lastAirDate}</div>`;
  }

  const colorBoxMarkup = `<div style="${colorBoxStyle}"></div>`;
  const narrativeTitleMarkup = `<div style="${narrativeTitleStyle}">${colorBoxMarkup}${narrativeTitle}</div>`;
  const bodyRow1 = `<div style="display: flex; justify-content: space-between;">${dateRangeMarkup}${
    isTotalGraph === true || !narrativeTitle.length ? '' : narrativeTitleMarkup
  }</div>`;

  const dataNumberMarkup = `<div style="font-weight: 600; font-size: 20px; line-height: 30px; color: #32327D; margin-right: 10px;">${dataCount.toLocaleString()}</div>`;
  const dataCountMarkup = `<div style="display: flex; align-items: center;">${dataNumberMarkup} ${dataType}</div>`;

  let totalColumnMarkup = '';

  if (isNarrativesGraph) {
    const periodTotalMarkup = `<div>Total In Time Period - ${periodTotal.toLocaleString()}</div>`;
    const lifetimeTotalMarkup = `<div>Lifetime Value - ${lifetimeTotal.toLocaleString()}</div>`;
    totalColumnMarkup = `<div style="display: flex; flex-direction: column; text-align: right;">${periodTotalMarkup}${lifetimeTotalMarkup}</div>`;
  }

  const bodyRow2Style = `display: flex; justify-content: space-between; font-weight: 500; font-size: 10px; line-height: 15px; color: #0A1734;`;
  const bodyRow2 = `<div style="${bodyRow2Style}">${dataCountMarkup}${totalColumnMarkup}</div>`;

  const body = `<div style="display: flex; flex-direction: column; padding: 3px 10px 5px 10px;">${bodyRow1}${bodyRow2}</div>`;

  return `<div style="${tooltipStyle}">${header}<hr style="${hrStyle}">${body}</div>`;
};

export const simpleStackedTooltip = (title, data) => {
  const tooltipStyle = `position: absolute; width: max-content; max-width: 300px; background-color: rgba(0, 0, 0, 0.8); border-radius: 3px; padding: 2px 5px; direction: ltr`;

  const rows = data
    .map(({ value, color }) => {
      const legendStyle = `display: inline-block; width: 12px; height: 12px; background: ${color}; border: solid 1px white; margin-right: 5px;`;
      const labelStyle = `overflow-wrap: break-word; word-wrap: break-word; word-break: break-word;`;
      return `<div><span style="${legendStyle}"></span><span style="${labelStyle}">${value}</span></div>`;
    })
    .join('');
  return `<div style="${tooltipStyle}"><div><div style="font-weight: 600;">${title}</div>${rows}</div></div>`;
};

export const socialImpactTooltip = tooltipOptions => {
  const { type, date, metric, value } = tooltipOptions;
  let boxColor;
  let typeTitle;

  if (type === 'published') {
    boxColor = '#C40075';
    typeTitle = 'Published';
  }

  if (type === 'longTail') {
    boxColor = '#32327D';
    typeTitle = 'Long Tail';
  }

  const colorBoxStyle = `width: 18px; height: 18px; margin-right: 10px; background-color: ${boxColor};`;
  const colorBoxMarkup = `<div style="${colorBoxStyle}"></div>`;

  const typeTitleStyle = `display: flex; align-items: center; font-weight: 600; font-size: 15px; line-height: 22px; color: #0A1734;`;
  const typeTitleMarkup = `<div style="${typeTitleStyle}">${colorBoxMarkup}${typeTitle}</div>`;

  const dateTitleStyle = `font-weight: 600; font-size: 15px; line-height: 22px; color: #6F6F6F;`;
  const dateTitleMarkup = `<div style="${dateTitleStyle}">${date}</div>`;

  const header = `<div style="display: flex; justify-content: space-between; align-items: center; padding: 13px 10px">${typeTitleMarkup}${dateTitleMarkup}</div>`;

  const valueMarkup = `<div style="font-weight: 600; font-size: 18px; line-height: 27px; color: #0A1734;">${value.toLocaleString()} ${metric}</div>`;
  const body = `<div style="display: flex; flex-direction: column; padding: 3px 10px 5px 10px;">${valueMarkup}</div>`;

  const tooltipStyle = `font-family: 'Poppins';font-style: normal; pointer-events: none; width: 345px; background-color: #FFFFFF; left: 2.5px; right: 2.5px; top: 2.5px; position: relative; transform: translateZ(-1px); direction: ltr; box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2); border-radius: 3px;`;
  const hrStyle = 'background-color: #D8D8D8; height: 1px; border: 0; margin: 0;';
  return `<div style="${tooltipStyle}">${header}<hr style="${hrStyle}">${body}</div>`;
};

/**
 * Generates an HTML String to be injected into an app stores tooltip label.
 * @return {String} The whole HTML String of the label, including styles and injected variables.
 */
export const appStoresTooltip = tooltipOptions => {
  const { title, date, value, total, lifetimeTotal, unit, boxColor } = tooltipOptions;

  const tooltipStyle = `font-family: 'Poppins';font-style: normal; pointer-events: none; width: 295px; background-color: #FFFFFF; left: 2.5px; right: 2.5px; top: 2.5px; position: relative; transform: translateZ(-1px); direction: ltr; box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2); z-index: 1;`;
  const hrStyle = 'background-color: #D8D8D8; height: 1px; border: 0; margin: 0;';

  const colorBoxStyle = `min-width: 18px; height: 18px; margin-right: 10px; background-color: ${boxColor}; border-radius: 3px;`;
  const colorBoxMarkup = `<div style="${colorBoxStyle}"></div>`;

  const titleStyle = `display: flex; align-items: center; font-weight: 600; font-size: 12px; line-height: 18px; text-align: left; color: #32327D;`;
  const titleMarkup = `<div style="${titleStyle}">${title}</div>`;

  const header = `<div style="display: flex; align-items: center; padding: 13px 10px">${colorBoxMarkup}${titleMarkup}</div>`;

  const dateStyle = `font-weight: 600; font-size: 15px; line-height: 22px; color: #6F6F6F;`;
  const dateMarkup = `<div style="${dateStyle}">${date}</div>`;

  const bodyRow1 = `<div>${dateMarkup}</div>`;

  const numberMarkup = `<div style="font-weight: 600; font-size: 20px; line-height: 30px; color: #32327D; margin-right: 10px;">${
    unit === 'Revenue' ? getValueAbbreviation(value, true, false) : value.toLocaleString()
  }</div>`;

  // remove 's' if singular
  const formattedUnit = value === 1 && unit.slice(-1) === 's' ? unit.slice(0, -1) : unit;
  const countMarkup = `<div style="display: flex; align-items: center;">${numberMarkup} ${formattedUnit}</div>`;

  const periodTotalMarkup = `<div>In Time Period - ${
    unit === 'Revenue' ? getValueAbbreviation(total, true, false) : total.toLocaleString()
  }</div>`;
  const lifetimeTotalMarkup =
    lifetimeTotal != null
      ? `<div>Lifetime Value - ${
          unit === 'Revenue'
            ? getValueAbbreviation(lifetimeTotal, true, false)
            : lifetimeTotal.toLocaleString()
        }</div>`
      : '';
  const totalColumnMarkup = `<div style="display: flex; flex-direction: column; text-align: right;">${periodTotalMarkup}${lifetimeTotalMarkup}</div>`;

  const bodyRow2Style = `display: flex; align-items: center; justify-content: space-between; font-weight: 500; font-size: 10px; color: #0A1734;`;
  const bodyRow2 = `<div style="${bodyRow2Style}">${countMarkup}${totalColumnMarkup}</div>`;

  const body = `<div style="display: flex; flex-direction: column; padding: 3px 10px 5px 10px;">${bodyRow1}${bodyRow2}</div>`;

  return `<div style="${tooltipStyle}">${header}<hr style="${hrStyle}">${body}</div>`;
};

/**
 * Generates an HTML String to be injected into a time to event graph.
 * @return {String} The whole HTML String of the label, including styles and injected variables.
 */
export const timeToEventTooltip = tooltipOptions => {
  const { title, value, boxColor } = tooltipOptions;

  const tooltipStyle = `font-family: 'Poppins';font-style: normal; pointer-events: none; width: fit-content; background-color: #FFFFFF; border-radius: 3px; left: 2.5px; right: 2.5px; top: 2.5px; position: relative; transform: translateZ(-1px); direction: ltr; box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2); z-index: 1;`;

  const colorBoxStyle = `min-width: 18px; height: 18px; margin-right: 10px; background-color: ${boxColor}; border-radius: 3px;`;
  const colorBoxMarkup = `<div style="${colorBoxStyle}"></div>`;

  const titleStyle = `display: flex; align-items: center; font-weight: 400; font-size: 12px; line-height: 18px; text-align: left; white-space: nowrap;`;
  const titleMarkup = `<div style="${titleStyle}">${title}: ${value}</div>`;

  const body = `<div style="display: flex; align-items: center; padding: 10px 10px">${colorBoxMarkup}${titleMarkup}</div>`;

  return `<div style="${tooltipStyle}">${body}</div>`;
};

/**
 * Generates an HTML String to be injected into a history forecast graph.
 * @return {String} The whole HTML String of the label, including styles and injected variables.
 */
export const historyForecastTooltip = tooltipOptions => {
  const { title, value, boxColor } = tooltipOptions;

  const tooltipStyle = `font-family: 'Poppins';font-style: normal; pointer-events: none; width: fit-content; background-color: #FFFFFF; left: 2.5px; right: 2.5px; top: 2.5px; position: relative; transform: translateZ(-1px); direction: ltr; box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2); z-index: 1;`;

  const colorBoxStyle = `min-width: 18px; height: 18px; margin-right: 30px; background-color: ${boxColor}; border-radius: 3px;`;
  const colorBoxMarkup = `<div style="${colorBoxStyle}"></div>`;

  const textStyle = `font-weight: 400; font-size: 12px; line-height: 18px; white-space: nowrap;`;
  const labelMarkup = `<div style="${textStyle}">${title}</div>`;
  const valueMarkup = `<div style="${textStyle}">${value}</div>`;

  const titleStyle = `display: flex; align-items: flex-end; flex-direction: column; text-align: right; `;
  const titleMarkup = `<div style="${titleStyle}">${labelMarkup}${valueMarkup}</div>`;

  const body = `<div style="display: flex; align-items: center; padding: 10px 10px">${colorBoxMarkup}${titleMarkup}</div>`;

  return `<div style="${tooltipStyle}">${body}</div>`;
};
