import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER } from 'gql/linktoken';
import PostAccountSelector from 'components/PostAccountSelector';
import { Grid, FormControl, Select, MenuItem } from '@material-ui/core';
import { mapOptions } from 'util/social';

const mediaSortConditions = [
  {
    value: 'newest-date',
    name: 'Date (Newest)',
    dbOrderBy: ['created_at', 'DESC']
  },
  {
    value: 'oldest-date',
    name: 'Date (Oldest)',
    dbOrderBy: ['created_at', 'ASC']
  },
  {
    value: 'top-impressions',
    name: 'Impressions (Top)',
    dbOrderBy: ['lifetime_impressions', 'DESC NULLS LAST']
  },
  {
    value: 'bottom-impressions',
    name: 'Impressions (Low)',
    dbOrderBy: ['lifetime_impressions', 'ASC NULLS LAST']
  }
];

const MediaFilters = ({ updateFilterValues }) => {
  const [sortOrder, setSortOrder] = useState(mediaSortConditions[0].value);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const { data } = useQuery(LINKED_ACCOUNTS_FOR_CURRENT_CONTAINER, {
    variables: { showOnlyEnabled: true },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.linkedAccounts) {
      const loadAccounts = async () => {
        const formattedAccounts = await mapOptions(data.linkedAccounts, true);
        setLinkedAccounts(formattedAccounts);
      };

      loadAccounts();
    }
  }, [data]);

  useEffect(() => {
    const dbSortString = mediaSortConditions.find(condition => condition.value === sortOrder)
      ?.dbOrderBy;

    updateFilterValues(dbSortString, selectedAccounts);
  }, [sortOrder, selectedAccounts]);

  return (
    <Grid container style={{ width: 'auto' }} alignItems="center" justifyContent="space-between">
      <FormControl style={{ width: '250px' }} variant="outlined">
        <Select value={sortOrder} onChange={e => setSortOrder(e.target.value)}>
          {mediaSortConditions.map(condition => (
            <MenuItem value={condition.value} key={condition.value}>
              {condition.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid item style={{ width: '300px' }}>
        <PostAccountSelector
          singleAccountData={linkedAccounts}
          singleSelectedAccounts={selectedAccounts}
          handleSelectAccount={setSelectedAccounts}
          useIconDisplay
          useDefault
          placeholder="Select Social Accounts"
        />
      </Grid>
    </Grid>
  );
};

MediaFilters.propTypes = {
  updateFilterValues: PropTypes.func.isRequired
};

export default MediaFilters;
