import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import { CloudUpload, Close } from '@material-ui/icons';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { S3_GENERATE_URL } from 'gql/s3';
import { showToast } from 'contexts/ToastContext';

const useStyles = makeStyles(styleTheme => ({
  dropzone: {
    border: '1px dashed #bdbdbd',
    borderRadius: '4px',
    padding: styleTheme.spacing(4),
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      borderColor: styleTheme.palette.primary.main
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '350px',
    width: '100%'
  },
  activeDropzone: {
    borderColor: styleTheme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  },
  fileList: {
    overflowY: 'auto',
    width: '100%',
    maxHeight: '200px'
  },
  fileItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: styleTheme.spacing(1),
    borderBottom: '1px solid #eee',
    height: '40px',
    boxSizing: 'border-box'
  },
  fileDetails: {
    display: 'flex',
    alignItems: 'center'
  },
  removeButton: {
    padding: 4,
    marginLeft: 8
  },
  hiddenInput: {
    display: 'none'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  fileListContainer: {
    marginTop: styleTheme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  fileListTitle: {
    marginBottom: styleTheme.spacing(1)
  },
  errorMessage: {
    marginTop: styleTheme.spacing(2)
  },
  fileName: {
    maxWidth: '60%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const dialogTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      paper: {
        width: '750px',
        minHeight: '300px',
        maxHeight: '90vh'
      }
    },
    MuiDialogTitle: {
      root: { padding: '20px 30px' }
    },
    MuiDialogContent: {
      root: { padding: '8px 30px' }
    },
    MuiDialogActions: {
      root: { padding: '16px 24px' }
    }
  }
});

const FileUpload = props => {
  const { refetch } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [error, setError] = useState(null);
  const [isDragActive, setIsDragActive] = useState(false);

  const fileInputRef = useRef(null);
  const dropzoneRef = useRef(null);

  const [getPresignedUrl] = useLazyQuery(S3_GENERATE_URL);

  const addFilesToList = newFiles => {
    setSelectedFiles(prevFiles => {
      const existingFilenames = new Map(prevFiles.map(file => [file.name, true]));
      const uniqueNewFiles = newFiles.filter(file => !existingFilenames.has(file.name));

      return [...prevFiles, ...uniqueNewFiles];
    });

    setError(null);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    if (!uploading) {
      setOpen(false);
      setSelectedFiles([]);
      setError(null);
    }
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const newFiles = Array.from(e.dataTransfer.files);
      addFilesToList(newFiles);
    }
  };

  const handleFileInputChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      addFilesToList(newFiles);

      e.target.value = '';
    }
  };

  const handleRemoveFile = filename => {
    if (uploading) return;

    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== filename));
  };

  const handleClickDropzone = () => {
    fileInputRef.current?.click();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleClickDropzone();
    }
  };

  const uploadFile = async file => {
    try {
      setUploadingFiles(prev => ({ ...prev, [file.name]: true }));

      const { data } = await getPresignedUrl({
        variables: {
          fileName: file.name,
          fileType: file.type
        }
      });

      await fetch(data.generatePresignedUrl.url, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file
      });

      return true;
    } catch (err) {
      showToast(`Failed to upload ${file.name}`, 'error');

      return false;
    } finally {
      setUploadingFiles(prev => {
        const updated = { ...prev };
        delete updated[file.name];
        return updated;
      });
    }
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      showToast('Please select files first', 'error');
    }

    try {
      setUploading(true);
      setError(null);

      // Initialize uploading files state
      const initialUploadingState = {};
      selectedFiles.forEach(file => {
        initialUploadingState[file.name] = true;
      });
      setUploadingFiles(initialUploadingState);

      const uploadPromises = selectedFiles.map(file => uploadFile(file));
      const results = await Promise.all(uploadPromises);

      // Check if all uploads were successful
      const allSuccessful = results.every(result => result === true);

      if (allSuccessful) {
        showToast('Files uploaded successfully', 'success');
      } else {
        showToast('Some files failed to upload', 'error');
      }

      setSelectedFiles([]);
      refetch();
      handleClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setUploading(false);
      setUploadingFiles({});
    }
  };

  const formatFileSize = bytes => {
    if (bytes === 0) return '0 B';

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1000));

    return `${(bytes / 1000 ** i).toFixed(2)} ${units[i]}`;
  };

  return (
    <>
      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUpload />}
        onClick={handleOpen}
      >
        Upload Files
      </Button>

      <ThemeProvider theme={dialogTheme}>
        <Dialog open={open} onClose={handleClose} maxWidth={false}>
          <DialogTitle>Upload Files</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div
              ref={dropzoneRef}
              className={`${classes.dropzone} ${isDragActive ? classes.activeDropzone : ''}`}
              onClick={handleClickDropzone}
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              role="button"
              aria-label="Upload files"
            >
              <input
                ref={fileInputRef}
                type="file"
                className={classes.hiddenInput}
                onChange={handleFileInputChange}
                multiple
              />
              {isDragActive ? (
                <Typography>Drop the files here...</Typography>
              ) : (
                <Typography>Drag and drop files here, or click to select files</Typography>
              )}
            </div>

            {selectedFiles.length > 0 && (
              <div className={classes.fileListContainer}>
                <Typography variant="h6" className={classes.fileListTitle}>
                  Selected Files:
                </Typography>
                <div className={classes.fileList}>
                  {selectedFiles.map(file => (
                    <div key={file.name} className={classes.fileItem}>
                      <Typography variant="body2" className={classes.fileName}>
                        {file.name}
                      </Typography>
                      <div className={classes.fileDetails}>
                        <Typography variant="body2">{formatFileSize(file.size)}</Typography>
                        {!uploading && (
                          <IconButton
                            className={classes.removeButton}
                            size="small"
                            onClick={() => handleRemoveFile(file.name)}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        )}
                        {uploadingFiles[file.name] && (
                          <CircularProgress size={16} style={{ marginLeft: 8 }} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {error && (
              <Typography color="error" variant="body2" className={classes.errorMessage}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={uploading}>
              Cancel
            </Button>
            <Button
              onClick={handleUpload}
              color="primary"
              disabled={selectedFiles.length === 0 || uploading}
            >
              {uploading ? (
                <>
                  Uploading...
                  <CircularProgress size={24} style={{ marginLeft: 8 }} />
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

FileUpload.propTypes = {
  refetch: PropTypes.func
};

FileUpload.defaultProps = {
  refetch: () => {}
};

export default FileUpload;
