import { gql } from '@apollo/client';
import apolloClientHelper from 'middleware/api';

export const GET_SIMPLE_SOCIAL_TYPE = gql`
  query simpleSocialType($type: String!, $useSimple: Boolean) {
    simpleSocialType(type: $type, useSimple: $useSimple) {
      type
    }
  }
`;

export const getSimpleSocialTypeQuery = async data =>
  apolloClientHelper(GET_SIMPLE_SOCIAL_TYPE, data);
