import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import Box from 'components/Box';
import HasAnyPriv from 'components/HasAnyPriv';
import AlembicPageHeader from 'components/AlembicPageHeader';
import OAuthButton from 'components/OAuth/OAuthButton';
import OAuthResponder from 'components/OAuth/OAuthResponder';
import LinkedAccountTable from 'components/LinkedAccountTable';
import Banner from 'components/Banner';
import setPageTitle from 'util/titles';
import isFeatureVisible from 'util/isFeatureVisible';
import { goToRoute } from 'util/routesHelpers';
import {
  AdobeAnalyticsLinkAccountPath,
  AppStoreConnectLinkAccountPath,
  GooglePlayConsoleLinkAccountPath
} from 'util/paths';
import {
  AdobeAnalyticsLogo,
  AppStoreConnectLogo,
  GooglePlayConsoleLogo,
  TikTokLogo
} from 'util/assets';
import { FEATURE_FLAG } from 'util/featureFlags';
import LINKTOKEN_TYPE_ENUM from 'util/linktokenTypeEnum';
import { showToast } from 'contexts/ToastContext';

const thisPageTitle = 'Link Accounts';

const LinkSocialAccount = props => {
  const { history, featureFlags } = props;

  const [onboard, setOnboard] = useState(false);

  useEffect(() => {
    setPageTitle(thisPageTitle);
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('onboard') === 'ready') {
      setOnboard(true);
    } else {
      setOnboard(false);
    }
  }, []);

  return (
    <Grid container>
      <Paper>
        <Box p={30}>
          <>
            <Grid container>
              <AlembicPageHeader pageTitle={thisPageTitle} />
              {onboard && (
                <Banner
                  message="
                              Thank you for linking your first account to Alembic!
                              Data collection will begin soon, and our Customer Success Team 
                              will be contacting you in the next few days to continue
                              your free trial."
                  severity="success"
                />
              )}
              <Box py={8}>
                <Typography variant="body1" paragraph>
                  For Privacy, your accounts cannot be seen by other users until they are assigned
                  to a container (and enabled). Removing or changing permissions on accounts after
                  they have been linked to Alembic may stop analytics gathering and disconnect
                  accounts.
                </Typography>
              </Box>
            </Grid>
            <OAuthResponder />
            {/* oauth signin area */}
            <Box py={8}>
              <Grid container justifyContent="center" spacing={2}>
                <HasAnyPriv privs={['SVC:CHAN:FB']}>
                  <Grid item xs={12} md={6} lg={3}>
                    <OAuthButton type={LINKTOKEN_TYPE_ENUM.FB_PROFILE} />
                  </Grid>
                </HasAnyPriv>
                <HasAnyPriv privs={['SVC:CHAN:LI']}>
                  <Grid item xs={12} md={6} lg={3}>
                    <OAuthButton type={LINKTOKEN_TYPE_ENUM.LINKEDIN_PROFILE} />
                  </Grid>
                </HasAnyPriv>

                {isFeatureVisible(featureFlags, FEATURE_FLAG.SALESFORCE_LINKING) && (
                  <HasAnyPriv privs={['SVC:CHAN:SF']}>
                    <Grid item xs={12} md={6} lg={3}>
                      <OAuthButton type={LINKTOKEN_TYPE_ENUM.SALESFORCE} />
                    </Grid>
                  </HasAnyPriv>
                )}

                {isFeatureVisible(featureFlags, FEATURE_FLAG.TWITTER_LINKING) && (
                  <HasAnyPriv privs={['SVC:CHAN:TW']}>
                    <Grid item xs={12} md={6} lg={4}>
                      <OAuthButton type={LINKTOKEN_TYPE_ENUM.TWITTER} />
                    </Grid>
                  </HasAnyPriv>
                )}

                <HasAnyPriv privs={['SVC:CHAN:YT']}>
                  <Grid item xs={12} md={6} lg={4}>
                    <OAuthButton type={LINKTOKEN_TYPE_ENUM.YOUTUBE} />
                  </Grid>
                </HasAnyPriv>
                {isFeatureVisible(featureFlags, FEATURE_FLAG.GA_LINKING) && (
                  <HasAnyPriv privs={['SVC:CHAN:GA']}>
                    <Grid item xs={12} md={6} lg={4}>
                      <OAuthButton type={LINKTOKEN_TYPE_ENUM.GOOGLE_PROFILE} />
                    </Grid>
                  </HasAnyPriv>
                )}
                {isFeatureVisible(featureFlags, FEATURE_FLAG.AA_LINKING) && (
                  <HasAnyPriv privs={['SVC:CHAN:AA']}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        style={{ padding: '10px 2px 10px 2px' }}
                        onClick={() => {
                          goToRoute(AdobeAnalyticsLinkAccountPath, history);
                        }}
                      >
                        <Box mx={10}>
                          <div style={{ display: 'flex' }}>
                            <img alt="logo" width={20} height={20} src={AdobeAnalyticsLogo} />
                          </div>
                        </Box>
                        Connect Adobe Analytics
                      </Button>
                    </Grid>
                  </HasAnyPriv>
                )}
                {isFeatureVisible(featureFlags, FEATURE_FLAG.APP_STORE_CONNECT) && (
                  <HasAnyPriv privs={['SVC:CHAN:ASC']}>
                    <Grid item xs={12} md={6} lg={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        style={{ padding: '10px 2px 10px 2px' }}
                        onClick={() => {
                          goToRoute(AppStoreConnectLinkAccountPath, history);
                        }}
                      >
                        <Box mx={10}>
                          <div style={{ display: 'flex' }}>
                            <img alt="logo" width={20} height={20} src={AppStoreConnectLogo} />
                          </div>
                        </Box>
                        Connect App Store Connect
                      </Button>
                    </Grid>
                  </HasAnyPriv>
                )}
                {isFeatureVisible(featureFlags, FEATURE_FLAG.GOOGLE_PLAY_CONSOLE) && (
                  <HasAnyPriv privs={['SVC:CHAN:GPC']}>
                    <Grid item xs={12} md={6} lg={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        style={{ padding: '10px 2px 10px 2px' }}
                        onClick={() => {
                          goToRoute(GooglePlayConsoleLinkAccountPath, history);
                        }}
                      >
                        <Box mx={10}>
                          <div style={{ display: 'flex' }}>
                            <img alt="logo" width={20} height={20} src={GooglePlayConsoleLogo} />
                          </div>
                        </Box>
                        Connect Google Play Console
                      </Button>
                    </Grid>
                  </HasAnyPriv>
                )}
                {isFeatureVisible(featureFlags, FEATURE_FLAG.TIKTOK_LINKING) && (
                  <HasAnyPriv privs={['SVC:CHAN:TT']}>
                    <Grid item xs={12} md={6} lg={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        style={{ padding: '10px 2px 10px 2px' }}
                        onClick={() => {
                          fetch(`${process.env.API_URL}/tiktok_oauth`)
                            .then(response => response.json())
                            .then(response => {
                              window.location = response.url;
                            })
                            .catch(() => {
                              showToast('Could not get TikTok consent URL', 'error');
                            });
                        }}
                      >
                        <Box mx={10}>
                          <div style={{ display: 'flex' }}>
                            <img alt="logo" width={20} height={20} src={TikTokLogo} />
                          </div>
                        </Box>
                        Connect to TikTok
                      </Button>
                    </Grid>
                  </HasAnyPriv>
                )}

                {isFeatureVisible(featureFlags, FEATURE_FLAG.GSC_LINKING) && (
                  <HasAnyPriv privs={['SVC:CHAN:GSC']}>
                    <Grid item xs={12} md={6} lg={3}>
                      <OAuthButton type="GOOGLE_SEARCH_CONSOLE" />
                    </Grid>
                  </HasAnyPriv>
                )}
              </Grid>
            </Box>

            <LinkedAccountTable />
          </>
        </Box>
      </Paper>
    </Grid>
  );
};

LinkSocialAccount.propTypes = {
  history: PropTypes.shape().isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const mapStateToProps = state => {
  return {
    featureFlags: state.auth.featureFlags
  };
};

export default connect(mapStateToProps)(LinkSocialAccount);
