import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import moment from 'moment';

// Default formatter that converts value to string, handling null/undefined
const defaultFormatter = value => (value != null ? String(value) : '');

const escapeCSV = value => {
  if (value === null || value === undefined) {
    return '';
  }

  const stringValue = String(value);

  // RFC 4180 compliant CSV escaping:
  // 1. Fields containing line breaks, double quotes, or commas should be enclosed in double quotes
  // 2. Double quotes in the field should be escaped with another double quote
  return `"${stringValue.replace(/"/g, '""')}"`;
};

/**
 * Formats a date for use in a filename (YYYY-MM-DD format)
 * @param {Date|string} date - The date to format
 * @returns {string} The formatted date string
 */
const formatDateForFilename = date => {
  return moment.utc(date || new Date()).format('YYYY-MM-DD');
};

/**
 * Formats a date range for use in a filename
 * @param {Object} dateRange - The date range to format
 * @param {Date|string} dateRange.startDate - The start date of the range
 * @param {Date|string} dateRange.endDate - The end date of the range
 * @returns {string} The formatted date range string (e.g., "2023-01-01_to_2023-01-31")
 */
const formatDateRangeForFilename = dateRange => {
  const { startDate, endDate } = dateRange || {};

  if (!startDate && !endDate) {
    return formatDateForFilename(new Date());
  }

  const start = startDate ? formatDateForFilename(startDate) : 'start';
  const end = endDate ? formatDateForFilename(endDate) : 'now';

  return `${start}_to_${end}`;
};

/**
 * Download button component for exporting data as CSV
 * @param {Object} props Component props
 * @param {Array<Object>} props.data - The data array to be exported as CSV
 * @param {string} [props.fileName='data_export'] - The base name of the downloaded file (without extension)
 * @param {Object} [props.dateRange] - Optional date range to include in the filename
 * @param {Date|string} [props.dateRange.startDate] - The start date of the range (Date object or string in a format moment can parse)
 * @param {Date|string} [props.dateRange.endDate] - The end date of the range (Date object or string in a format moment can parse)
 * @param {Array<Object>} props.columns - Column configuration for the CSV export
 * @param {string} props.columns[].field - The field name in the data object to extract values from
 * @param {string} [props.columns[].header] - The column header name to display in the CSV (defaults to field name if not provided)
 * @param {Function} [props.columns[].formatter] - Optional function to format the field value before export
 *    @param {*} value - The raw value from the data
 *    @param {Object} row - The entire row data object
 *    @returns {string} The formatted value
 * @returns {React.Component} Download CSV button component
 */
const DownloadCSVButton = ({ data, fileName, dateRange, columns }) => {
  if (!data?.length) {
    return <></>;
  }

  const generateCSV = () => {
    let csvContent = '';
    const headers = columns.map(col => escapeCSV(col.header || col.field));
    csvContent += `${headers.join(',')}\n`;

    const rows = data.map(item => {
      return columns
        .map(col => {
          const formatter = col.formatter || defaultFormatter;
          const rawValue = item[col.field];
          const value = formatter(rawValue, item);

          return escapeCSV(value);
        })
        .join(',');
    });

    return csvContent + rows.join('\n');
  };

  const handleDownload = () => {
    if (!columns || !columns.length) {
      return;
    }

    // Generate filename, adding date range if provided
    let baseFileName = fileName;

    // Add date range if provided
    if (dateRange && (dateRange.startDate || dateRange.endDate)) {
      const dateRangeStr = formatDateRangeForFilename(dateRange);
      baseFileName = `${fileName}_${dateRangeStr}`;
    }

    // Always append .csv extension
    const finalFileName = `${baseFileName}.csv`;

    const csvContent = generateCSV();
    const BOM = '\uFEFF'; // Add BOM for Excel compatibility
    const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.setAttribute('href', url);
    link.setAttribute('download', finalFileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  return (
    <IconButton onClick={handleDownload} style={{ color: '#0B1835', marginRight: '10px' }}>
      <DownloadIcon />
    </IconButton>
  );
};

DownloadCSVButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fileName: PropTypes.string, // Base filename without extension
  dateRange: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
  }), // Optional date range to use in filename

  // Column configuration
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      header: PropTypes.string,
      formatter: PropTypes.func
    })
  ).isRequired
};

DownloadCSVButton.defaultProps = {
  fileName: 'data_export',
  dateRange: null
};

export default DownloadCSVButton;
