import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import AlbTable from 'components/AlbTable';
import AlbLoading from 'components/AlbLoading';
import AnalyticsBox from 'components/AnalyticsBox';
import DownloadCSVButton from 'components/DownloadCSVButton';
import { formatCurrencyRound } from 'util/formatCurrency';
import getValueAbbreviation from 'util/getValueAbbreviation';
import formatSalesforceLabel from '../formatSalesforceLabel';
import formatSalesforceDimension from '../formatSalesforceDimension';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  characteristic: {
    height: '100%',
    alignItems: 'center',
    color: '#32327D',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

/**
 * @summary Renders Top Channels Table for cost to recreate reports
 * @name SalesforceRevenueTable
 * @param {Object} props - React props passed to this component
 * @param {Boolean} props.isLoading - Whether the table data is currently loading
 * @param {Array} props.tableData - Data to be displayed in the table
 * @param {Object} props.dateRange - Date range to be used in the table
 * @param {Boolean} props.lead - Whether the table is for lead opportunities
 * @return {Object} - React JSX
 */
const SalesforceRevenueTable = props => {
  const { isLoading, tableData, dateRange, lead } = props;
  const classes = useStyles();

  const [truncateValues, setTruncateValues] = useState(false);
  const [displayedRows, setDisplayedRows] = useState(5);

  const handleResize = useCallback(() => setTruncateValues(window.innerWidth <= 1200));

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isLoading) {
      setDisplayedRows(5);
    }
  }, [isLoading]);

  const handleShowMore = () => {
    setDisplayedRows(prevCount => prevCount + 5);
  };

  const visibleTableData = tableData.slice(0, displayedRows);
  const hasMoreRows = displayedRows < tableData.length;

  const tableThemeOverrides = {
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        height: '100%'
      },
      responsiveBase: {
        position: 'unset !important',
        height: '100% !important'
      },
      tableRoot: {
        height: '100%'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        boxShadow: '0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        whiteSpace: 'nowrap'
      },
      sortAction: {
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        maxWidth: '200px',

        '&:not(:last-child)': {
          paddingRight: '0px'
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        height: '100%'
      }
    }
  };

  const customCharacteristicRender = (text, { rowData }) => {
    const [dimension] = rowData;
    const formattedLabel = formatSalesforceLabel({ label: text, dimension });

    return (
      <Tooltip title={formattedLabel}>
        <Grid className={classes.characteristic}>{formattedLabel}</Grid>
      </Tooltip>
    );
  };

  const customMetricRender = text => (
    <Grid className={classes.flex}>
      <div align="center" style={{ flex: 1 }}>
        {Math.round(text).toLocaleString()}
      </div>
    </Grid>
  );

  const customPercentRender = text => (
    <Grid className={classes.flex}>
      <div align="center" style={{ flex: 1 }}>
        {Math.round(text).toLocaleString()}%
      </div>
    </Grid>
  );

  const customRevenueRender = text => (
    <Grid className={classes.flex}>
      <div align="center" style={{ flex: 1 }}>
        {text < 0 ? '-' : ''}
        {truncateValues
          ? getValueAbbreviation(Math.abs(text), true)
          : `$${formatCurrencyRound(Math.abs(text))}`}
      </div>
    </Grid>
  );

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    sort: false,
    pagination: false,
    responsive: 'standard',
    draggableColumns: { enabled: true, transitionTime: 300 }
  };

  const tableColumns = [
    {
      name: 'dimension',
      options: { display: false }
    },
    {
      name: 'label',
      label: 'FIELD',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customCharacteristicRender
      }
    },
    {
      name: 'revenue',
      label: 'REVENUE',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customRevenueRender
      }
    },
    {
      name: 'close_rate',
      label: 'CLOSE RATE',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customPercentRender
      }
    },
    {
      name: 'deal_size',
      label: 'DEAL SIZE',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customRevenueRender
      }
    },
    {
      name: 'volume',
      label: 'VOLUME',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: customMetricRender
      }
    }
  ];

  // Define column formatters for CSV export
  const revenueFormatter = value => `$${formatCurrencyRound(Math.abs(value))}`;
  const percentFormatter = value => `${Math.round(value)}%`;
  const volumeFormatter = value => Math.round(value).toLocaleString();

  // Define download columns configuration
  const downloadColumns = [
    { field: 'label', header: 'Field' },
    { field: 'dimension', header: 'Category', formatter: formatSalesforceDimension },
    { field: 'revenue', header: 'Revenue', formatter: revenueFormatter },
    { field: 'close_rate', header: 'Close Rate', formatter: percentFormatter },
    { field: 'deal_size', header: 'Deal Size', formatter: revenueFormatter },
    { field: 'volume', header: 'Volume', formatter: volumeFormatter }
  ];

  return (
    <Grid item xs={6}>
      <AnalyticsBox
        noMargin
        noPadding
        tabs={['Top 5']}
        onChange={() => {}}
        customComponent={
          <DownloadCSVButton
            data={tableData}
            fileName={`salesforce_${lead ? 'lead' : 'non_lead'}_opportunities`}
            dateRange={dateRange}
            columns={downloadColumns}
          />
        }
        prefix="mv"
      >
        <div style={{ position: 'relative' }}>
          {isLoading && (
            <Grid item className={classes.loadingOverlay}>
              <AlbLoading />
            </Grid>
          )}
          <AlbTable
            tableData={visibleTableData}
            tableColumns={tableColumns}
            tableOptions={tableOptions}
            tableThemeOverrides={tableThemeOverrides}
          />
        </div>
      </AnalyticsBox>
      {hasMoreRows && (
        <Grid container justifyContent="center">
          <Button
            className={classes.showMoreButton}
            color="primary"
            variant="contained"
            onClick={handleShowMore}
          >
            Show More
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

SalesforceRevenueTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lead: PropTypes.bool,
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  })
};

SalesforceRevenueTable.defaultProps = {
  lead: false,
  dateRange: null
};

export default SalesforceRevenueTable;
