import { getSimpleSocialTypeQuery } from 'gql/ui_util';

/**
 * @method
 * @summary This function converts a complex social type to a simple one
 * @name getSimpleSocialType
 * @param {string} type - The social type
 * @return {Promise<string>} - Returns a promise that resolves to a simple social type
 */

// Cache for previously fetched social types
const socialTypeCache = new Map();

const getSimpleSocialType = async (type, useSimple = false) => {
  const cacheKey = `${type}:${useSimple}`;

  if (socialTypeCache.has(cacheKey)) {
    return socialTypeCache.get(cacheKey);
  }

  const res = await getSimpleSocialTypeQuery({ type, useSimple });
  const { type: socialType = '' } = res.payload.data.simpleSocialType || {};

  // Store in cache for subsequent calls with the same type
  socialTypeCache.set(cacheKey, socialType);

  return socialType;
};

export default getSimpleSocialType;
