import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { GET_MENTIONS_FROM_DETECTED_EVENT } from 'gql/mention';
import { useQuery } from '@apollo/client';
import Box from 'components/Box';
import PostDrawerMetricGrid from 'components/PostDrawerView/PostDrawerMetricGrid';
import AnnotationsList from 'components/DetectedEventsTable/AnnotationsList';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import colors from 'util/colors';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';
import handleGraphQLError from 'util/error';
import { mapEventTypeToIcon } from '../util';

const useStyles = makeStyles({
  container: {
    padding: '28px 25px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '36px',
    color: '#0A1734',
    marginRight: '10px'
  },
  report: {
    '& > img': {
      marginRight: '10px'
    }
  },
  reportName: {
    fontSize: '16px',
    fontWeight: 500,
    color: colors.doveGray,
    marginLeft: '12px'
  }
});

const excludeRatingsEventTypes = [
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS
];

const displaySharesEventTypes = [DETECTED_EVENT_TYPE.SA_TOTAL_SHARES];

/**
 * @name ThirdPartyMediaDrawerDetailsDashboard - renders the top section of the dashboard drawer for 3P Media event types
 * @param {Object} props.details - the details of the detectedEvent object
 * @returns React JSX - drawer header section
 */
const ThirdPartyMediaDrawerDetailsDashboard = props => {
  const classes = useStyles();

  const { details } = props;
  const { type, originalObject } = details;

  const [mentions, setMentions] = useState(0);
  const [shares, setShares] = useState(0);
  const [ratings, setRatings] = useState(0);
  const [icon, setIcon] = useState(null);

  const { data, loading, error } = useQuery(GET_MENTIONS_FROM_DETECTED_EVENT, {
    variables: { detectedEventId: originalObject?.id },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.getMentionsFromDetectedEvent) {
      setMentions(data.getMentionsFromDetectedEvent.count ?? 0);
      setShares(data.getMentionsFromDetectedEvent.shares ?? 0);
      setRatings(data.getMentionsFromDetectedEvent.total_viewership ?? 0);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    const loadIcon = async () => {
      if (type) {
        const iconSrc = await mapEventTypeToIcon(type);
        setIcon(iconSrc);
      } else {
        setIcon(null);
      }
    };

    loadIcon();
  }, [type]);

  let gridItemSize = 12;

  if (!excludeRatingsEventTypes.includes(type)) {
    gridItemSize = 6;
  }

  if (displaySharesEventTypes.includes(type)) {
    gridItemSize = 4;
  }

  const gridItems = [
    {
      graphTitle: 'Total Mentions',
      metricValue: mentions.toLocaleString(),
      gridItemSize
    }
  ];

  if (displaySharesEventTypes.includes(type)) {
    gridItems.push({
      graphTitle: 'Total Shares',
      metricValue: shares.toLocaleString(),
      gridItemSize
    });
  }

  if (!excludeRatingsEventTypes.includes(type)) {
    gridItems.push({
      graphTitle: 'Total Ratings',
      metricValue: ratings.toLocaleString(),
      gridItemSize
    });
  }

  return (
    <div className={classes.container}>
      <Box mb={2.5}>
        <Grid container alignItems="center">
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography className={classes.title}>3rd Party Media Surge</Typography>
          </Grid>
          <Grid
            className={classes.report}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              {icon && <img src={icon} height={13} alt="source icon" />}
              <Typography className={classes.reportName}>
                {originalObject?.eventInfo?.sourceName}
              </Typography>
            </Box>
          </Grid>
          <Grid container alignItems="center">
            {error && <AlbError error={error} />}
            {loading && <AlbLoading />}
            {!error && !loading && <PostDrawerMetricGrid gridItems={gridItems} />}
          </Grid>
        </Grid>
      </Box>
      <AnnotationsList startDate={details.startDate} endDate={details.endDate} includeTitle />
    </div>
  );
};

ThirdPartyMediaDrawerDetailsDashboard.propTypes = {
  details: PropTypes.shape().isRequired
};

export default ThirdPartyMediaDrawerDetailsDashboard;
