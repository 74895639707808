import React from 'react';
import Annotations from 'views/Annotations';
import LabelFilters from 'views/LabelFilters';
// import DashboardPlan from 'views/DashboardPlan';
// import DashboardOptimize from 'views/DashboardUnderstand';
import DashboardV4 from 'views/DashboardV4';
import DashboardInsights from 'views/DashboardInsights';
import DashboardCausal from 'views/DashboardCausal';
import Media from 'views/MediaLayout';
import Profile from 'views/Profile';
import ManageOrganization from 'views/ManageOrganization';
import LinkSocialAccount from 'views/LinkSocialAccount';
import ChangePassword from 'views/ChangePassword';
import MFAInput from 'views/Login/MFAInputDialog';
import ThemesAndTopics from 'views/ThemesAndTopics';
import FileList from 'views/FileUpload';
import FileRedirect from 'views/FileUpload/FileRedirect';
import ManageUsers from 'components/ManageUsers';
import ContainerEdit from 'components/ContainerEdit';
import AssociatedAccounts from 'components/AssociatedAccounts';
import Analytics from 'views/Report';
import AnalyticsSocialV2 from 'components/AnalyticsSocialV2';
import ShareOfVoiceReports from 'components/AnalyticsShareOfVoice';
import ShareOfVoiceAnalytics from 'components/AnalyticsShareOfVoice/ShareOfVoiceAnalytics';
import ShortlinkAnalytics from 'components/ShortlinkAnalytics';
import AnalyticsHypeAndFlow from 'components/AnalyticsHypeAndFlow';
import CreateMediaReport from 'components/AnalyticsEarnedMedia/CreateMediaReport';
import AnalyticsResurgence from 'components/AnalyticsResurgence';
import MultiPostResurgence from 'components/AnalyticsResurgence/MultiPostResurgence';
import CostToRecreateReport from 'components/AnalyticsCostToRecreate';
import CostToRecreateAnalytics from 'components/AnalyticsCostToRecreate/CostToRecreateAnalytics';
import CreateCostToRecreateReport from 'components/AnalyticsCostToRecreate/CreateCostToRecreateReport';
import AnalyticsWeb from 'components/AnalyticsWeb';
import CreateThirdPartyMediaReport from 'components/AnalyticsThirdPartyMedia/CreateThirdPartyMediaReport';
import LinkAdobeAnalyticsAccount from 'views/LinkAdobeAnalyticsAccount';
import LinkAdobeAnalyticsSegment from 'views/LinkAdobeAnalyticsAccount/LinkAdobeAnalyticsSegment';
import LinkGoogleAnalyticsFilter from 'views/LinkGoogleAnalyticsFilter';
import ThirdPartyMediaAnalytics from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaAnalytics';
import AdobeAnalyticsManagePredictionEvents from 'views/AdobeAnalyticsManagePredictionEvents';
import LinkAppStoreConnectAccount from 'views/LinkAppStoreConnectAccount';
import LinkGooglePlayConsoleAccount from 'views/LinkGooglePlayConsoleAccount';
import AnalyticsProgramLift from 'components/AnalyticsProgramLift';
import AnalyticsProgramLiftReport from 'components/AnalyticsProgramLift/AnalyticsProgramLiftReport';
import AnalyticsSalesforce from 'components/AnalyticsSalesforce';
import AnalyticsTargeting from 'components/AnalyticsTargeting';
import { userFullLogin, userFullAuthN, userFullAuthNnoPW, userFullAuthMFA } from '../loginFlow';

import {
  Root,
  LoginPath,
  RegisterPath,
  OnboardPath,
  EULAPath,
  ConfirmEmailPath,
  RecoverPath,
  RecoverWithTokenPath,
  BecomePath,
  InvitePath,
  // PlanPath,
  // OptimizePath,
  ObserveEventsPathV4,
  ObservePredictionsPathV4,
  AnnotationsPath,
  CreateEarnedMediaAnalyticsPath,
  ShareOfVoiceAnalyticsPath,
  ShareOfVoiceAnalyticsPathMatch,
  ResurgenceAnalyticsPath,
  ResurgenceAnalyticsPathMatch,
  CostToRecreateAnalyticsPath,
  CostToRecreateAnalyticsPathMatch,
  CreateCostToRecreateAnalyticsPath,
  WebAndAppAnalyticsPath,
  MediaPath,
  AnalyticsPath,
  SocialAnalyticsPath,
  HypeAndFlowAnalyticsPath,
  ShortlinksAnalyticsPath,
  OrganizationTreePath,
  ManageUsersPath,
  LinkAccountsPath,
  ManageOrganizationPath,
  ThemesAndTopicsPath,
  FilesPath,
  MFAInputPath,
  ChangePasswordPath,
  UserProfilePath,
  EditContainerPath,
  AssociatedAccountsPath,
  ShortlinkPath,
  DashboardSocialPathV4,
  DashboardEarnedPathV4,
  ThirdPartyMediaAnalyticsPath,
  ThirdPartyMediaAnalyticsSettingsPath,
  CreateThirdPartyMediaAnalyticsPath,
  ResendEmailVerificationPath,
  AdobeAnalyticsLinkAccountPath,
  LinkAdobeAnalyticsSegmentPath,
  LinkGoogleAnalyticsFilterPath,
  AdobeAnalyticsManagePredictionEventsPath,
  AppStoreConnectLinkAccountPath,
  GooglePlayConsoleLinkAccountPath,
  ProgramLiftPath,
  ProgramLiftAnalyticsPath,
  LabelFiltersPath,
  SalesforcePath,
  TargetingAnalyticsPath,
  InsightPath,
  CausalPath,
  SamlCompletePath
} from './paths';

const Login = React.lazy(() => import('views/Login'));
const Register = React.lazy(() => import('views/Register'));
const Onboard = React.lazy(() => import('views/Register'));
const ConfirmEmail = React.lazy(() => import('views/Register/ConfirmEmail'));
const EULA = React.lazy(() => import('views/Register/EULA'));
const Recover = React.lazy(() => import('views/Recover'));
const RecoverWithToken = React.lazy(() => import('views/RecoverWithToken'));
const Become = React.lazy(() => import('views/Become'));
const SamlComplete = React.lazy(() => import('views/SamlComplete'));
const Invite = React.lazy(() => import('views/Invite'));
const OAuthResponder = React.lazy(() => import('components/OAuth/OAuthResponder'));
const MainLayout = React.lazy(() => import('views/MainLayout'));
const Shortlink = React.lazy(() => import('views/Shortlink'));
const OrganizationTree = React.lazy(() => import('components/OrganizationTree'));
const ResendEmailVerification = React.lazy(() => import('views/ResendEmailVerification'));

// This file is read by App.js and fed to RenderRoutes to set up routing.
export const PRIVATE_ROUTES = [
  {
    path: ChangePasswordPath,
    key: 'ChangePassword',
    component: userFullAuthNnoPW(ChangePassword)
  },
  {
    path: Root,
    key: 'App',
    component: userFullAuthN(MainLayout),
    routes: [
      {
        path: `/:containerId${ChangePasswordPath}`,
        key: 'ChangePassword',
        component: userFullAuthNnoPW(ChangePassword)
      },
      {
        path: `/:containerId${AnnotationsPath}`,
        key: 'Annotations',
        component: userFullAuthN(Annotations)
      },
      {
        path: `/:containerId${LabelFiltersPath}`,
        key: 'LabelFilters',
        component: userFullAuthN(LabelFilters)
      },
      // {
      //   path: `/:containerId${PlanPath}`,
      //   key: 'DashboardPlan',
      //   component: userFullAuthN(DashboardPlan)
      // },
      // {
      //   path: `/:containerId${OptimizePath}`,
      //   key: 'DashboardOptimize',
      //   component: userFullAuthN(DashboardOptimize)
      // },
      {
        path: `/:containerId${ObserveEventsPathV4}`,
        key: 'DashboardV4',
        component: userFullAuthN(DashboardV4)
      },
      {
        path: `/:containerId${AdobeAnalyticsManagePredictionEventsPath}`,
        key: 'AdobeAnalyticsManagePredictionEvents',
        component: userFullAuthN(AdobeAnalyticsManagePredictionEvents)
      },
      {
        path: `/:containerId${ObservePredictionsPathV4}`,
        key: 'DashboardV4',
        component: userFullAuthN(DashboardV4)
      },
      {
        path: `/:containerId${DashboardSocialPathV4}`,
        key: 'DashboardV4',
        component: userFullAuthN(DashboardV4)
      },
      {
        path: `/:containerId${DashboardEarnedPathV4}`,
        key: 'DashboardV4',
        component: userFullAuthN(DashboardV4)
      },
      {
        path: `/:containerId${MediaPath}`,
        key: 'Media',
        component: userFullAuthN(Media)
      },
      {
        path: `/:containerId${AnalyticsPath}`,
        exact: true,
        key: 'Analytics',
        component: userFullAuthN(Analytics)
      },
      {
        path: `/:containerId${SocialAnalyticsPath}`,
        key: 'SocialAnalytics',
        component: userFullAuthN(AnalyticsSocialV2)
      },
      {
        path: `/:containerId${CreateEarnedMediaAnalyticsPath}`,
        key: 'CreateEarnedMediaAnalytics',
        component: userFullAuthN(CreateMediaReport)
      },
      {
        path: `/:containerId${ShareOfVoiceAnalyticsPathMatch}`,
        key: 'ShareOfVoiceAnalytics',
        component: userFullAuthN(ShareOfVoiceAnalytics)
      },
      {
        path: `/:containerId${ShareOfVoiceAnalyticsPath}`,
        key: 'ShareOfVoice',
        component: userFullAuthN(ShareOfVoiceReports)
      },
      {
        path: `/:containerId${ResurgenceAnalyticsPathMatch}`,
        key: 'MultiPostResurgence',
        component: userFullAuthN(MultiPostResurgence)
      },
      {
        path: `/:containerId${ResurgenceAnalyticsPath}`,
        key: 'Resurgence',
        component: userFullAuthN(AnalyticsResurgence)
      },
      {
        path: `/:containerId${OrganizationTreePath}`,
        key: 'OrganizationTree',
        component: userFullAuthN(OrganizationTree)
      },
      {
        path: `/:containerId${HypeAndFlowAnalyticsPath}`,
        key: 'HypeAndFlowAnalytics',
        component: userFullAuthN(AnalyticsHypeAndFlow)
      },
      {
        path: `/:containerId${AdobeAnalyticsLinkAccountPath}`,
        key: 'LinkAdobeAnalyticsAccount',
        component: userFullAuthN(LinkAdobeAnalyticsAccount)
      },
      {
        path: `/:containerId${LinkAdobeAnalyticsSegmentPath}`,
        key: 'LinkAdobeAnalyticsSegment',
        component: userFullAuthN(LinkAdobeAnalyticsSegment)
      },
      {
        path: `/:containerId${LinkGoogleAnalyticsFilterPath}`,
        key: 'LinkGoogleAnalyticsFilter',
        component: userFullAuthN(LinkGoogleAnalyticsFilter)
      },
      {
        path: `/:containerId${AppStoreConnectLinkAccountPath}`,
        key: 'LinkAppStoreConnectLinkAccountPath',
        component: userFullAuthN(LinkAppStoreConnectAccount)
      },
      {
        path: `/:containerId${GooglePlayConsoleLinkAccountPath}`,
        key: 'LinkGooglePlayConsoleLinkAccountPath',
        component: userFullAuthN(LinkGooglePlayConsoleAccount)
      },
      {
        path: `/:containerId${LinkAccountsPath}`,
        key: 'LinkSocialAccount',
        component: userFullAuthN(LinkSocialAccount)
      },
      {
        path: `/:containerId${ManageOrganizationPath}`,
        key: 'ManageOrganization',
        component: userFullAuthN(ManageOrganization)
      },
      {
        path: `/:containerId${UserProfilePath}/:tabId`,
        key: 'Profile',
        component: userFullAuthN(Profile)
      },
      {
        path: `/:containerId${UserProfilePath}`,
        key: 'Profile',
        component: userFullAuthN(Profile)
      },
      {
        path: `/:containerId${ThemesAndTopicsPath}`,
        key: 'ThemesTopics',
        component: userFullAuthN(ThemesAndTopics)
      },
      {
        path: `/:containerId${FilesPath}`,
        key: 'Upload',
        component: userFullAuthN(FileList)
      },
      {
        path: FilesPath,
        key: 'UploadRedirect',
        component: userFullAuthN(FileRedirect)
      },
      {
        path: `/:containerId${ManageUsersPath}`,
        key: 'ManageUsers',
        component: userFullAuthN(ManageUsers)
      },
      {
        path: `/:containerId${EditContainerPath}`,
        key: 'ContainerEdit',
        component: userFullAuthN(ContainerEdit)
      },
      {
        path: `/:containerId${AssociatedAccountsPath}`,
        key: 'AssociatedAccounts',
        component: userFullAuthN(AssociatedAccounts)
      },
      {
        path: `/:containerId${ShortlinkPath}`,
        key: 'Shortlink',
        component: userFullAuthN(Shortlink)
      },
      {
        path: `/:containerId${ShortlinksAnalyticsPath}`,
        key: 'ShortlinkAnalytics',
        component: userFullAuthN(ShortlinkAnalytics)
      },
      {
        path: process.env.FB_CALLBACK_PATH,
        key: 'facebook',
        component: userFullAuthN(OAuthResponder),
        type: 'FB_PROFILE'
      },
      {
        path: process.env.MAILCHIMP_CALLBACK_PATH,
        key: 'mailchimp',
        component: userFullAuthN(OAuthResponder),
        type: 'MAILCHIMP'
      },
      {
        path: process.env.LINKEDIN_CALLBACK_PATH,
        key: 'linkedin',
        component: userFullAuthN(OAuthResponder),
        type: 'LINKEDIN_PROFILE'
      },
      {
        path: process.env.TWITTER_CALLBACK_PATH,
        key: 'twitter',
        component: userFullAuthN(OAuthResponder),
        type: 'TWITTER'
      },
      {
        path: process.env.YOUTUBE_CALLBACK_PATH,
        key: 'youtube',
        component: userFullAuthN(OAuthResponder),
        type: 'YOUTUBE'
      },
      {
        path: process.env.GA_CALLBACK_PATH,
        key: 'google',
        component: userFullAuthN(OAuthResponder),
        type: 'GOOGLE_PROFILE'
      },
      {
        path: process.env.SALESFORCE_CALLBACK_PATH,
        key: 'salesforce',
        component: userFullAuthN(OAuthResponder),
        type: 'SALESFORCE'
      },
      {
        path: process.env.TIKTOK_CALLBACK_PATH,
        key: 'tiktok',
        component: userFullAuthN(OAuthResponder),
        type: 'TIKTOK'
      },
      {
        path: process.env.GSC_CALLBACK_PATH,
        key: 'google_search_console',
        component: userFullAuthN(OAuthResponder),
        type: 'GOOGLE_SEARCH_CONSOLE'
      },
      {
        path: `/:containerId${CostToRecreateAnalyticsPathMatch}`,
        key: 'CostToRecreateAnalytics',
        component: userFullAuthN(CostToRecreateAnalytics)
      },
      {
        path: `/:containerId${CostToRecreateAnalyticsPath}`,
        key: 'CostToRecreateReport',
        component: userFullAuthN(CostToRecreateReport)
      },
      {
        path: `/:containerId${CreateCostToRecreateAnalyticsPath}`,
        key: 'CreateCostToRecreateAnalyticsPath',
        component: userFullAuthN(CreateCostToRecreateReport)
      },
      {
        path: `/:containerId${WebAndAppAnalyticsPath}`,
        key: 'WebAndAppAnalytics',
        component: userFullAuthN(AnalyticsWeb)
      },
      {
        path: `/:containerId${ThirdPartyMediaAnalyticsPath}`,
        key: 'ThirdPartyMediaAnalytics',
        component: userFullAuthN(ThirdPartyMediaAnalytics)
      },
      {
        path: `/:containerId${ThirdPartyMediaAnalyticsSettingsPath}`,
        key: 'ThirdPartyMediaAnalytics',
        component: userFullAuthN(ThirdPartyMediaAnalytics)
      },
      {
        path: `/:containerId${CreateThirdPartyMediaAnalyticsPath}`,
        key: 'CreateThirdPartyMediaAnalytics',
        component: userFullAuthN(CreateThirdPartyMediaReport)
      },
      {
        path: `${ResendEmailVerificationPath}`,
        key: 'ResendEmailVerification',
        component: userFullAuthN(ResendEmailVerification)
      },
      {
        path: `/:containerId${ProgramLiftAnalyticsPath}`,
        key: 'AnalyticsProgramLiftReport',
        component: userFullAuthN(AnalyticsProgramLiftReport)
      },
      {
        path: `/:containerId${ProgramLiftPath}`,
        key: 'AnalyticsProgramLift',
        component: userFullAuthN(AnalyticsProgramLift)
      },
      {
        path: `/:containerId${SalesforcePath}`,
        key: 'AnalyticsSalesforce',
        component: userFullAuthN(AnalyticsSalesforce)
      },
      {
        path: `/:containerId${TargetingAnalyticsPath}`,
        key: 'AnalyticsTargeting',
        component: userFullAuthN(AnalyticsTargeting)
      },
      {
        path: `/:containerId${InsightPath}`,
        key: 'DashboardInsights',
        component: userFullAuthN(DashboardInsights)
      },
      {
        path: `/:containerId${CausalPath}`,
        key: 'DashboardCausal',
        component: userFullAuthN(DashboardCausal)
      }
    ]
  }
];

export const PUBLIC_ROUTES = [
  {
    path: LoginPath,
    key: 'Login',
    exact: true,
    component: userFullLogin(Login) // when set this forces the user out if they are logged in.
  },
  // technically still a public route because you're not logged in (yet)
  // but we will kick you without a login.
  {
    path: MFAInputPath,
    key: 'MFAInput',
    component: userFullAuthMFA(MFAInput) // when set the user is kicked to the main redirect if logged in, or, if not logged in, to the login page.
  },
  {
    path: RegisterPath,
    key: 'Register',
    component: Register
  },
  {
    path: OnboardPath,
    key: 'Onboard',
    component: Onboard
  },
  {
    path: EULAPath,
    key: 'EULA',
    component: EULA
  },
  {
    path: ConfirmEmailPath,
    key: 'ConfirmEmail',
    component: ConfirmEmail
  },
  {
    path: RecoverPath,
    key: 'Recover',
    component: Recover
  },
  {
    path: BecomePath,
    key: 'Become',
    component: Become
  },
  {
    path: SamlCompletePath,
    key: 'SamlComplete',
    component: SamlComplete
  },
  {
    path: RecoverWithTokenPath,
    key: 'RecoverWithToken',
    component: RecoverWithToken
  },
  {
    path: InvitePath,
    key: 'Invite',
    component: Invite
  },
  {
    path: ResendEmailVerificationPath,
    key: 'ResendEmailVerificationPath',
    component: ResendEmailVerification
  }
];
