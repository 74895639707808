/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { showToast } from 'contexts/ToastContext';
import handleGraphQLError from 'util/error';
import { useMutation } from '@apollo/client';
import { REMOVE_SOCIAL_LINK } from 'gql/linktoken';
import DeleteForever from '@material-ui/icons/DeleteForever';

import SocialLogo from 'components/SocialLogo';
import getSimpleSocialType from 'util/getSimpleSocialType';

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';

const UnlinkSocial = React.forwardRef((props, ref) => {
  const { id, remoteName, accountType, refetch, close } = props;
  const [removeSocialLink, { error, data }] = useMutation(REMOVE_SOCIAL_LINK);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [unlinkConfirmed, setUnlinkConfirmed] = useState(false);
  const [simpleSocialType, setSimpleSocialType] = useState(null);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.removeSocialLink === true) {
      showToast('Unlink Successful', 'success');
      refetch();
    }
  }, [data]);

  useEffect(() => {
    const loadSocialType = async () => {
      const socialType = await getSimpleSocialType(accountType);
      setSimpleSocialType(socialType);
    };

    loadSocialType();
  }, [accountType]);

  const onConfirm = () => {
    removeSocialLink({
      variables: {
        id
      }
    });
    setConfirmModal(false);
    setUnlinkConfirmed(false);
    setConfirmText('');
  };

  const handleConfirmClose = () => {
    setConfirmModal(false);
    setUnlinkConfirmed(false);
    setConfirmText('');
    close();
  };

  return (
    <>
      <MenuItem
        innerRef={ref}
        onClick={() => {
          setConfirmModal(true);
        }}
      >
        <ListItemIcon>
          <DeleteForever fontSize="small" />
        </ListItemIcon>
        <ListItemText>Unlink</ListItemText>
      </MenuItem>
      <Dialog
        open={confirmModal}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <SocialLogo width={40} height={40} type={simpleSocialType} />
        <DialogTitle id="alert-dialog-title">Unlink {remoteName} ?</DialogTitle>
        <DialogContent>
          <Typography paragraph style={{ color: 'darkred' }}>
            Unlinking an account from Alembic will <b>remove all of the data associated with it</b>{' '}
            and all sub-accounts below it as well as historical data for those accounts.
          </Typography>

          <Typography paragraph style={{ color: 'black' }}>
            If you need to relink this account to pick up new pages or other changes, hit CANCEL and
            click RELINK. Unlinking <b>can not</b> be undone.
          </Typography>

          <Typography paragraph style={{ color: 'black' }}>
            To confirm that you wish to unlink this account, please type <em>CONFIRM</em> in
            UPPERCASE.
          </Typography>

          <TextField
            fullWidth
            variant="filled"
            value={confirmText}
            placeholder="CONFIRM"
            onChange={event => {
              setConfirmText(event.target.value);
              setUnlinkConfirmed(event.target.value === 'CONFIRM');
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} variant="outlined" color="primary">
            CANCEL
          </Button>
          <Button
            onClick={onConfirm}
            variant="outlined"
            color="primary"
            style={unlinkConfirmed ? { backgroundColor: '#e00000', color: '#fff' } : {}}
            autoFocus
            disabled={!unlinkConfirmed}
          >
            UNLINK ACCOUNT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

UnlinkSocial.propTypes = {
  id: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  remoteName: PropTypes.string.isRequired
};

export default UnlinkSocial;
