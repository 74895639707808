import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SortByDropdown from './SortByDropdown';
import TerminatingEventDropdown from './TerminatingEventDropdown';
import SearchBox from './SearchBox';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontWeight: 500
  },
  causalTitle: {
    fontSize: '20px',
    color: '#0A1734'
  },
  dropdownLabel: {
    fontSize: '15px',
    color: '#0D1A3A'
  },
  select: {
    width: '190px',
    height: '41px',
    color: '#0D1A3A',
    marginLeft: '10px'
  }
});

/**
 * @method
 * @summary This component renders the header for the Causal UI
 * @name CausalHeader
 * @param {Object} props - React props
 * @return {Object} - React JSX
 */
function CausalHeader(props) {
  const {
    terminatingEvents,
    selectedEventTypes,
    setSelectedEventTypes,
    sort,
    setSort,
    searchInputValue,
    setSearchInputValue
  } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.headerContainer} spacing={2}>
      <Grid item className={classes.causalTitle}>
        Causal Chains
      </Grid>
      {terminatingEvents?.length > 0 && (
        <Grid item className={classes.dropdownLabel}>
          <TerminatingEventDropdown
            selectedEventTypes={selectedEventTypes}
            setSelectedEventTypes={setSelectedEventTypes}
            terminatingEvents={terminatingEvents}
          />
        </Grid>
      )}
      <Grid item className={classes.dropdownLabel}>
        <SortByDropdown sort={sort} setSort={setSort} />
      </Grid>
      <Grid item>
        <SearchBox
          searchInputValue={searchInputValue}
          setSearchInputValue={setSearchInputValue}
          sort={sort}
          terminatingEvents={terminatingEvents}
        />
      </Grid>
    </Grid>
  );
}

CausalHeader.propTypes = {
  terminatingEvents: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedEventTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedEventTypes: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  searchInputValue: PropTypes.string.isRequired,
  setSearchInputValue: PropTypes.func.isRequired
};

export default CausalHeader;
