/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxHeader from 'components/AnalyticsSocialV2/BoxContent/BoxHeader';
import AlbSwitch from 'components/AlbSwitch';
import AlbGraphDruid from 'components/AlbGraphs/AlbGraphDruid';
import { sourcesAndPagesGraphLabels } from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import { topPostsColors } from 'util/colors';
import SIMPLE_SOCIAL_TYPE_ENUM from 'util/getSimpleSocialTypeEnum';
import getSimpleSocialType from 'util/getSimpleSocialType';
import WebTopTable from './Tables/WebTopTable';

const useStyles = makeStyles({
  box: {
    margin: '25px 15px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
  },
  graph: {
    flexDirection: 'column',
    padding: '30px'
  },
  table: {
    boxShadow: 'inset 0px 20px 13px -13px rgba(0, 0, 0, 0.1)'
  },
  switchContainer: {
    padding: '0px 30px'
  },
  switchLabel: {
    fontSize: '13px',
    lineHeight: '20px'
  }
});

const WebChannels = props => {
  const { accounts, dates } = props;

  const classes = useStyles();

  const tabs = ['Channels', 'Campaigns'];
  const queries = ['webAndAppsTopChannelsGraph', 'webAndAppsTopCampaignsGraph'];
  const revenueQueries = [
    'webAndAppsTopChannelsRevenueGraph',
    'webAndAppsTopCampaignsRevenueGraph'
  ];

  const [currentTab, setCurrentTab] = useState(0);
  const [pageGraph, setPageGraph] = useState(0);
  const [isRevenueChecked, setIsRevenueChecked] = useState(false);
  const [hasAdobeAnalytics, setHasAdobeAnalytics] = useState(false);
  const rowsPerPage = 10;

  // Check if any account is Adobe Analytics
  useEffect(() => {
    const checkForAdobeAnalytics = async () => {
      if (!accounts) return;

      const typesPromises = accounts.map(async ({ type }) => getSimpleSocialType(type));

      const types = await Promise.all(typesPromises);
      const hasAdobe = types.some(type => type === SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS);

      setHasAdobeAnalytics(hasAdobe);
    };

    checkForAdobeAnalytics();
  }, [accounts]);

  const createGraph = (query, page) => ({
    customGraph: 'sourcesAndPages',
    additionalTooltips: ['point'],
    druidQuery: {
      queryV2: query,
      graphType: 'webAndAppsTable',
      page,
      rowsPerPage
    },
    fill: true,
    lineTension: 0.4,
    interactionMode: 'nearest',
    tooltipWidthCustom: 361,
    stacked: true,
    useTopPostsColors: true,
    // sourcesAndPagesGraphLabels is a filler array with 10 indices which is only used to provide the graph with an
    // indicator to render 10 tooltips/legend entries, but we may need to dynamically update the queries
    // field in the graph with the results of the query if we enable more than 10 rows in the table since
    // we're planning to have the graph and table be 1:1
    queries: sourcesAndPagesGraphLabels.map((label, i) => ({
      type: 'bar',
      palette: {
        gradient: [topPostsColors[i], topPostsColors[i]],
        borderColor: 'transparent'
      },
      // the label is used here to act as 'key' for label/tooltip mapping, otherwise unrelated to the query
      label
    }))
  });

  const [graph, setGraph] = useState(createGraph(queries[currentTab], 0));

  useEffect(() => {
    if (isRevenueChecked) {
      setGraph(createGraph(revenueQueries[currentTab], 0));
    } else {
      setGraph(createGraph(queries[currentTab], 0));
    }
  }, [currentTab, isRevenueChecked]);

  useEffect(() => {
    if (isRevenueChecked) {
      setGraph(createGraph(revenueQueries[currentTab], pageGraph));
    } else {
      setGraph(createGraph(queries[currentTab], pageGraph));
    }
  }, [pageGraph, isRevenueChecked]);

  return (
    <Grid container style={{ marginTop: '-25px' }}>
      <Grid container item className={classes.box}>
        <BoxHeader tabs={tabs} dates={dates} onTabChange={setCurrentTab} />
        {!hasAdobeAnalytics && (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            className={classes.switchContainer}
          >
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              Users
            </Typography>
            <AlbSwitch
              onChange={event => setIsRevenueChecked(event.target.checked)}
              checked={isRevenueChecked}
            />
            <Typography variantMapping={{ body1: 'span' }} className={classes.switchLabel}>
              Revenue
            </Typography>
          </Grid>
        )}
        <Grid container className={classes.graph}>
          <AlbGraphDruid tabData={graph} accounts={accounts} dates={dates} />
        </Grid>
        <Grid container item className={classes.table}>
          <BoxHeader tabs={[`${tabs[currentTab]} Breakdown`]} dates={dates} />
          <WebTopTable
            metric={`webAndAppsTop${tabs[currentTab]}${isRevenueChecked ? 'Revenue' : ''}Table`}
            accounts={accounts}
            dates={dates}
            setPageGraph={setPageGraph}
            rowsPerPageOptions={[]}
            isRevenue={isRevenueChecked}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

WebChannels.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired
};

export default WebChannels;
